import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import {
	deleteMachine,
	selectMachine,
} from '@/features/machines/machines-slice'

const Route = createFileRoute('/machines/$machineId/delete')({
	component: DeleteMachineComponent,
	loader: async ({ params: { machineId } }) => {
		const machineSelector = selectMachine(machineId)
		const machine = machineSelector(store.getState())
		if (!machine) {
			throw notFound()
		}
		return { machine }
	},
})

function DeleteMachineComponent() {
	const { machine } = Route.useLoaderData()
	const { machineId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()

	function closeDialog() {
		navigate({
			to: '/machines',
			search: true,
		})
	}

	return (
		<GenericAlertDialog
			title="Delete machine"
			description={`Are you sure you want to delete "${machine.name}"? This action cannot be undone.`}
			confirmButtonText="Delete"
			onClose={closeDialog}
			onConfirm={() => {
				closeDialog()
				dispatch(deleteMachine(machineId))
				toast.success(`Machine "${machine.name}" deleted`)
			}}
		/>
	)
}

export { Route }
