type GenericEventHandler<E extends React.SyntheticEvent> = (event: E) => void

const stopPropagation = <E extends React.SyntheticEvent>(
	handler?: (event: E) => void,
): GenericEventHandler<E> => {
	return event => {
		event.stopPropagation()
		handler?.(event)
	}
}

export { stopPropagation }
