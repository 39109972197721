import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'

type FullScreenState = {
	isFullScreenSupported: boolean
	isFullScreen: boolean
	toggleFullScreen: () => void
	fullScreenRef: React.RefObject<HTMLDivElement>
	fullScreenContainer?: HTMLDivElement | null
}

const FullScreenStateContext = createContext<FullScreenState | undefined>(
	undefined,
)

function FullScreenProvider(props: {
	children: ReactNode | ((state: FullScreenState) => ReactNode)
}) {
	const { children } = props
	const [isFullScreen, setIsFullScreen] = useState(false)
	const fullScreenRef = useRef<HTMLDivElement>(null)
	const isFullScreenSupported = Boolean(document.fullscreenEnabled)

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			fullScreenRef.current?.requestFullscreen()
		} else if (document.exitFullscreen) {
			document.exitFullscreen()
		}
	}

	useEffect(() => {
		const syncFullScreenStatus = () => {
			setIsFullScreen(Boolean(document.fullscreenElement))
		}
		syncFullScreenStatus()
		document.addEventListener('fullscreenchange', syncFullScreenStatus)
		return () => {
			document.removeEventListener('fullscreenchange', syncFullScreenStatus)
		}
	}, [])

	const value: FullScreenState = {
		isFullScreenSupported,
		isFullScreen,
		toggleFullScreen,
		fullScreenRef,
	}

	return (
		<FullScreenStateContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</FullScreenStateContext.Provider>
	)
}

function useFullScreen() {
	const context = useContext(FullScreenStateContext)
	const [fullScreenContainer, setFullScreenContainer] =
		useState<HTMLDivElement | null>(null)

	useLayoutEffect(() => {
		if (context) {
			setFullScreenContainer(context.fullScreenRef.current)
		}
	}, [context])

	if (context === undefined) {
		throw new Error(
			`${useFullScreen.name} must be used within a ${FullScreenProvider.name}`,
		)
	}
	return { ...context, fullScreenContainer }
}

export { useFullScreen, FullScreenProvider }
