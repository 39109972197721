import { useEffect } from 'react'

function useScrollSync<T extends HTMLElement>(
	refs: React.RefObject<T>[],
	oneWay = false,
) {
	useEffect(() => {
		const handleScroll = (event: Event) => {
			const target = event.target as T
			refs.forEach(ref => {
				const element = ref.current
				if (element) {
					if (element && element !== target) {
						element.scrollLeft = target.scrollLeft
					}
				}
			})
		}

		const subscribedElements: T[] = refs
			.map(ref => ref.current)
			.filter((element): element is T => !!element)

		subscribedElements.forEach((element, index) => {
			if (oneWay) {
				if (index === 0) {
					element.addEventListener('scroll', handleScroll)
				}
			} else {
				element.addEventListener('scroll', handleScroll)
			}
		})

		return () => {
			subscribedElements.forEach((element, index) => {
				if (oneWay) {
					if (index === 0) {
						element.removeEventListener('scroll', handleScroll)
					}
				} else {
					element.removeEventListener('scroll', handleScroll)
				}
			})
		}
	}, [refs, oneWay])
}

export { useScrollSync }
