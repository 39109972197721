import { useCallback, useEffect, useState } from 'react'

import { TMachine } from '@repo/types'
import {
	addMinutes,
	differenceInMilliseconds,
	differenceInMinutes,
} from 'date-fns'

function useContextualZoom<T extends HTMLElement>(args: {
	scrollViewRef: React.RefObject<T>
	screen: (T | null) | Window
	startDate: Date
	hourGap: number
	rowHeight: number
	machines: TMachine[]
	zoomLevel: string
	offsetY?: number // For navbar, action buttons, etc. that are above the zoomable/scrollable view
	stickySectionHeight?: number // For sticky section that is above the zoomable/scrollable view
	focalPointPercentage?: number
	reducedViewLeft?: number
}) {
	const {
		scrollViewRef,
		screen,
		startDate,
		hourGap,
		rowHeight,
		machines,
		zoomLevel,
		offsetY = 0,
		stickySectionHeight = 0,
		focalPointPercentage = 0.5,
		reducedViewLeft = 0,
	} = args
	const [centerDate, setCenterDate] = useState<Date>(() => new Date())

	const focusViewOnDate = useCallback(
		(targetDate: Date, focalPointPercentageOverride = focalPointPercentage) => {
			const offset =
				(differenceInMilliseconds(targetDate, startDate) / (3600 * 1000)) *
					hourGap -
				reducedViewLeft
			const viewWidth = scrollViewRef.current?.clientWidth ?? 0
			const centerOffset =
				offset - (viewWidth - reducedViewLeft) * focalPointPercentageOverride
			scrollViewRef.current?.scrollTo({ left: centerOffset })
		},
		[scrollViewRef, startDate, reducedViewLeft, focalPointPercentage, hourGap],
	)

	const focusViewOnMachine = useCallback(
		(machineId: string) => {
			const machineIndex = machines.findIndex(m => m.id === machineId)
			if (machineIndex === -1) return

			function adjustForViewport(targetY: number) {
				return targetY + offsetY
			}

			const screenHeight =
				screen instanceof Window
					? screen.innerHeight
					: (screen?.clientHeight ?? 0)

			const screenScrollY =
				screen instanceof Window ? screen.scrollY : (screen?.scrollTop ?? 0)

			const targetTop = machineIndex * rowHeight
			const targetBottom = targetTop + rowHeight * 2

			const viewportTop = screenScrollY
			const viewportBottom = screenScrollY + screenHeight

			const hitbox = rowHeight * 0.6

			if (
				adjustForViewport(Math.max(targetTop - hitbox, 0)) < viewportTop ||
				adjustForViewport(targetBottom + hitbox) > viewportBottom
			) {
				const topPlacement = Math.max(
					adjustForViewport(Math.max(targetTop - hitbox, 0)),
					0,
				)
				const bottomPlacement = Math.max(
					targetTop -
						screenHeight +
						stickySectionHeight +
						rowHeight * 4 +
						hitbox,
					0,
				)

				const newScrollY =
					Math.abs(screenScrollY - topPlacement) <
					Math.abs(screenScrollY - bottomPlacement)
						? topPlacement
						: bottomPlacement

				if (screen instanceof Window) {
					window.scrollTo({
						top: newScrollY,
						behavior: 'smooth',
					})
				} else if (screen) {
					screen.scroll({
						top: newScrollY,
						behavior: 'smooth',
					})
				}
			}
		},
		[machines, rowHeight, offsetY, stickySectionHeight, screen],
	)

	const captureCenterDate = useCallback(() => {
		const viewWidth = scrollViewRef.current?.clientWidth ?? 0
		const scrollLeft = scrollViewRef.current?.scrollLeft ?? 0
		const offsetAdjusted =
			scrollLeft +
			reducedViewLeft +
			(viewWidth - reducedViewLeft) * focalPointPercentage
		const newCenterDate = addMinutes(startDate, (offsetAdjusted / hourGap) * 60)
		if (
			!centerDate ||
			Math.abs(differenceInMinutes(centerDate, newCenterDate)) > 45
		) {
			setCenterDate(newCenterDate)
		}
	}, [
		scrollViewRef,
		centerDate,
		startDate,
		hourGap,
		reducedViewLeft,
		focalPointPercentage,
	])

	useEffect(() => {
		focusViewOnDate(centerDate)
	}, [zoomLevel, centerDate, focusViewOnDate])

	const createZoomFn = useCallback(
		(zoomFn?: () => void) => {
			if (!zoomFn) {
				return undefined
			}
			return () => {
				captureCenterDate()
				zoomFn()
			}
		},
		[captureCenterDate],
	)

	return { focusViewOnDate, focusViewOnMachine, createZoomFn }
}

export { useContextualZoom }
