import { TMachineBooking, TOrder } from '@repo/types'
import { isAfter, isBefore } from 'date-fns'

import { TPlanningAlert } from './types'

function getAlertsForMachine<
	T extends TPlanningAlert<TOrder, TMachineBooking>,
>(args: { alerts: T[]; machineId: string; startDate?: Date; endDate?: Date }) {
	const { alerts, machineId, startDate, endDate } = args

	return alerts.filter(alert => {
		if (startDate && isBefore(alert.startDate, startDate)) {
			return false
		}
		if (endDate && isAfter(alert.endDate, endDate)) {
			return false
		}
		switch (alert.category) {
			case 'transition-hard-linked':
				return alert.bookings[0]?.machineId === machineId
			case 'transition-soft-linked':
				return alert.bookings[0]?.machineId === machineId
			default:
				return alert.bookings.some(booking => booking.machineId === machineId)
		}
	})
}

export { getAlertsForMachine }
