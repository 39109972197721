import { Fragment, InputHTMLAttributes, useEffect, useMemo } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TMachine, TTimeRange, weekdays } from '@repo/types'
import { Plus, Trash2, X } from 'lucide-react'
import {
	Control,
	FieldErrors,
	SubmitHandler,
	useFieldArray,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

const timeRangeIsEmpty = (range: TTimeRange) =>
	range.from === '' && range.to === ''

const timeToMinutes = (time: string) => {
	const [hours, minutes] = time.split(':').map(Number)
	return hours * 60 + minutes
}

const noOverlaps = (ranges: TTimeRange[]) => {
	for (let i = 0; i < ranges.length; i++) {
		const current = ranges[i]
		for (let j = i + 1; j < ranges.length; j++) {
			const next = ranges[j]
			if (
				timeToMinutes(current.to) > timeToMinutes(next.from) &&
				timeToMinutes(current.from) < timeToMinutes(next.to)
			) {
				return false // Overlap found
			}
		}
	}
	return true // No overlaps
}

const MIDNIGHT = '00:00'

const timeRangeSchema = z
	.object({
		from: z
			.string()
			.trim()
			.regex(
				/^([01]\d|2[0-3]):([0-5]\d)$/,
				'Use 24-hour format (HH:mm), e.g. 13:45',
			)
			.or(z.literal('')),
		to: z
			.string()
			.trim()
			.regex(
				/^([01]\d|2[0-3]):([0-5]\d)$/,
				'Use 24-hour format (HH:mm), e.g. 13:45',
			)
			.or(z.literal(''))
			.or(z.literal('24:00')),
	})
	.refine(
		range =>
			(range.from === '' && (range.to === '' || range.to !== '00:00')) ||
			timeToMinutes(range.from) < timeToMinutes(range.to),
		{
			message: "'From' time must be earlier than 'To' time",
		},
	)

const timeRangeArraySchema = z
	.array(timeRangeSchema)
	.transform(ranges =>
		ranges
			.filter(range => !timeRangeIsEmpty(range))
			.map(range => ({
				from: range.from === '' ? MIDNIGHT : range.from,
				to:
					range.to === ''
						? MIDNIGHT
						: range.to === '24:00'
							? '23:59'
							: range.to,
			}))
			.sort((a, b) => timeToMinutes(a.from) - timeToMinutes(b.from)),
	)
	.refine(noOverlaps, { message: 'Time ranges must not overlap' })

const machineSchema = z.object({
	name: z
		.string({
			required_error: 'Please enter a name',
			invalid_type_error: 'Name must be a string',
		})
		.trim()
		.min(1, { message: 'Please enter a name' }),
	availability: z.object({
		monday: timeRangeArraySchema,
		tuesday: timeRangeArraySchema,
		wednesday: timeRangeArraySchema,
		thursday: timeRangeArraySchema,
		friday: timeRangeArraySchema,
		saturday: timeRangeArraySchema,
		sunday: timeRangeArraySchema,
	}),
})

type MachineSchema = z.infer<typeof machineSchema>

const initialAvailability: MachineSchema['availability'] = {
	monday: [],
	tuesday: [],
	wednesday: [],
	thursday: [],
	friday: [],
	saturday: [],
	sunday: [],
}

const allDayTimeRange: TTimeRange = { from: '00:00', to: '23:59' }

function AllDayButton(props: { onClick: () => void }) {
	const { onClick } = props

	return (
		<Button variant="outline" className="h-8" type="button" onClick={onClick}>
			24h
		</Button>
	)
}

function PlusButton(props: { onClick: () => void; className?: string }) {
	const { onClick, className } = props

	return (
		<Button
			variant="outline"
			className={cn('h-8 p-0', className)}
			type="button"
			onClick={onClick}
		>
			<Plus className="h-4 w-4" />
		</Button>
	)
}

function TrashButton(props: { onClick: () => void; className?: string }) {
	const { onClick, className } = props

	return (
		<Button
			variant="outline"
			className={cn('h-8 p-0', className)}
			type="button"
			onClick={onClick}
		>
			<Trash2 className="h-4 w-4" />
		</Button>
	)
}

function XButton(props: { onClick: () => void; className?: string }) {
	const { onClick, className } = props

	return (
		<Button
			variant="outline"
			className={cn('h-8 p-0', className)}
			type="button"
			onClick={onClick}
		>
			<X className="h-4 w-4" />
		</Button>
	)
}

const isAllDayTimeRange = (range: { from: string; to: string }) =>
	range.from === allDayTimeRange.from && range.to === allDayTimeRange.to

function WeekdayAvailabilityInput(props: {
	name: string
	fromField: {
		props: (index: number) => InputHTMLAttributes<HTMLInputElement>
		error: (index: number) => boolean
	}
	toField: {
		props: (index: number) => InputHTMLAttributes<HTMLInputElement>
		error: (index: number) => boolean
	}
	errorMessages: (index: number) => Array<string | undefined>
	watchedFirstField: { from: string; to: string }
	fields: Array<{ id: string; from: string; to: string }>
	onInsert: (index: number, range: { from: string; to: string }) => void
	onRemove: (index: number) => void
	separator?: boolean
}) {
	const {
		name,
		fromField,
		toField,
		errorMessages,
		watchedFirstField,
		fields,
		onInsert,
		onRemove,
		separator = true,
	} = props

	return (
		<>
			<Label className="text-right text-xs font-normal">{name}</Label>
			<div className="grid grid-cols-[minmax(156px,_1fr)_64px] gap-2 sm:grid-cols-[minmax(156px,_1fr)_96px]">
				{fields.length === 0 && (
					<>
						<PlusButton onClick={() => onInsert(0, { from: '', to: '' })} />
						<AllDayButton onClick={() => onInsert(0, allDayTimeRange)} />
					</>
				)}
				{fields.length === 1 && isAllDayTimeRange(watchedFirstField) ? (
					<>
						<Input
							className={cn('h-8 text-center')}
							value="All day (24 hours)"
							disabled
						/>
						<XButton onClick={() => onRemove(0)} />
					</>
				) : (
					fields.map((field, index) => {
						return (
							<Fragment key={field.id}>
								<div className="grid w-full grid-cols-[1fr_auto_1fr] items-center gap-x-2">
									<Input
										className={cn('h-8 text-center')}
										error={fromField.error(index)}
										placeholder={MIDNIGHT}
										{...fromField.props(index)}
									/>
									<Label className="text-center text-xs font-normal">to</Label>
									<Input
										className="h-8 text-center"
										error={toField.error(index)}
										placeholder={MIDNIGHT}
										{...toField.props(index)}
									/>
								</div>
								<div className="grid grid-cols-2 gap-x-1">
									<PlusButton
										onClick={() => onInsert(index + 1, { from: '', to: '' })}
									/>
									<TrashButton onClick={() => onRemove(index)} />
								</div>
								{errorMessages(index).filter(Boolean).at(0) && (
									// Only show the first error message
									<span
										key={index}
										className="col-span-full -mt-1 text-xs text-destructive"
									>
										{errorMessages(index).filter(Boolean).at(0)}
									</span>
								)}
							</Fragment>
						)
					})
				)}
				{separator && <Separator className="col-span-full" />}
			</div>
		</>
	)
}

function useDayFieldArray(args: {
	day: (typeof weekdays)[number]
	control: Control<MachineSchema>
}) {
	const { fields, insert, remove } = useFieldArray({
		name: `availability.${args.day}`,
		control: args.control,
	})
	return { fields, insert, remove }
}

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<MachineSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function MachineForm(props: {
	submitButtons: SubmitButtonsRenderer
	existingNames: string[]
	onSubmit: SubmitHandler<MachineSchema>
	onError?: (errors: FieldErrors<MachineSchema>) => void
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Omit<TMachine, 'id'>
}) {
	const {
		submitButtons,
		existingNames,
		onSubmit,
		onError,
		onUnsavedChanges,
		initialValues,
	} = props

	const schema = useMemo(() => {
		return machineSchema.refine(
			data => !existingNames.includes(data.name),
			data => ({
				message: `Machine "${data.name}" already exists`,
				path: ['name'],
			}),
		)
	}, [existingNames])
	const {
		register,
		handleSubmit,
		control,
		watch,
		trigger,
		setValue,
		formState: { errors, isDirty },
	} = useForm<MachineSchema>({
		resolver: zodResolver(schema),
		defaultValues: initialValues ?? {
			name: '',
			availability: initialAvailability,
		},
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	const weekdayFieldArrayProps = {
		monday: useDayFieldArray({ day: 'monday', control }),
		tuesday: useDayFieldArray({ day: 'tuesday', control }),
		wednesday: useDayFieldArray({ day: 'wednesday', control }),
		thursday: useDayFieldArray({ day: 'thursday', control }),
		friday: useDayFieldArray({ day: 'friday', control }),
		saturday: useDayFieldArray({ day: 'saturday', control }),
		sunday: useDayFieldArray({ day: 'sunday', control }),
	} as const

	const showWeekdaySeparators =
		Object.keys(weekdayFieldArrayProps).reduce((totalFields, key) => {
			const day = key as keyof typeof weekdayFieldArrayProps
			const fields = weekdayFieldArrayProps[day].fields
			return totalFields + Math.max(1, fields.length)
		}, 0) > 7

	function handleTimeChange(
		name: `availability.${(typeof weekdays)[number]}.${number}.${keyof TTimeRange}`,
	) {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			// Transforms a numeric string into a time format.
			// For 1-2 digits: unchanged (e.g., '1' -> '1', '12' -> '12').
			// For 3-4 digits: inserts a colon (e.g., '123' -> '12:3', '1234' -> '12:34').
			const value = e.target.value
			const transformedValue = value.replace(
				/(\d{1,2})(\d{0,2})/,
				(_match, firstPart, secondPart) => {
					// Check if the first part is a single digit between 3 and 9 and prepend a 0 if true
					if (firstPart.length === 1 && parseInt(firstPart, 10) >= 3) {
						firstPart = `0${firstPart}`
					}

					return secondPart.length === 0
						? firstPart
						: `${firstPart}:${secondPart}`
				},
			)

			setValue(name, transformedValue)
		}
	}

	function handleTimeBlur(
		name: `availability.${(typeof weekdays)[number]}.${number}.${keyof TTimeRange}`,
	) {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value

			// Match only integers between 0 and 24, including 00, 01, ..., 24.
			const isValidHour = /^(0?[0-9]|1[0-9]|2[0-4])$/.test(value)

			let transformedValue = value

			if (isValidHour) {
				transformedValue = `${value.padStart(2, '0')}:00`
			}

			setValue(name, transformedValue)
			trigger(name)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
			<div className="grid gap-6 pb-6 pt-4">
				<div className="grid grid-cols-[66px_1fr] items-center gap-x-4 gap-y-2">
					<Label htmlFor="name" className="text-right">
						Name
					</Label>
					<Input
						id="name"
						error={Boolean(errors.name)}
						{...register('name', { required: true })}
					/>
					{errors.name && (
						<span className="col-start-2 -mt-1 text-xs text-destructive">
							{errors.name.message}
						</span>
					)}
				</div>
				<div className="grid grid-cols-[66px_1fr] items-center gap-x-4 gap-y-2">
					<Label className="col-span-full">Availability</Label>
					<Separator className="col-span-full" />
					{weekdays.map(weekday => (
						<WeekdayAvailabilityInput
							key={weekday}
							name={weekday.charAt(0).toUpperCase() + weekday.slice(1)}
							fromField={{
								props: index => ({
									...register(`availability.${weekday}.${index}.from`, {
										required: true,
										onChange: handleTimeChange(
											`availability.${weekday}.${index}.from`,
										),
										onBlur: handleTimeBlur(
											`availability.${weekday}.${index}.from`,
										),
									}),
									id: `availability.${weekday}.${index}.from`,
								}),
								error: index =>
									Boolean(
										errors.availability?.[weekday]?.[index]?.from ||
											errors.availability?.[weekday]?.[index]?.root ||
											errors.availability?.[weekday]?.root,
									),
							}}
							toField={{
								props: index => ({
									...register(`availability.${weekday}.${index}.to`, {
										required: true,
										onChange: handleTimeChange(
											`availability.${weekday}.${index}.to`,
										),
										onBlur: handleTimeBlur(
											`availability.${weekday}.${index}.to`,
										),
									}),
									id: `availability.${weekday}.${index}.to`,
								}),
								error: index =>
									Boolean(
										errors.availability?.[weekday]?.[index]?.to ||
											errors.availability?.[weekday]?.[index]?.root ||
											errors.availability?.[weekday]?.root,
									),
							}}
							errorMessages={index => [
								errors.availability?.[weekday]?.[index]?.from?.message,
								errors.availability?.[weekday]?.[index]?.to?.message,
								errors.availability?.[weekday]?.[index]?.root?.message,
								errors.availability?.[weekday]?.root?.message,
							]}
							fields={weekdayFieldArrayProps[weekday].fields}
							watchedFirstField={watch(`availability.${weekday}.0`)}
							onInsert={(index, range) => {
								const prevWeekdayIndex =
									weekdays.findIndex(day => day === weekday) - 1
								const canUsePrevRange =
									prevWeekdayIndex >= 0 &&
									index <=
										weekdayFieldArrayProps[weekdays[prevWeekdayIndex]].fields
											.length -
											1 &&
									!errors.availability?.[weekdays[prevWeekdayIndex]]?.[index]
										?.root?.message &&
									!errors.availability?.[weekdays[prevWeekdayIndex]]?.[index]
										?.from?.message &&
									!errors.availability?.[weekdays[prevWeekdayIndex]]?.[index]
										?.to?.message
								if (timeRangeIsEmpty(range) && canUsePrevRange) {
									const prevRange = watch(
										`availability.${weekdays[prevWeekdayIndex]}.${index}`,
									)
									if (
										prevRange.from === allDayTimeRange.from &&
										prevRange.to === allDayTimeRange.to
									) {
										weekdayFieldArrayProps[weekday].insert(index, range)
									} else {
										weekdayFieldArrayProps[weekday].insert(index, {
											from: prevRange.from,
											to: prevRange.to,
										})
									}
								} else {
									weekdayFieldArrayProps[weekday].insert(index, range)
								}
							}}
							onRemove={weekdayFieldArrayProps[weekday].remove}
							separator={weekday !== 'sunday' && showWeekdaySeparators}
						/>
					))}
				</div>
			</div>
			<DialogFooter>
				{typeof submitButtons === 'function'
					? submitButtons({ handleSubmit })
					: submitButtons}
			</DialogFooter>
		</form>
	)
}

export { MachineForm }
