const formatTimeUnit = {
	seconds: (seconds: number | string = 0) =>
		Number(seconds) === 1 ? 'sec' : 'secs',
	minutes: (minutes: number | string = 0) =>
		Number(minutes) === 1 ? 'min' : 'mins',
	hours: (hours: number | string = 0) => (Number(hours) === 1 ? 'hr' : 'hrs'),
	days: (days: number | string = 0) => (Number(days) === 1 ? 'day' : 'days'),
	seconds_per_piece: () => 'sec/piece',
	minutes_per_piece: () => 'min/piece',
	pieces_per_second: (pieces: number | string = 0) =>
		Number(pieces) === 1 ? 'piece/sec' : 'pieces/sec',
	pieces_per_minute: (pieces: number | string = 0) =>
		Number(pieces) === 1 ? 'piece/min' : 'pieces/min',
}

export { formatTimeUnit }
