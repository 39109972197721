import { TMachine } from '@repo/types'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { MachineForm } from '@/features/machines/forms/machine-form'
import {
	createMachine,
	selectMachineNames,
} from '@/features/machines/machines-slice'

const Route = createFileRoute('/machines/create')({
	component: CreateMachineComponent,
})

function CreateMachineComponent() {
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const machineNames = useAppSelector(selectMachineNames)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<Omit<TMachine, 'id'>>({
			onSubmit: data => {
				// TODO: Try using async thunk for this, and change the history so when you have created the machine and navigate back, it will go to the edit route instead of the create route
				dispatch(createMachine(data))
				toast.success(`Machine "${data.name}" created`)
			},
			onClose: () => {
				navigate({
					to: '/machines',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog title="Create machine" {...formDialogProps}>
				<MachineForm
					submitButtons={<Button type="submit">Create Machine</Button>}
					existingNames={machineNames}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
