import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'

function GenericAlertDialog(props: {
	children?: React.ReactNode
	title: string
	description: string
	confirmButtonText: string
	confirmButtonDisabled?: boolean
	onClose: () => void
	onConfirm: () => void
	cancelButtonText?: string
	open?: boolean
	container?: HTMLElement | null
}) {
	function handleOpenChange(open: boolean) {
		if (!open) {
			props.onClose()
		}
	}

	return (
		<AlertDialog open={props.open ?? true} onOpenChange={handleOpenChange}>
			<AlertDialogContent
				className="max-h-[90dvh] w-[95dvw] overflow-y-auto sm:max-w-[425px]"
				container={props.container}
			>
				<AlertDialogHeader>
					<AlertDialogTitle>{props.title}</AlertDialogTitle>
					<AlertDialogDescription>{props.description}</AlertDialogDescription>
				</AlertDialogHeader>
				{props.children}
				<AlertDialogFooter>
					<AlertDialogCancel onClick={props.onClose}>
						{props.cancelButtonText ?? 'Cancel'}
					</AlertDialogCancel>
					<AlertDialogAction
						disabled={props.confirmButtonDisabled}
						onClick={props.onConfirm}
					>
						{props.confirmButtonText}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export { GenericAlertDialog }
