import { TMachineAvailability, TPeriod, weekdays } from '@repo/types'
import {
	addDays,
	areIntervalsOverlapping,
	differenceInMinutes,
	endOfDay,
	getDay,
	setHours,
	setMinutes,
	setSeconds,
	startOfDay,
} from 'date-fns'

const getOpenPeriods = (args: {
	startDate: Date
	endDate: Date
	availability: TMachineAvailability
}): TPeriod[] => {
	const { startDate, endDate, availability } = args
	const openPeriods: TPeriod[] = []

	const normalizedStartDate = startOfDay(startDate)
	const normalizedEndDate = endOfDay(endDate)

	for (
		let currentDate = normalizedStartDate;
		currentDate <= normalizedEndDate;
		currentDate = addDays(currentDate, 1)
	) {
		const dayOfWeek = weekdays[(getDay(currentDate) + 6) % 7]
		const times = availability[dayOfWeek]

		times.forEach(({ from, to }) => {
			const [fromHours, fromMinutes] = from.split(':').map(Number)
			const [toHours, toMinutes] = to.split(':').map(Number)
			const startTime = setSeconds(
				setMinutes(setHours(currentDate, fromHours), fromMinutes),
				0,
			)
			const endTime = setSeconds(
				setMinutes(setHours(currentDate, toHours), toMinutes),
				0,
			)
			if (
				areIntervalsOverlapping(
					{ start: startTime, end: endTime },
					{ start: startDate, end: endDate },
					{ inclusive: true },
				)
			) {
				openPeriods.push({
					startDate: startTime,
					endDate: endTime,
				})
			}
		})
	}

	// Adjust for overlapping or consecutive periods
	const mergedOpenPeriods = openPeriods.reduce<TPeriod[]>((acc, current) => {
		const last = acc[acc.length - 1]
		if (last && differenceInMinutes(current.startDate, last.endDate) <= 1) {
			// Merge current period into last if they overlap or are consecutive within a minute
			last.endDate = current.endDate
		} else {
			acc.push(current)
		}
		return acc
	}, [])

	return mergedOpenPeriods
}

export { getOpenPeriods }
