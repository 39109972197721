import { Link } from '@tanstack/react-router'

import { buttonVariants } from './ui/button'

export function NotFound() {
	return (
		<div className="flex w-full flex-1 flex-col items-center justify-center gap-4 p-8">
			<p className="text-lg text-muted-foreground">Not found!</p>
			<Link to="/" className={buttonVariants({ variant: 'default' })}>
				Go home
			</Link>
		</div>
	)
}
