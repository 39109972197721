import { useMemo } from 'react'

function isTouchDevice() {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export function useTouchDeviceDetector() {
	const isTouchDeviceMemoized = useMemo(() => isTouchDevice(), [])

	return {
		isTouchDevice: isTouchDeviceMemoized,
	}
}
