import {
	TCalendarAdjustment,
	TCalendarPeriodAdjustment,
	TMachine,
	TMachineAvailability,
	TMachineBooking,
	TPeriod,
	TPeriodString,
	TPhasedItem,
	TPhaseDuration,
	TPhaseSchedule,
	TPlannedMachineBooking,
	TPlanningParameters,
	TProductionTimeUnit,
	TProductOperationPhases,
	TSchedulingDirection,
	TTimeUnit,
} from '@repo/types'
import {
	addMinutes,
	addYears,
	areIntervalsOverlapping,
	differenceInSeconds,
	max,
	min,
	startOfDay,
	subMinutes,
	subYears,
} from 'date-fns'

import { getToolPeriods } from '../validation/validate-tool-overlaps'
import { adjustOpenPeriods } from './adjust-open-periods'
import { getCalendarAdjustmentsForMachine } from './get-calendar-adjustments-for-machine'
import { getOpenPeriods } from './get-open-periods'

export function convertToMinutes(
	args: {
		quantity?: number
		unit?: 'seconds' | 'minutes' | 'hours' | 'days'
	} = {},
): number {
	const quantity = args.quantity ?? 0
	switch (args.unit) {
		case 'seconds':
			return Math.ceil(quantity / 60)
		case 'minutes':
			return Math.ceil(quantity)
		case 'hours':
			return Math.ceil(quantity * 60)
		case 'days':
			return Math.ceil(quantity * 60 * 24)
		default:
			return 0
	}
}

export function getPhaseDurationMinutes(
	phaseDuration?: TPhaseDuration<TTimeUnit>,
) {
	return convertToMinutes(phaseDuration)
}
export function getProductionDurationMinutes(args: {
	productionDuration: TPhaseDuration<TProductionTimeUnit>
	quantity: number
}): number {
	const { productionDuration, quantity } = args
	const { unit, quantity: phaseQuantity } = productionDuration

	switch (unit) {
		case 'minutes':
			return Math.ceil(phaseQuantity)
		case 'seconds_per_piece':
			return Math.ceil((phaseQuantity * quantity) / 60)
		case 'minutes_per_piece':
			return Math.ceil(phaseQuantity * quantity)
		case 'pieces_per_second':
			return Math.ceil(quantity / phaseQuantity / 60)
		case 'pieces_per_minute':
			return Math.ceil(quantity / phaseQuantity)
		default:
			return 0
	}
}

export function getTotalOperationDurationMinutes(args: {
	setupDuration?: TPhaseDuration<TTimeUnit>
	productionDuration: TPhaseDuration<TProductionTimeUnit>
	teardownDuration?: TPhaseDuration<TTimeUnit>
	quantity: number
}): number {
	const { setupDuration, productionDuration, teardownDuration, quantity } = args
	const setupTime = getPhaseDurationMinutes(setupDuration)
	const teardownTime = getPhaseDurationMinutes(teardownDuration)
	const productionTime = getProductionDurationMinutes({
		productionDuration,
		quantity,
	})

	return setupTime + teardownTime + productionTime
}

function scheduleForwardFromEarliestStartDate(args: {
	earliestStartDate: Date
	dueDate: Date
	durationMinutes: number
	availability: TMachineAvailability
	calendarAdjustments: TCalendarPeriodAdjustment[]
}): TPeriod | null {
	const {
		earliestStartDate,
		dueDate,
		durationMinutes,
		availability,
		calendarAdjustments,
	} = args
	const startDate = earliestStartDate
	const endDate = addYears(max([dueDate, new Date()]), 5)
	const openPeriods = adjustOpenPeriods({
		startDate,
		endDate,
		calendarAdjustments,
		openPeriods: getOpenPeriods({
			startDate,
			endDate,
			availability,
		}),
	})
	if (openPeriods.length === 0) {
		console.warn('Could not find any open periods for scheduling.', args)
		return null
	}
	const currentStartDate = max([earliestStartDate, openPeriods[0].startDate])
	let currentEndDate = currentStartDate
	let minutesRemaining = durationMinutes

	for (const period of openPeriods) {
		if (currentEndDate < period.startDate) {
			currentEndDate = period.startDate
		}
		const periodStartDate = max([currentStartDate, period.startDate])
		const gain = Math.min(
			minutesRemaining,
			Math.ceil(differenceInSeconds(period.endDate, periodStartDate) / 60),
		)

		minutesRemaining -= gain
		currentEndDate = addMinutes(currentEndDate, gain)

		if (minutesRemaining <= 0) break
	}

	return minutesRemaining <= 0
		? { startDate: currentStartDate, endDate: currentEndDate }
		: null
}

function scheduleBackwardFromDueDate(args: {
	earliestStartDate: Date
	dueDate: Date
	durationMinutes: number
	availability: TMachineAvailability
	calendarAdjustments: TCalendarPeriodAdjustment[]
}): TPeriod | null {
	const {
		earliestStartDate,
		dueDate,
		durationMinutes,
		availability,
		calendarAdjustments,
	} = args
	const startDate = subYears(min([earliestStartDate, new Date()]), 5)
	const endDate = dueDate
	const openPeriods = adjustOpenPeriods({
		startDate,
		endDate,
		calendarAdjustments,
		openPeriods: getOpenPeriods({
			startDate,
			endDate,
			availability,
		}),
	}).reverse()
	if (openPeriods.length === 0) {
		console.warn('Could not find any open periods for scheduling.', args)
		return null
	}

	const currentEndDate = min([dueDate, openPeriods[0].endDate])
	let currentStartDate = currentEndDate
	let minutesRemaining = durationMinutes

	for (const period of openPeriods) {
		if (currentStartDate > period.endDate) {
			currentStartDate = period.endDate
		}

		const periodEndDate = min([currentStartDate, period.endDate])
		const gain = Math.min(
			minutesRemaining,
			Math.ceil(differenceInSeconds(periodEndDate, period.startDate) / 60),
		)

		minutesRemaining -= gain
		currentStartDate = subMinutes(currentStartDate, gain)

		if (minutesRemaining <= 0) break
	}

	return minutesRemaining <= 0
		? { startDate: currentStartDate, endDate: currentEndDate }
		: null
}

function checkToolConflict<T extends TPeriod | TPeriodString = TPeriod>(args: {
	existingBooking: TMachineBooking
	machine: Pick<TMachine, 'id'>
	tools: TPhasedItem[]
	phases: TPhaseSchedule<T>
}): boolean {
	const { existingBooking, machine, tools, phases } = args
	const { id: machineId } = machine

	if (
		existingBooking.machineId === machineId ||
		existingBooking.status === 'completed'
	) {
		return false
	}

	const existingTools = new Set(existingBooking.tools.map(tool => tool.name))

	const relevantTools = tools.filter(tool => existingTools.has(tool.name))

	for (const tool of relevantTools) {
		const toolName = tool.name
		const existingPeriods = getToolPeriods({
			toolName,
			tools: existingBooking.tools,
			phases: {
				before: {
					startDate: new Date(existingBooking.phases.before.startDate),
					endDate: new Date(existingBooking.phases.before.endDate),
				},
				during: {
					startDate: new Date(existingBooking.phases.during.startDate),
					endDate: new Date(existingBooking.phases.during.endDate),
				},
				after: {
					startDate: new Date(existingBooking.phases.after.startDate),
					endDate: new Date(existingBooking.phases.after.endDate),
				},
			},
		})
		const newPeriods = getToolPeriods({ toolName, tools, phases })

		for (const existingPeriod of existingPeriods) {
			for (const newPeriod of newPeriods) {
				if (
					areIntervalsOverlapping(
						{ start: existingPeriod.startDate, end: existingPeriod.endDate },
						{ start: newPeriod.startDate, end: newPeriod.endDate },
					)
				) {
					return true
				}
			}
		}
	}

	return false
}

function checkOverlapConflict<
	T extends TPeriod | TPeriodString = TPeriod,
>(args: {
	existingBooking: TMachineBooking
	machine: Pick<TMachine, 'id'>
	periods: TPhaseSchedule<T>
}): boolean {
	const { existingBooking, machine, periods } = args

	return (
		existingBooking.machineId === machine.id &&
		existingBooking.status !== 'completed' &&
		areIntervalsOverlapping(
			{ start: existingBooking.startDate, end: existingBooking.endDate },
			{ start: periods.before.startDate, end: periods.after.endDate },
		)
	)
}

export function scheduleOperation(args: {
	phases: TProductOperationPhases
	planningParameters: Pick<
		TPlanningParameters,
		'quantity' | 'earliestStartDate' | 'dueDate' | 'buffer'
	>
	machine: Pick<TMachine, 'id' | 'availability'>
	calendarAdjustments: TCalendarPeriodAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
	tools: TPhasedItem[]
}): Pick<
	TMachineBooking,
	'startDate' | 'endDate' | 'phases' | 'effectiveTimeMinutes'
> | null {
	const {
		phases,
		machine,
		calendarAdjustments,
		bookings,
		planningParameters,
		direction,
		tools,
	} = args
	const { quantity, earliestStartDate, dueDate } = planningParameters
	const productionDuration = phases.during.find(
		productionPhase => productionPhase.machine.id === machine.id,
	)?.duration ?? {
		quantity: 0,
		unit: 'minutes',
	}
	const [setupTime, productionTime, teardownTime] = [
		getPhaseDurationMinutes(phases.before),
		getProductionDurationMinutes({
			productionDuration,
			quantity,
		}),
		getPhaseDurationMinutes(phases.after),
	]

	const forwardDueDate = new Date(dueDate)
	const backwardDueDate = subMinutes(
		dueDate,
		convertToMinutes(planningParameters.buffer),
	)

	let attemptedEarliestStartDate = new Date(earliestStartDate)
	let attemptedDueDate =
		direction === 'backward' ? backwardDueDate : forwardDueDate

	let tries = 5_000

	let before: TPeriod = { startDate: new Date(0), endDate: new Date(0) }
	let during: TPeriod = { startDate: new Date(0), endDate: new Date(0) }
	let after: TPeriod = { startDate: new Date(0), endDate: new Date(0) }

	while (tries-- > 0) {
		if (direction === 'backward') {
			const newAfter = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: attemptedDueDate,
				durationMinutes: teardownTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newAfter) return null
			after = newAfter
			const newDuring = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: newAfter.startDate,
				durationMinutes: productionTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newDuring) return null
			during = newDuring
			const newBefore = scheduleBackwardFromDueDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: newDuring.startDate,
				durationMinutes: setupTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newBefore) return null
			before = newBefore
		} else {
			const newBefore = scheduleForwardFromEarliestStartDate({
				earliestStartDate: attemptedEarliestStartDate,
				dueDate: attemptedDueDate,
				durationMinutes: setupTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newBefore) return null
			before = newBefore
			const newDuring = scheduleForwardFromEarliestStartDate({
				earliestStartDate: newBefore.endDate,
				dueDate: attemptedDueDate,
				durationMinutes: productionTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newDuring) return null
			during = newDuring
			const newAfter = scheduleForwardFromEarliestStartDate({
				earliestStartDate: newDuring.endDate,
				dueDate: attemptedDueDate,
				durationMinutes: teardownTime,
				availability: machine.availability,
				calendarAdjustments,
			})
			if (!newAfter) return null
			after = newAfter
		}

		const conflicts = bookings.filter(booking => {
			const hasOverlap = checkOverlapConflict({
				existingBooking: booking,
				machine,
				periods: { before, during, after },
			})

			const hasToolConflict = checkToolConflict({
				existingBooking: booking,
				machine,
				tools,
				phases: { before, during, after },
			})

			return hasOverlap || hasToolConflict
		})

		if (conflicts.length === 0) break

		if (direction === 'backward') {
			attemptedDueDate = subMinutes(
				min(conflicts.map(booking => booking.startDate)),
				1,
			)
		} else {
			attemptedEarliestStartDate = addMinutes(
				max(conflicts.map(booking => booking.endDate)),
				1,
			)
		}
	}

	if (tries <= 0) {
		console.warn('Exceeded maximum scheduling attempts.', args)
		return null
	}

	return {
		startDate: before.startDate.toISOString(),
		endDate: after.endDate.toISOString(),
		phases: {
			before: {
				startDate: before.startDate.toISOString(),
				endDate: before.endDate.toISOString(),
			},
			during: {
				startDate: during.startDate.toISOString(),
				endDate: during.endDate.toISOString(),
			},
			after: {
				startDate: after.startDate.toISOString(),
				endDate: after.endDate.toISOString(),
			},
		},
		effectiveTimeMinutes: {
			before: setupTime,
			during: productionTime,
			after: teardownTime,
			total: setupTime + productionTime + teardownTime,
		},
	}
}

function scheduleOrder(args: {
	planningParameters: TPlanningParameters
	calendarAdjustments: TCalendarAdjustment[]
	bookings: TMachineBooking[]
	direction: TSchedulingDirection
	earliestAllowedStartDate?: Date
}): Omit<TPlannedMachineBooking, 'id'>[] | null {
	const {
		planningParameters,
		calendarAdjustments,
		bookings,
		direction,
		earliestAllowedStartDate = new Date(0),
	} = args
	const { operations, orderId } = planningParameters
	const newBookings: Omit<TPlannedMachineBooking, 'id'>[] = []
	let earliestStartDate = max([
		planningParameters.earliestStartDate,
		earliestAllowedStartDate,
	])
	let dueDate = planningParameters.dueDate
	const operationsInSchedulingOrder =
		direction === 'backward' ? [...operations].reverse() : operations
	for (let i = 0; i < operationsInSchedulingOrder.length; i++) {
		const operation = operationsInSchedulingOrder[i]
		const machine = operation.machines[0]
		if (machine) {
			const plannedOperation = scheduleOperation({
				direction,
				phases: operation.phases,
				planningParameters: {
					...planningParameters,
					earliestStartDate:
						operation.earliestStartDate ?? earliestStartDate.toISOString(), // TODO: Should operations have an earliestStartDate?
					dueDate,
				},
				machine,
				bookings,
				tools: operation.tools,
				calendarAdjustments: getCalendarAdjustmentsForMachine({
					machineId: machine.id,
					calendarAdjustments,
				}),
			})
			if (!plannedOperation) return null
			newBookings.push({
				...plannedOperation,
				status: 'planned',
				machineId: machine.id,
				orderId,
				productId: planningParameters.productId,
				operationId: operation.id,
				tools: operation.tools,
				compatibleMachines: operation.machines.map(machine => machine.id),
			})
			if (direction === 'backward') {
				const waitingTimeMinutes = convertToMinutes(
					operationsInSchedulingOrder[i + 1]?.transition?.waitingTime,
				)
				dueDate = subMinutes(
					plannedOperation.startDate,
					waitingTimeMinutes,
				).toISOString()
			} else {
				const waitingTimeMinutes = convertToMinutes(
					operation.transition?.waitingTime,
				)
				earliestStartDate = addMinutes(
					plannedOperation.endDate,
					waitingTimeMinutes,
				)
			}
		}
	}

	if (direction === 'backward') {
		const backwardBookings = [...newBookings].reverse()
		const backwardStartDate = startOfDay(backwardBookings[0].startDate)
		const forwardBookings = scheduleOrder({
			planningParameters: {
				...planningParameters,
				earliestStartDate: max([
					planningParameters.earliestStartDate,
					backwardStartDate,
				]).toISOString(),
			},
			calendarAdjustments,
			bookings,
			direction: 'forward',
			earliestAllowedStartDate,
		})
		return forwardBookings
	} else {
		return newBookings
	}
}

export { scheduleOrder }
