import {
	TMachine,
	TMachineReference,
	TPhaseDuration,
	TProductionTimeUnit,
	TProductOperation,
} from '@repo/types'

function enhanceProductOperation(
	productOperation: TProductOperation<TMachineReference>,
	machines: TMachine[],
): TProductOperation<TMachine> {
	const enhancedProductionPhases = productOperation.phases.during
		.map(({ machine, duration }) => ({
			machine: machines.find(m => m.id === machine.id),
			duration,
		}))
		.filter(
			(
				phase,
			): phase is {
				machine: TMachine
				duration: TPhaseDuration<TProductionTimeUnit>
			} => Boolean(phase.machine),
		)

	return {
		...productOperation,
		phases: {
			...productOperation.phases,
			during: enhancedProductionPhases,
		},
	}
}

export { enhanceProductOperation }
