import { Fragment, useEffect, useMemo } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TMachine, TProductionTimeUnit, TProductOperation } from '@repo/types'
import { Plus, Trash2, X } from 'lucide-react'
import {
	Controller,
	FieldErrors,
	SubmitHandler,
	useFieldArray,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Combobox } from '@/components/ui/combobox'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { type TStaffGroup } from '@/features/staff-groups/staff-groups-slice'
import { type TTool } from '@/features/tools/tools-slice'
import { cn } from '@/lib/utils'
import {
	decimalSeparator,
	thousandSeparator,
} from '@/utils/format-numeric-string'

import { getFormattedPhaseDuration } from '../utils/get-formatted-phase-duration'
import {
	ProductOperationOutputSchema,
	productOperationSchema,
	type PhasedItemInputSchema,
	type ProductOperationInputSchema,
} from './product-schema'

// TODO: Use Toggle Group component: https://ui.shadcn.com/docs/components/toggle-group
const PhaseButtons = (props: {
	name: string
	factor: PhasedItemInputSchema['factor']
	phases: PhasedItemInputSchema['phases']
	error: boolean
	onChange: (value: PhasedItemInputSchema) => void
}) => {
	const { name, factor, phases, error, onChange } = props
	if (name.length === 0) {
		return null
	}

	const buttons = [
		{
			name: 'before',
			value: phases.before,
			label: 'Before',
		},
		{
			name: 'during',
			value: phases.during,
			label: 'During',
		},
		{
			name: 'after',
			value: phases.after,
			label: 'After',
		},
	]

	return (
		<div className="grid grid-cols-3 gap-1">
			{buttons.map(button => (
				<Button
					key={name + button.name}
					type="button"
					className={cn(
						'text-xs font-normal',
						error && 'border border-destructive',
					)}
					variant={button.value ? 'default' : 'secondary'}
					onClick={() =>
						onChange({
							name: name,
							factor: factor,
							phases: {
								...phases,
								[button.name]: !button.value,
							},
						})
					}
				>
					{button.label}
				</Button>
			))}
		</div>
	)
}

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<ProductOperationInputSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function ProductOperationForm(props: {
	machines: TMachine[]
	tools: TTool[]
	staffGroups: TStaffGroup[]
	existingNames: string[]
	submitButtons: SubmitButtonsRenderer
	onSubmit: SubmitHandler<ProductOperationOutputSchema>
	onError?: (errors: FieldErrors<ProductOperationInputSchema>) => void
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Omit<TProductOperation, 'id' | 'productId'>
}) {
	const {
		machines,
		tools,
		staffGroups,
		existingNames,
		submitButtons,
		onSubmit,
		onError,
		onUnsavedChanges,
		initialValues,
	} = props

	const schema = useMemo(() => {
		return productOperationSchema.refine(
			data => !existingNames.includes(data.name),
			data => ({
				message: `Operation "${data.name}" already exists`,
				path: ['name'],
			}),
		)
	}, [existingNames])
	const {
		register,
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm<
		ProductOperationInputSchema,
		unknown,
		ProductOperationOutputSchema
	>({
		resolver: zodResolver(schema),
		defaultValues: initialValues,
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	const fieldArrays = {
		machineDurations: useFieldArray({
			name: 'phases.during',
			keyName: 'key',
			control,
		}),
		tools: useFieldArray({
			name: 'tools',
			control,
		}),
		staffGroups: useFieldArray({
			name: 'staffGroups',
			control,
		}),
	}

	const watchedPhases = watch('phases')
	const watchedTools = watch('tools', [])
	const watchedToolNames = new Set(watchedTools.map(t => t.name))
	const watchedStaffGroups = watch('staffGroups', [])
	const watchedStaffGroupNames = new Set(watchedStaffGroups.map(s => s.name))
	const watchedMachineDurations = watch('phases.during', [])
	const watchedMachineIds = new Set(
		watchedMachineDurations.map(machineDuration => machineDuration.machine.id),
	)

	const formattedBeforePhase = getFormattedPhaseDuration(watchedPhases.before)
	const formattedDuringPhase = getFormattedPhaseDuration(
		watchedPhases.during[0]?.duration,
	)
	const formattedAfterPhase = getFormattedPhaseDuration(watchedPhases.after)

	return (
		<form
			onSubmit={e => {
				e.stopPropagation()
				handleSubmit(onSubmit, onError)(e)
			}}
			autoComplete="off"
		>
			<div className="grid gap-6 pb-6 pt-4">
				<div className="-mb-2 grid grid-cols-[auto_1fr] items-center gap-x-4 gap-y-2">
					<Label htmlFor="name">Name</Label>
					<Input
						id="name"
						className="flex-grow"
						error={Boolean(errors.name)}
						{...register('name', { required: true })}
					/>
					{errors.name && (
						<span className="col-start-2 -mt-1 text-xs text-destructive">
							{errors.name.message}
						</span>
					)}
				</div>
				<Tabs defaultValue="during">
					<TabsList className="grid h-auto w-full grid-cols-3">
						<TabsTrigger
							value="before"
							className={cn(
								errors.phases?.before &&
									'!shadow-[inset_0_0_0_2px_hsl(var(--destructive))]',
							)}
						>
							<div className="flex flex-col">
								<div className="text-base text-foreground">Before</div>
								<div className="text-xs font-normal uppercase text-muted-foreground">
									Setup
								</div>
								<div className="text-[10px] font-normal leading-tight text-muted-foreground">
									{formattedBeforePhase.quantity} {formattedBeforePhase.unit}
								</div>
							</div>
						</TabsTrigger>
						<TabsTrigger
							value="during"
							className={cn(
								errors.phases?.during &&
									'!shadow-[inset_0_0_0_2px_hsl(var(--destructive))]',
							)}
						>
							<div className="flex flex-col">
								<div className="text-base text-foreground">During</div>
								<div className="text-xs font-normal uppercase text-muted-foreground">
									Production
								</div>
								<div className="text-[10px] font-normal leading-tight text-muted-foreground">
									{formattedDuringPhase.quantity} {formattedDuringPhase.unit}
								</div>
							</div>
						</TabsTrigger>
						<TabsTrigger
							value="after"
							className={cn(
								errors.phases?.after &&
									'!shadow-[inset_0_0_0_2px_hsl(var(--destructive))]',
							)}
						>
							<div className="flex flex-col">
								<div className="text-base text-foreground">After</div>
								<div className="text-xs font-normal uppercase text-muted-foreground">
									Teardown
								</div>
								<div className="text-[10px] font-normal leading-tight text-muted-foreground">
									{formattedAfterPhase.quantity} {formattedAfterPhase.unit}
								</div>
							</div>
						</TabsTrigger>
					</TabsList>
					<TabsContent value="before">
						<Card className="flex min-h-24">
							<CardContent className="grid w-full grid-cols-2 items-center gap-2 p-4">
								<Label
									htmlFor="phases.before.quantity"
									className="col-span-full"
								>
									Setup Time
								</Label>
								<Controller
									name="phases.before.quantity"
									control={control}
									render={({ field }) => (
										<NumericFormat
											customInput={Input}
											id="phases.before.quantity"
											className="text-right"
											error={Boolean(errors.phases?.before?.quantity)}
											defaultValue={field.value}
											placeholder="0"
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											onValueChange={values => field.onChange(values.value)}
										/>
									)}
								/>
								<Controller
									name="phases.before.unit"
									control={control}
									render={({ field }) => (
										<Select
											defaultValue={field.value}
											onValueChange={field.onChange}
										>
											<SelectTrigger
												onBlur={field.onBlur}
												error={Boolean(errors.phases?.before?.unit)}
											>
												<SelectValue placeholder="Select time unit" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="seconds">seconds</SelectItem>
												<SelectItem value="minutes">minutes</SelectItem>
												<SelectItem value="hours">hours</SelectItem>
											</SelectContent>
										</Select>
									)}
								/>
								{errors.phases?.before?.quantity && (
									<span className="col-span-full -mt-1 text-xs text-destructive">
										{errors.phases.before.quantity.message}
									</span>
								)}
								{errors.phases?.before?.unit && (
									<span className="col-span-full -mt-1 text-xs text-destructive">
										{errors.phases.before.unit.message}
									</span>
								)}
							</CardContent>
						</Card>
					</TabsContent>
					<TabsContent value="during">
						<Card className="flex min-h-24">
							<CardContent className="grid w-full grid-cols-[1fr_64px_112px_32px] items-center gap-2 p-4 sm:grid-cols-[1fr_64px_112px_48px]">
								<Label className="col-span-full">Machines</Label>
								{fieldArrays.machineDurations.fields.map(
									(machineDuration, index) => (
										<Fragment key={machineDuration.key}>
											<Combobox
												initialValue={machineDuration.machine.id}
												options={machines
													.filter(
														m =>
															!watchedMachineIds.has(m.id) ||
															m.id === machineDuration.machine.id,
													)
													.map(m => ({
														value: m.id,
														label: m.name,
													}))}
												searchPlaceholder="Search machine..."
												valuePlaceholder="Select a machine..."
												classNameButton="w-auto"
												classNameContent="w-[320px]"
												onSelect={value => {
													fieldArrays.machineDurations.update(index, {
														...machineDuration,
														machine: { id: value },
													})
												}}
												error={Boolean(errors.phases?.during?.[index]?.machine)}
											/>
											<NumericFormat
												id={`phases.during.${index}.duration.quantity`}
												customInput={Input}
												className="text-right"
												error={Boolean(
													errors.phases?.during?.[index]?.duration?.quantity,
												)}
												defaultValue={machineDuration.duration.quantity}
												placeholder="0"
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												onValueChange={values => {
													setValue(
														`phases.during.${index}.duration.quantity`,
														values.value,
													)
												}}
											/>
											<Select
												defaultValue={machineDuration.duration.unit}
												onValueChange={value => {
													setValue(
														`phases.during.${index}.duration.unit`,
														value as TProductionTimeUnit,
													)
												}}
											>
												<SelectTrigger
													error={Boolean(
														errors.phases?.during?.[index]?.duration?.unit,
													)}
												>
													<SelectValue placeholder="Select time unit" />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="minutes">minutes</SelectItem>
													<SelectItem value="pieces_per_second">
														pieces/sec
													</SelectItem>
													<SelectItem value="pieces_per_minute">
														pieces/min
													</SelectItem>
													<SelectItem value="seconds_per_piece">
														sec/piece
													</SelectItem>
													<SelectItem value="minutes_per_piece">
														min/piece
													</SelectItem>
												</SelectContent>
											</Select>
											<Button
												className="p-2"
												variant="outline"
												type="button"
												disabled={
													fieldArrays.machineDurations.fields.length === 1
												}
												onClick={() =>
													fieldArrays.machineDurations.remove(index)
												}
											>
												<Trash2 className="h-4 min-h-4 w-4 min-w-4" />
											</Button>
											{errors.phases?.during?.[index]?.duration?.quantity
												?.message && (
												<span className="col-span-full -mt-1 text-xs text-destructive">
													{
														errors.phases.during[index]?.duration?.quantity
															?.message
													}
												</span>
											)}
											{errors.phases?.during?.[index]?.duration?.unit
												?.message && (
												<span className="col-span-full -mt-1 text-xs text-destructive">
													{errors.phases.during[index]?.duration?.unit?.message}
												</span>
											)}
											{errors.phases?.during?.[index]?.machine?.message && (
												<span className="col-span-full -mt-1 text-xs text-destructive">
													{errors.phases.during[index]?.machine?.message}
												</span>
											)}
										</Fragment>
									),
								)}
								<Button
									variant="outline"
									type="button"
									className={cn(
										'col-span-full',
										errors.phases?.during?.message && 'border-destructive',
									)}
									onClick={() => {
										const phases = watch('phases.during')
										const lastPhaseIndex = phases.length - 1
										const isLastPhaseValid =
											lastPhaseIndex >= 0 &&
											!errors.phases?.during?.[lastPhaseIndex]

										let duration
										if (isLastPhaseValid) {
											duration = phases[lastPhaseIndex].duration
										} else {
											duration = {
												quantity: 0,
												unit: 'seconds_per_piece' as const,
											}
										}

										fieldArrays.machineDurations.append({
											machine: { id: '' },
											duration,
										})
									}}
								>
									<Plus className="mr-2 h-4 min-h-4 w-4 min-w-4" /> Add Machine
								</Button>
								{errors.phases?.during?.message && (
									<span className="col-span-full -mt-1 text-xs text-destructive">
										{errors.phases?.during?.message}
									</span>
								)}
							</CardContent>
						</Card>
					</TabsContent>
					<TabsContent value="after">
						<Card className="flex min-h-24">
							<CardContent className="grid w-full grid-cols-2 items-center gap-2 p-4">
								<Label
									htmlFor="phases.after.quantity"
									className="col-span-full"
								>
									Teardown Time
								</Label>
								<Controller
									name="phases.after.quantity"
									control={control}
									render={({ field }) => (
										<NumericFormat
											customInput={Input}
											id="phases.after.quantity"
											className="text-right"
											error={Boolean(errors.phases?.after?.quantity)}
											defaultValue={field.value}
											placeholder="0"
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											onValueChange={values => field.onChange(values.value)}
										/>
									)}
								/>
								<Controller
									name="phases.after.unit"
									control={control}
									render={({ field }) => (
										<Select
											defaultValue={field.value}
											onValueChange={field.onChange}
										>
											<SelectTrigger
												onBlur={field.onBlur}
												error={Boolean(errors.phases?.after?.unit)}
											>
												<SelectValue placeholder="Select time unit" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="seconds">seconds</SelectItem>
												<SelectItem value="minutes">minutes</SelectItem>
												<SelectItem value="hours">hours</SelectItem>
											</SelectContent>
										</Select>
									)}
								/>
								{errors.phases?.after?.quantity && (
									<span className="col-span-full -mt-1 text-xs text-destructive">
										{errors.phases.after.quantity.message}
									</span>
								)}
								{errors.phases?.after?.unit && (
									<span className="col-span-full -mt-1 text-xs text-destructive">
										{errors.phases.after.unit.message}
									</span>
								)}
							</CardContent>
						</Card>
					</TabsContent>
				</Tabs>
				<div className="grid gap-x-4 gap-y-2">
					<Label htmlFor="tools">Tools</Label>
					{fieldArrays.tools.fields.length > 0 &&
						fieldArrays.tools.fields.map(({ id }, index) => (
							<Controller
								key={id}
								name={`tools.${index}`}
								control={control}
								render={({ field }) => (
									<div className="grid grid-cols-[1fr_144px_32px] items-center gap-2 sm:grid-cols-[1fr_160px_48px]">
										<Combobox
											initialValue={field.value.name}
											allowCreation={true}
											options={tools
												.filter(
													t =>
														!watchedToolNames.has(t.name) ||
														t.name === field.value.name,
												)
												.map(t => ({
													value: t.name,
													label: t.name,
												}))}
											searchPlaceholder="Type tool name or select from list..."
											valuePlaceholder="Add a tool..."
											classNameButton={cn(
												'w-auto',
												field.value.name.length === 0 && 'col-span-2',
											)}
											onSelect={value => {
												field.onChange({ ...field.value, name: value })
											}}
										/>
										<PhaseButtons
											name={field.value.name}
											factor={field.value.factor}
											phases={field.value.phases}
											onChange={field.onChange}
											error={Boolean(errors.tools?.[index]?.phases)}
										/>
										<Button
											className="p-2"
											type="button"
											variant="outline"
											onClick={() => fieldArrays.tools.remove(index)}
										>
											<Trash2 className="h-4 min-h-4 w-4 min-w-4" />
										</Button>
										{errors.tools?.[index]?.factor && (
											<span className="col-span-full -mt-1 text-right text-xs text-destructive">
												{errors.tools?.[index]?.factor?.message}
											</span>
										)}
										{errors.tools?.[index]?.phases && (
											<span className="col-span-full -mt-1 text-right text-xs text-destructive">
												{errors.tools?.[index]?.phases?.message}
											</span>
										)}
									</div>
								)}
							/>
						))}
					{(watchedTools.length === 0 ||
						(watchedTools.length > 0 &&
							watchedTools[watchedTools.length - 1].name.length > 0)) && (
						<Button
							className="col-span-full"
							variant="outline"
							type="button"
							onClick={() =>
								fieldArrays.tools.append({
									name: '',
									factor: 1,
									phases: {
										before: false,
										during: true,
										after: false,
									},
								})
							}
						>
							<Plus className="mr-2 h-4 min-h-4 w-4 min-w-4" />
							Add Tool
						</Button>
					)}
				</div>
				<div className="grid gap-x-4 gap-y-2">
					<Label htmlFor="staffGroups">Staff Groups</Label>
					{fieldArrays.staffGroups.fields.length > 0 &&
						fieldArrays.staffGroups.fields.map(({ id }, index) => (
							<Controller
								key={id}
								name={`staffGroups.${index}`}
								control={control}
								render={({ field }) => (
									<div className="grid grid-cols-[1fr_144px_32px] items-center gap-2 sm:grid-cols-[1fr_160px_48px]">
										<div
											className={cn(
												'grid grid-cols-[1fr_12px_54px] items-center gap-2',
												field.value.name.length === 0 && 'col-span-2',
											)}
										>
											<Combobox
												initialValue={field.value.name}
												allowCreation={true}
												options={staffGroups
													.filter(
														t =>
															!watchedStaffGroupNames.has(t.name) ||
															t.name === field.value.name,
													)
													.map(t => ({
														value: t.name,
														label: t.name,
													}))}
												searchPlaceholder="Type staff group name or select from list..."
												valuePlaceholder="Add a staff group..."
												classNameButton={cn(
													'w-auto',
													field.value.name.length === 0 && 'col-span-3',
												)}
												classNameContent="w-[385px]"
												onSelect={value => {
													field.onChange({ ...field.value, name: value })
												}}
											/>
											{field.value.name.length > 0 && (
												<>
													<X className="h-3 min-h-3 w-3 min-w-3" />
													<NumericFormat
														customInput={Input}
														id={`staffGroups.${index}.factor`}
														className="text-center"
														placeholder="1"
														defaultValue={field.value.factor}
														onValueChange={values => {
															field.onChange({
																...field.value,
																factor: values.value,
															})
														}}
														error={Boolean(errors.staffGroups?.[index]?.factor)}
														thousandSeparator={thousandSeparator}
														decimalSeparator={decimalSeparator}
													/>
												</>
											)}
										</div>
										<PhaseButtons
											name={field.value.name}
											factor={field.value.factor}
											phases={field.value.phases}
											onChange={field.onChange}
											error={Boolean(errors.staffGroups?.[index]?.phases)}
										/>
										<Button
											type="button"
											variant="outline"
											onClick={() => fieldArrays.staffGroups.remove(index)}
										>
											<Trash2 className="h-4 min-h-4 w-4 min-w-4" />
										</Button>
										{errors.staffGroups?.[index]?.factor && (
											<span className="col-span-full -mt-1 text-right text-xs text-destructive">
												{errors.staffGroups?.[index]?.factor?.message}
											</span>
										)}
										{errors.staffGroups?.[index]?.phases && (
											<span className="col-span-full -mt-1 text-right text-xs text-destructive">
												{errors.staffGroups?.[index]?.phases?.message}
											</span>
										)}
									</div>
								)}
							/>
						))}
					{(watchedStaffGroups.length === 0 ||
						(watchedStaffGroups.length > 0 &&
							watchedStaffGroups[watchedStaffGroups.length - 1].name.length >
								0)) && (
						<Button
							variant="outline"
							type="button"
							onClick={() =>
								fieldArrays.staffGroups.append({
									name: '',
									factor: 1,
									phases: {
										before: false,
										during: true,
										after: false,
									},
								})
							}
						>
							<Plus className="mr-2 h-4 min-h-4 w-4 min-w-4" />
							Add Staff Group
						</Button>
					)}
				</div>
			</div>
			<DialogFooter>
				{typeof submitButtons === 'function'
					? submitButtons({ handleSubmit })
					: submitButtons}
			</DialogFooter>
		</form>
	)
}

export { ProductOperationForm }
