import { z } from 'zod'

const envSchema = z.object({
	VITE_SUPABASE_URL: z.string().url(),
	VITE_SUPABASE_ANON_KEY: z.string().min(1),
	VITE_CSV_API_URL: z.string().url(),
	VITE_API_URL: z.string().url(),
})

export type Environment = z.infer<typeof envSchema>

export const env = envSchema.parse(import.meta.env)
