import CryptoJS from 'crypto-js'

import { TPlanningAlert } from '@/features/validation/types'

export function createAlertHash(alert: Omit<TPlanningAlert, 'hash'>): string {
	const { category, orders, bookings } = alert

	const ordersString = JSON.stringify(orders)
	const bookingsString = JSON.stringify(bookings)

	const dataToHash = `${category}${ordersString}${bookingsString}`

	const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex)

	return hash
}
