function getNextUniqueName(
	name: string,
	existingNames: Set<string>,
	includeSpaceBeforeNumber: boolean = true,
): string {
	if (!existingNames.has(name)) {
		return name
	}

	const regex = includeSpaceBeforeNumber ? /(\s\d+)$/ : /(\d+)$/
	const match = name.match(regex)
	const baseName = name.replace(regex, '')
	const matchedNumber = match?.[0].trim() ?? '1'
	let counter = parseInt(matchedNumber, 10) + 1
	let newName = `${baseName}${includeSpaceBeforeNumber ? ' ' : ''}${counter.toString().padStart(matchedNumber.length, '0')}`
	const maxAttempts = 100_000

	while (existingNames.has(newName)) {
		counter++
		newName = `${baseName}${includeSpaceBeforeNumber ? ' ' : ''}${counter}`

		if (counter > maxAttempts) {
			return name.split('').reverse().join('')
		}
	}

	return newName
}

export { getNextUniqueName }
