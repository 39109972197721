import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
	TMachine,
	TMachineBooking,
	TPendingOrder,
	TPlannedOrder,
	TSchedulingDirection,
} from '@repo/types'
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router'
import {
	addHours,
	addWeeks,
	format,
	startOfDay,
	startOfWeek,
	subWeeks,
} from 'date-fns'
import {
	ArrowLeftFromLine,
	ArrowRightFromLine,
	CalendarIcon,
	Eraser,
	Maximize,
	Minimize,
	MoreVertical,
	RotateCcw,
	Undo,
} from 'lucide-react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { GenericDialog } from '@/components/generic-dialog'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { CommandShortcut } from '@/components/ui/command'
import { DialogFooter } from '@/components/ui/dialog'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Label } from '@/components/ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { Gantt } from '@/features/gantt/components/gantt'
import { usePersistentOrderedList } from '@/features/gantt/components/sortable-machine-list'
import { useContextualZoom } from '@/features/gantt/hooks/use-contextual-zoom'
import { useDeselect } from '@/features/gantt/hooks/use-deselect'
import { selectMachines } from '@/features/machines/machines-slice'
import { selectCategorizedOrders } from '@/features/orders/orders-slice'
import { AlignBookingsDialog } from '@/features/planning/components/align-bookings-dialog'
import { ChangeStartDateDialog } from '@/features/planning/components/change-start-date-dialog'
import { useFullScreen } from '@/features/planning/context/full-screen-provider'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'
import {
	activateAlert,
	ignoreAlert,
	resetProgress,
	selectAllBookings,
	selectCalendarAdjustments,
	selectEnhancedAlerts,
	selectProgressedBookings,
	selectSchedulingDirection,
	selectZoomLevel,
	setSchedulingDirection,
	setZoomLevel,
	validatePlan,
	ZoomLevel,
} from '@/features/planning/planning-slice'
import { selectProducts } from '@/features/products/products-slice'
import { UpdateProgressDialog } from '@/features/progress/components/update-progress-dialog'
import { TPlanningAlert } from '@/features/validation/types'
import { useDialogState } from '@/hooks/use-dialog-state'
import { useHotkeyLabel } from '@/hooks/use-hotkey-label'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'

type PlanningSearch = {
	bookingId?: string
	orderId?: string
	alertId?: string
}

const Route = createFileRoute('/planning')({
	component: PlanningComponent,
	validateSearch: (search: Record<string, unknown>): PlanningSearch => {
		return {
			bookingId: search.bookingId as PlanningSearch['bookingId'],
			orderId: search.orderId as PlanningSearch['orderId'],
			alertId: search.alertId as PlanningSearch['alertId'],
		}
	},
	onEnter: () => {
		store.dispatch(validatePlan())
	},
})

type ZoomLevelSettings = {
	zoomLevel: string
	startDate: Date
	endDate: Date
	hourGap: number
	rowHeight: number
	rowLabelWidth: number
	rowLabelMarginLeft: number
	focalPointPercentage: number
	xGridLinesStep: number
	xLabelsStep: number
}

function applyZoomLevel(zoomLevel: ZoomLevel): ZoomLevelSettings {
	const windowWidth = window.innerWidth
	const rowLabelWidth = Math.min(250, windowWidth * 0.4)
	const startOfCurrentWeek = startOfWeek(startOfDay(new Date()), {
		weekStartsOn: 1,
	})
	const widthFactor = 1 - rowLabelWidth / windowWidth - 0.02
	const hoursInDay = 24

	// TODO: Add infinite scroll, so that the past and future weeks are not fixed and only a limited number of weeks are rendered at a time
	// TODO: Use URL params to store the zoom level and the current center view date from where to render the Gantt chart. Based on the zoom level, the number of past and future weeks to render should be calculated.
	const settingsMap = {
		'12h': {
			pastWeeks: 2,
			futureWeeks: 10,
			daysInView: 0.5,
			xGridStep: 1,
			xLabelsStep: 1,
		},
		'24h': {
			pastWeeks: 3,
			futureWeeks: 10,
			daysInView: 1,
			xGridStep: 1,
			xLabelsStep: 1,
		},
		'3d': {
			pastWeeks: 4,
			futureWeeks: 14,
			daysInView: 3,
			xGridStep: 3,
			xLabelsStep: 3,
		},
		'5d': {
			pastWeeks: 7,
			futureWeeks: 14,
			daysInView: 5,
			xGridStep: 6,
			xLabelsStep: 6,
		},
		'14d': {
			pastWeeks: 14,
			futureWeeks: 53,
			daysInView: 15,
			xGridStep: hoursInDay,
			xLabelsStep: hoursInDay * 2,
		},
		'30d': {
			pastWeeks: 14,
			futureWeeks: 53,
			daysInView: 31,
			xGridStep: hoursInDay,
			xLabelsStep: hoursInDay * 7,
		},
		'90d': {
			pastWeeks: 14,
			futureWeeks: 53,
			daysInView: 92,
			xGridStep: hoursInDay * 7,
			xLabelsStep: hoursInDay * 7,
		},
	} as const

	const setting = settingsMap[zoomLevel]

	return {
		zoomLevel,
		startDate: subWeeks(startOfCurrentWeek, setting.pastWeeks),
		endDate: addHours(addWeeks(startOfCurrentWeek, setting.futureWeeks), 8),
		hourGap: (windowWidth / (hoursInDay * setting.daysInView)) * widthFactor,
		rowHeight: 40,
		rowLabelWidth,
		rowLabelMarginLeft: 8,
		focalPointPercentage: 0.1,
		xGridLinesStep: setting.xGridStep,
		xLabelsStep: setting.xLabelsStep,
	}
}

const zoomLevels: ZoomLevel[] = ['12h', '24h', '3d', '5d', '14d', '30d', '90d']

function PlanningComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const [activeBookingId, setActiveBookingId] = useState<string | undefined>(
		search.bookingId,
	)
	const [activeOrderId, setActiveOrderId] = useState<string | undefined>(
		search.orderId,
	)
	const dispatch = useAppDispatch()
	const machines = useAppSelector(selectMachines)
	const { orderedList: orderedMachines, reorder: reorderMachines } =
		usePersistentOrderedList(machines, 'orderedMachines')
	const products = useAppSelector(selectProducts)
	const bookings = useAppSelector(selectAllBookings)
	const alerts = useAppSelector(selectEnhancedAlerts)
	const schedulingDirection = useAppSelector(selectSchedulingDirection)
	const zoomLevel = useAppSelector(selectZoomLevel)
	const zoomLevelSettings = useMemo(
		() => applyZoomLevel(zoomLevel),
		[zoomLevel],
	)

	const calendarAdjustments = useAppSelector(selectCalendarAdjustments)
	const progressedBookings = useAppSelector(selectProgressedBookings)
	const { pendingOrders, plannedOrders, processedOrders } = useAppSelector(
		selectCategorizedOrders,
	)
	const {
		isFullScreen,
		fullScreenRef,
		fullScreenContainer,
		isFullScreenSupported,
		toggleFullScreen,
	} = useFullScreen()

	const unscheduleLastOrderDialog = useDialogState()
	const clearAllScheduledOrdersDialog = useDialogState()
	const resetAllProgressDialog = useDialogState()

	// Hotkeys
	const planOrderHotkey = 'mod+shift+1'
	useHotkeys(planOrderHotkey, () => {
		if (pendingOrders.length > 0) {
			handlePlanOrder({ order: pendingOrders[0] })
		}
	})
	const planOrderHotkeyLabel = useHotkeyLabel(planOrderHotkey)

	const selectBooking = useCallback(
		(args: { orderId: string; bookingId: string }) => {
			const { orderId, bookingId } = args
			setActiveOrderId(orderId)
			setActiveBookingId(bookingId)
		},
		[setActiveOrderId, setActiveBookingId],
	)

	const deselectAll = useCallback(() => {
		setActiveOrderId(undefined)
		setActiveBookingId(undefined)
		if (search.alertId || search.bookingId || search.orderId) {
			navigate({
				search: old => ({
					...old,
					bookingId: undefined,
					orderId: undefined,
					alertId: undefined,
				}),
			})
		}
	}, [navigate, search.alertId, search.orderId, search.bookingId])

	const scrollViewRef = useRef<HTMLDivElement>(null)
	useDeselect({
		deselectRef: scrollViewRef,
		onDeselect: deselectAll,
	})

	const { focusViewOnDate, focusViewOnMachine, createZoomFn } =
		useContextualZoom({
			scrollViewRef: scrollViewRef,
			screen: isFullScreen ? fullScreenContainer : window,
			startDate: zoomLevelSettings.startDate,
			hourGap: zoomLevelSettings.hourGap,
			rowHeight: zoomLevelSettings.rowHeight,
			machines: orderedMachines,
			zoomLevel,
			offsetY: isFullScreen ? 68 : 125,
			stickySectionHeight: 60,
			focalPointPercentage: 0.1,
			reducedViewLeft:
				zoomLevelSettings.rowLabelWidth + zoomLevelSettings.rowLabelMarginLeft,
		})

	const [activeBooking, setActiveBooking] = useState<TMachineBooking | null>(
		null,
	)
	useEffect(() => {
		if (activeBookingId) {
			const booking = bookings.find(booking => booking.id === activeBookingId)
			setActiveBooking(booking ?? null)
		} else {
			setActiveBooking(null)
		}
	}, [activeBookingId, bookings])

	const [isResettingOrderPlan, setIsResettingOrderPlan] = useState(false)
	const { planOrder, resetOrderPlan, moveBooking, switchMachine } =
		useOrderPlanner()

	useEffect(() => {
		if (!search.bookingId) return
		const booking = bookings.find(booking => booking.id === search.bookingId)
		if (booking) {
			focusViewOnDate(new Date(booking.startDate), 0.4)
			focusViewOnMachine(booking.machineId)
		}
	}, [
		search.bookingId,
		bookings,
		processedOrders,
		focusViewOnDate,
		focusViewOnMachine,
	])

	useEffect(() => {
		if (!search.alertId) return
		const alert = alerts.find(alert => alert.id === search.alertId)
		if (!alert) return
		if (alert.bookings[0]) {
			selectBooking({
				orderId: alert.bookings[0].orderId,
				bookingId: alert.bookings[0].id,
			})
			focusViewOnMachine(alert.bookings[0].machineId)
		}
		focusViewOnDate(new Date(alert.startDate), 0.4)
	}, [
		search.alertId,
		alerts,
		selectBooking,
		focusViewOnDate,
		focusViewOnMachine,
	])

	const selectNextBooking = useCallback(
		(bookingId: string) => {
			const booking = bookings.find(booking => booking.id === bookingId)
			if (!booking) return
			const order = processedOrders.find(order => order.id === booking?.orderId)
			if (!order) return
			const bookingIds = order.bookingIds
			const currentIndex = bookingIds.findIndex(
				bookingId => booking.id === bookingId,
			)
			if (currentIndex === -1) return
			const nextIndex = currentIndex + 1
			if (nextIndex >= bookingIds.length) return
			const nextBookingId = bookingIds[nextIndex]
			const nextBooking = bookings.find(booking => booking.id === nextBookingId)
			if (!nextBooking) return
			selectBooking({
				orderId: order.id,
				bookingId: nextBookingId,
			})
			focusViewOnDate(new Date(nextBooking.startDate), 0.4)
			focusViewOnMachine(nextBooking.machineId)
		},
		[
			bookings,
			processedOrders,
			selectBooking,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	const selectPrevBooking = useCallback(
		(bookingId: string) => {
			const booking = bookings.find(booking => booking.id === bookingId)
			if (!booking) return
			const order = processedOrders.find(order => order.id === booking.orderId)
			if (!order) return
			const bookingIds = order.bookingIds
			const currentIndex = bookingIds.findIndex(
				bookingId => booking.id === bookingId,
			)
			if (currentIndex === -1) return
			const prevIndex = currentIndex - 1
			if (prevIndex < 0) return
			const prevBookingId = bookingIds[prevIndex]
			const prevBooking = bookings.find(booking => booking.id === prevBookingId)
			if (!prevBooking) return
			selectBooking({
				orderId: order.id,
				bookingId: prevBookingId,
			})
			focusViewOnDate(new Date(prevBooking.startDate), 0.4)
			focusViewOnMachine(prevBooking.machineId)
		},
		[
			bookings,
			processedOrders,
			selectBooking,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	const [changeBookingStartDateDialogOpen, setChangeBookingStartDateOpen] =
		useState(false)

	function openChangeBookingStartDateDialog() {
		if (activeBooking) {
			setChangeBookingStartDateOpen(true)
		} else {
			toast.error('No valid booking selected')
		}
	}

	function closeChangeBookingStartDateDialog() {
		setChangeBookingStartDateOpen(false)
	}

	const handlePlanOrder = useCallback(
		(args: { order: TPendingOrder; direction?: TSchedulingDirection }) => {
			const { order, direction = schedulingDirection } = args
			const newBookings = planOrder({ order, direction })

			if (newBookings) {
				const firstBooking = newBookings[0]
				selectBooking({
					orderId: order.id,
					bookingId: firstBooking.id,
				})
				focusViewOnDate(new Date(firstBooking.startDate), 0.4)
				focusViewOnMachine(firstBooking.machineId)
			}
		},
		[
			planOrder,
			selectBooking,
			schedulingDirection,
			focusViewOnDate,
			focusViewOnMachine,
		],
	)

	// TODO: In the future, handle replanning orders from a specific date in the planning slice, so resetting and planning can be done in one step
	const handleReplanOrderFromDate = useCallback(
		(desiredStartDate: Date) => {
			if (!activeBooking) {
				toast.error('No valid booking selected')
				return
			}
			resetOrderPlan(activeBooking.orderId)
			const order = processedOrders.find(
				order => order.id === activeBooking.orderId,
			)
			if (!order) {
				toast.error('No valid order found')
				return
			}
			handlePlanOrder({
				order: {
					...order,
					earliestStartDate: desiredStartDate.toISOString(),
					status: 'pending',
				},
				direction: 'forward',
			})
		},
		[activeBooking, handlePlanOrder, processedOrders, resetOrderPlan],
	)

	function handleUnscheduleOrder(order: TPlannedOrder) {
		setIsResettingOrderPlan(true)
		const firstBooking = bookings.filter(b => b.orderId === order.id)[0]

		if (firstBooking) {
			selectBooking({
				orderId: firstBooking.orderId,
				bookingId: firstBooking.id,
			})
			focusViewOnDate(new Date(firstBooking.startDate), 0.4)
			focusViewOnMachine(firstBooking.machineId)
		}

		setTimeout(() => {
			resetOrderPlan(order.id)
			deselectAll()
			setIsResettingOrderPlan(false)
		}, 900)
	}
	function handleUnscheduleOrders(orderIds: string[]) {
		orderIds.forEach(orderId => resetOrderPlan(orderId))
	}

	const [alignBookingsDialogOpen, setAlignBookingsDialogOpen] = useState(false)
	function openAlignBookingsDialog() {
		setAlignBookingsDialogOpen(true)
	}
	function closeAlignBookingsDialog() {
		setAlignBookingsDialogOpen(false)
	}

	const [updateProgressDialogOpen, setUpdateProgressDialogOpen] =
		useState(false)
	function openUpdateProgressDialog() {
		setUpdateProgressDialogOpen(true)
	}
	function closeUpdateProgressDialog() {
		setUpdateProgressDialogOpen(false)
	}
	async function handleResetProgress(targetBookingId: string) {
		return dispatch(resetProgress(targetBookingId)).unwrap()
	}
	async function handleResetAllProgress() {
		for (const booking of progressedBookings) {
			await handleResetProgress(booking.id)
		}
	}

	const [switchBookingMachine, setSwitchBookingMachine] = useState<{
		initialMachineId: string
		compatibleMachines: TMachine[]
		selectedMachineId: string
	} | null>(null)

	function openSwitchBookingMachineDialog() {
		if (!activeBooking) {
			toast.error('No valid booking selected')
			return
		}
		const compatibleMachines = machines.filter(m =>
			activeBooking.compatibleMachines.includes(m.id),
		)
		setSwitchBookingMachine({
			compatibleMachines,
			initialMachineId: activeBooking.machineId,
			selectedMachineId: activeBooking.machineId,
		})
	}
	function closeSwitchBookingMachineDialog() {
		setSwitchBookingMachine(null)
	}
	function handleSwitchMachine(toMachineId: string) {
		if (!activeBookingId) {
			toast.error('No valid booking selected')
			closeSwitchBookingMachineDialog()
			return
		}
		const updatedBooking = switchMachine({
			bookingId: activeBookingId,
			toMachineId,
		})
		if (updatedBooking) {
			selectBooking({
				orderId: updatedBooking.orderId,
				bookingId: updatedBooking.id,
			})
			focusViewOnDate(new Date(updatedBooking.startDate), 0.4)
			focusViewOnMachine(updatedBooking.machineId)
		}
		closeSwitchBookingMachineDialog()
	}

	const onZoomIn =
		zoomLevel === '12h'
			? undefined
			: () => {
					const currentIndex = zoomLevels.indexOf(zoomLevel)
					if (currentIndex > 0) {
						dispatch(setZoomLevel(zoomLevels[currentIndex - 1]))
					}
				}

	const onZoomOut =
		zoomLevel === '90d'
			? undefined
			: () => {
					const currentIndex = zoomLevels.indexOf(zoomLevel)
					if (currentIndex < zoomLevels.length - 1) {
						dispatch(setZoomLevel(zoomLevels[currentIndex + 1]))
					}
				}

	function onIgnoreAlert(alert: TPlanningAlert) {
		dispatch(
			ignoreAlert({
				date: new Date().toISOString(),
				hash: alert.hash,
			}),
		)
	}

	function onActivateAlert(alert: TPlanningAlert) {
		dispatch(activateAlert(alert.hash))
	}

	return (
		<>
			<div
				ref={fullScreenRef}
				className={cn(
					'flex h-full w-full flex-col',
					isFullScreen && 'overflow-y-scroll bg-background',
				)}
			>
				<PageLayout className="border-b">
					<ScrollableToolbar>
						<ScrollableToolbarContentLeft>
							<div className="flex items-center gap-2">
								<h1 className="text-xl sm:text-2xl">Planning</h1>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button variant="ghost" className="h-8 w-8 p-2">
											<MoreVertical className="h-4 w-4 shrink-0" />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent
										align="start"
										container={fullScreenContainer}
									>
										<DropdownMenuItem
											data-disabled={
												plannedOrders.length === 0 || isResettingOrderPlan
											}
											onClick={unscheduleLastOrderDialog.open}
										>
											<Undo className="mr-2 h-4 w-4 shrink-0" />
											<span>Unschedule Last Order</span>
										</DropdownMenuItem>
										<DropdownMenuSeparator />
										<DropdownMenuGroup>
											<DropdownMenuItem
												data-disabled={
													plannedOrders.length === 0 || isResettingOrderPlan
												}
												onClick={clearAllScheduledOrdersDialog.open}
												className="justify-between"
											>
												<div className="flex items-center">
													<Eraser className="mr-2 h-4 w-4 shrink-0" />
													<span>Clear All Scheduled</span>
												</div>
												<Badge
													variant="outline"
													className="ml-2 justify-center font-mono"
												>
													{plannedOrders.length}
												</Badge>
											</DropdownMenuItem>
											<DropdownMenuItem
												data-disabled={progressedBookings.length === 0}
												onClick={resetAllProgressDialog.open}
												className="justify-between"
											>
												<div className="flex items-center">
													<RotateCcw className="mr-2 h-4 w-4 shrink-0" />
													<span>Reset All Progress</span>
												</div>
												<Badge
													variant="outline"
													className="ml-2 justify-center font-mono"
												>
													{progressedBookings.length}
												</Badge>
											</DropdownMenuItem>
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
						</ScrollableToolbarContentLeft>
						<ScrollableToolbarContentRight>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										disabled={pendingOrders.length === 0}
										size="sm"
										onClick={() => handlePlanOrder({ order: pendingOrders[0] })}
									>
										Plan Next Order
										{planOrderHotkeyLabel && (
											<CommandShortcut className="ml-2 text-background/80">
												{planOrderHotkeyLabel}
											</CommandShortcut>
										)}
										<Badge
											variant="secondary"
											className="ml-2 justify-center font-mono"
										>
											{pendingOrders.length}
										</Badge>
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									{pendingOrders.length === 0 ? (
										<span className="text-xs">No pending orders</span>
									) : (
										<div className="flex flex-col gap-1">
											<Label className="my-2">Next Order</Label>
											<span className="text-xs">
												<strong>Production Order #:</strong>{' '}
												{pendingOrders[0].productionOrderNumber}
											</span>
											<span className="text-xs">
												<strong>Sales Order #:</strong>{' '}
												{pendingOrders[0].salesOrderNumber}
											</span>
											<span className="text-xs">
												<strong>Customer:</strong>{' '}
												{pendingOrders[0].customerName}
											</span>
											<span className="text-xs">
												<strong>Product:</strong>{' '}
												{
													products.find(
														product =>
															product.id === pendingOrders[0].product.id,
													)?.productNumber
												}{' '}
												&bull;{' '}
												{
													products.find(
														product =>
															product.id === pendingOrders[0].product.id,
													)?.name
												}
											</span>
											<span className="text-xs">
												<strong>Quantity:</strong>{' '}
												{formatNumericString(pendingOrders[0].quantity)}
											</span>
											<span className="text-xs">
												<strong>Earliest Start Date:</strong>{' '}
												{format(
													pendingOrders[0].earliestStartDate,
													'EEE MMM dd, yyyy',
												)}
											</span>
											<span className="text-xs">
												<strong>Due Date:</strong>{' '}
												{format(pendingOrders[0].dueDate, 'EEE MMM dd, yyyy')}
											</span>
										</div>
									)}
								</TooltipContent>
							</Tooltip>
							<Select
								value={schedulingDirection}
								onValueChange={(value: TSchedulingDirection) =>
									dispatch(setSchedulingDirection(value))
								}
							>
								<SelectTrigger className="w-56">
									<SelectValue placeholder="Choose Scheduling Direction" />
								</SelectTrigger>
								<SelectContent container={fullScreenContainer}>
									<SelectItem value="forward">
										<span className="flex items-center">
											<ArrowRightFromLine className="mr-2 h-4 w-4 shrink-0" />{' '}
											Scheduling Forwards
										</span>
									</SelectItem>
									<SelectItem value="backward">
										<span className="flex items-center">
											<ArrowLeftFromLine className="mr-2 h-4 w-4 shrink-0" />{' '}
											Scheduling Backwards
										</span>
									</SelectItem>
								</SelectContent>
							</Select>
							<Button
								title="Calendar Adjustments"
								variant="outline"
								size="sm"
								onClick={() =>
									navigate({ to: '/planning/calendar-adjustments' })
								}
							>
								<CalendarIcon className="h-4 w-4" />
							</Button>
							{isFullScreenSupported && (
								<Button
									title={
										isFullScreen
											? 'Exit Full Screen Mode'
											: 'Enter Full Screen Mode'
									}
									variant="outline"
									size="sm"
									onClick={toggleFullScreen}
								>
									{isFullScreen ? (
										<Minimize className="h-4 w-4" />
									) : (
										<Maximize className="h-4 w-4" />
									)}
								</Button>
							)}
						</ScrollableToolbarContentRight>
					</ScrollableToolbar>
				</PageLayout>
				<Gantt
					scrollRef={scrollViewRef}
					machines={orderedMachines}
					products={products}
					orders={processedOrders}
					bookings={bookings}
					alerts={alerts}
					calendarAdjustments={calendarAdjustments}
					onZoomIn={createZoomFn(onZoomIn)}
					onZoomOut={createZoomFn(onZoomOut)}
					activeOrderId={activeOrderId} // TODO: Handle this state over URL params
					activeBookingId={activeBookingId} // TODO: Handle this state over URL params
					onAdjustMachineCalendar={machineId =>
						navigate({
							to: '/planning/calendar-adjustments/new',
							search: {
								machines: [machineId],
							},
						})
					}
					onUpdateProgress={openUpdateProgressDialog}
					onReorderMachines={reorderMachines}
					onSelectBooking={selectBooking}
					onSelectNextBooking={selectNextBooking}
					onSelectPrevBooking={selectPrevBooking}
					onMoveBooking={moveBooking}
					onSwitchBookingMachine={openSwitchBookingMachineDialog}
					onChangeBookingStartDate={openChangeBookingStartDateDialog}
					onAlignBookings={openAlignBookingsDialog}
					focusViewOnDate={focusViewOnDate}
					onIgnoreAlert={onIgnoreAlert}
					onActivateAlert={onActivateAlert}
					{...zoomLevelSettings}
				/>
			</div>
			{changeBookingStartDateDialogOpen && activeBooking && (
				<ChangeStartDateDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeChangeBookingStartDateDialog}
					onSubmit={date => {
						focusViewOnDate(date, 0.4)
						focusViewOnMachine(activeBooking.machineId)
					}}
					onReplan={handleReplanOrderFromDate}
				/>
			)}
			{alignBookingsDialogOpen && activeBooking && (
				<AlignBookingsDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeAlignBookingsDialog}
					onSubmit={() => {
						focusViewOnMachine(activeBooking.machineId)
					}}
				/>
			)}
			<GenericDialog
				title="Switch machine"
				container={fullScreenContainer}
				open={Boolean(switchBookingMachine)}
				onClose={closeSwitchBookingMachineDialog}
			>
				<Select
					value={switchBookingMachine?.selectedMachineId}
					onValueChange={value =>
						setSwitchBookingMachine(prev =>
							prev ? { ...prev, selectedMachineId: value } : null,
						)
					}
				>
					<SelectTrigger className="w-full">
						<div>
							<span className="mr-2 text-muted-foreground">Machine: </span>
							<SelectValue placeholder="Select Machine" />
						</div>
					</SelectTrigger>
					<SelectContent>
						{switchBookingMachine?.compatibleMachines.map(machine => (
							<SelectItem key={machine.id} value={machine.id}>
								{machine.name}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				<DialogFooter>
					<Button variant="outline" onClick={closeSwitchBookingMachineDialog}>
						Cancel
					</Button>
					<Button
						disabled={
							switchBookingMachine?.initialMachineId ===
							switchBookingMachine?.selectedMachineId
						}
						onClick={() => {
							if (switchBookingMachine) {
								handleSwitchMachine(switchBookingMachine.selectedMachineId)
							} else {
								toast.error('No valid machine selected')
							}
						}}
					>
						Switch Machine
					</Button>
				</DialogFooter>
			</GenericDialog>
			{updateProgressDialogOpen && activeBooking && (
				<UpdateProgressDialog
					booking={activeBooking}
					container={fullScreenContainer}
					onClose={closeUpdateProgressDialog}
				/>
			)}
			<GenericAlertDialog
				title="Unschedule last order"
				description="Are you sure you want to unschedule the last order? This action cannot be undone."
				container={fullScreenContainer}
				open={unscheduleLastOrderDialog.isOpen}
				confirmButtonText="Unschedule Last Order"
				onClose={unscheduleLastOrderDialog.close}
				onConfirm={() => {
					handleUnscheduleOrder(plannedOrders[plannedOrders.length - 1])
				}}
			/>
			<GenericAlertDialog
				title="Clear all scheduled orders"
				description="Are you sure you want to clear all scheduled orders? This action cannot be undone."
				container={fullScreenContainer}
				open={clearAllScheduledOrdersDialog.isOpen}
				confirmButtonText="Clear All Scheduled Orders"
				onClose={clearAllScheduledOrdersDialog.close}
				onConfirm={() => {
					handleUnscheduleOrders(plannedOrders.map(o => o.id))
				}}
			/>
			<GenericAlertDialog
				title="Reset all progress"
				description="Are you sure you want to reset all progress? This action cannot be undone."
				container={fullScreenContainer}
				open={resetAllProgressDialog.isOpen}
				confirmButtonText="Reset All Progress"
				onClose={resetAllProgressDialog.close}
				onConfirm={handleResetAllProgress}
			/>
			<Outlet />
		</>
	)
}

export { Route }
