import { createId } from '@paralleldrive/cuid2'
import {
	ProductOperationReference,
	TProduct,
	TProductOperation,
} from '@repo/types'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { useConfirmExitFormDialog } from '@/features/form-dialog/use-confirm-exit-form-dialog'
import { selectMachines } from '@/features/machines/machines-slice'
import { ProductForm } from '@/features/products/forms/product-form'
import { createProductOperation } from '@/features/products/product-operations-slice'
import {
	createProduct,
	selectProductNames,
	selectProductNumbers,
} from '@/features/products/products-slice'
import {
	createStaffGroup,
	selectStaffGroups,
} from '@/features/staff-groups/staff-groups-slice'
import { createTool, selectTools } from '@/features/tools/tools-slice'
import { useUniqueName } from '@/hooks/use-unique-name'

const Route = createFileRoute('/products/create')({
	component: CreateProductComponent,
})

function CreateProductComponent() {
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const productNames = useAppSelector(selectProductNames)
	const productNumbers = useAppSelector(selectProductNumbers)
	const machines = useAppSelector(selectMachines)
	const tools = useAppSelector(selectTools)
	const staffGroups = useAppSelector(selectStaffGroups)
	const { getUniqueName } = useUniqueName(productNames)
	const { getUniqueName: getUniqueProductNumber } = useUniqueName(
		productNumbers,
		false,
	)

	const { ConfirmExitAlertDialog, formDialogProps, formProps } =
		useConfirmExitFormDialog<
			Omit<TProduct<Omit<TProductOperation, 'id' | 'productId'>>, 'id'>
		>({
			// TODO: Try using async thunk for this
			onSubmit: data => {
				const productId = createId()
				const operationReferences: ProductOperationReference[] =
					data.operations.map(operation => {
						const operationId = createId()
						dispatch(
							createProductOperation(
								{
									...operation,
									productId,
								},
								operationId,
							),
						)

						return { id: operationId }
					})
				dispatch(
					createProduct(
						{ ...data, operations: operationReferences },
						productId,
					),
				)
				toast.success(`Product "${data.name}" created`)
			},
			onClose: () => {
				navigate({
					to: '/products',
					search: true,
				})
			},
		})

	return (
		<>
			<ConfirmExitAlertDialog />
			<GenericDialog
				title="Create product"
				className="flex h-[90dvh] w-[95dvw] max-w-screen-2xl flex-col"
				{...formDialogProps}
			>
				<ProductForm
					initialValues={{
						name: getUniqueName('Product 1'),
						productNumber: getUniqueProductNumber('0000.1'),
						operations: [],
					}}
					submitButtons={<Button type="submit">Create Product</Button>}
					machines={machines}
					tools={tools}
					staffGroups={staffGroups}
					onOperationChanged={operation => {
						operation.tools.forEach(tool => dispatch(createTool(tool)))
						operation.staffGroups.forEach(staffGroup =>
							dispatch(createStaffGroup(staffGroup)),
						)
					}}
					existingNames={productNames}
					existingProductNumbers={productNumbers}
					{...formProps}
				/>
			</GenericDialog>
		</>
	)
}

export { Route }
