import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TMachine } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

type MachinesState = {
	list: TMachine[]
}

const initialState: MachinesState = {
	list: [],
}

export const machinesSlice = createSlice({
	name: 'machines',
	initialState,
	reducers: {
		createMachine: {
			reducer: (state, action: PayloadAction<TMachine>) => {
				state.list.push(action.payload)
			},
			prepare: (machineData: Omit<TMachine, 'id'>) => {
				const id = createId()
				return { payload: { ...machineData, id } }
			},
		},
		editMachine: (state, action: PayloadAction<TMachine>) => {
			const machine = state.list.find(
				machine => machine.id === action.payload.id,
			)
			if (machine) {
				Object.assign(machine, action.payload)
			}
		},
		deleteMachine: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(machine => machine.id !== action.payload)
		},
		reorderMachine: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const [removed] = state.list.splice(oldIndex, 1)
			state.list.splice(newIndex, 0, removed)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const { createMachine, editMachine, deleteMachine, reorderMachine } =
	machinesSlice.actions

export const selectMachine = (id: string) =>
	createSelector(
		(state: RootState) => state.machines.list,
		machines => machines.find(machine => machine.id === id),
	)

export const selectMultipleMachines = (ids: string[]) =>
	createSelector(
		(state: RootState) => state.machines.list,
		machines => machines.filter(machine => ids.includes(machine.id)),
	)

export const selectMachines = (state: RootState) => state.machines.list

export const selectMachineNames = createSelector(selectMachines, machines =>
	machines.map(machine => machine.name),
)

export default machinesSlice.reducer
