import { useState } from 'react'

import { TOrder } from '@repo/types'
import { Table } from '@tanstack/react-table'

import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import { Button } from '@/components/ui/button'

interface DataTableSelectedRowsActionsProps<TData> {
	table: Table<TData>
	resetOrderPlan: (orderId: string) => void
}

export function DataTableSelectedRowActions<TData>({
	table,
	resetOrderPlan,
}: DataTableSelectedRowsActionsProps<TData>) {
	const [showUnscheduleAllModal, setShowUnscheduleAllModal] = useState(false)
	const selectedRows = table.getFilteredSelectedRowModel().rows
	const hasSelectedRows = selectedRows.length > 0
	const allSelectedArePending = selectedRows.every(
		row => (row.original as TOrder).status === 'pending',
	)

	if (!hasSelectedRows) return null

	function handleUnscheduleConfirm() {
		setShowUnscheduleAllModal(false)
		selectedRows.forEach(row => {
			const order = row.original as TOrder
			resetOrderPlan(order.id)
		})
	}

	return (
		<>
			<Button
				className="h-8"
				variant="outline"
				disabled={allSelectedArePending}
				onClick={() => setShowUnscheduleAllModal(true)}
			>
				Reset Orders
			</Button>
			{showUnscheduleAllModal && (
				<GenericAlertDialog
					title="Reset orders"
					description="Are you sure you want to unschedule all selected orders? This action cannot be undone, but you can always plan them again."
					confirmButtonText="Unschedule All"
					onClose={() => setShowUnscheduleAllModal(false)}
					onConfirm={handleUnscheduleConfirm}
				/>
			)}
		</>
	)
}
