import localForage from 'localforage'

import { PERSIST_KEY } from '../constants'

// Inspired by: https://github.com/machester4/redux-persist-indexeddb-storage

export function indexedDbStorage(name: string) {
	const { getItem, setItem, removeItem } = localForage.createInstance({
		name,
	})

	return {
		// TODO: This is a temporary solution to migrate the data from localStorage to indexedDB
		getItem: async (key: string): Promise<string | null> => {
			if (key !== PERSIST_KEY) {
				return getItem(key)
			}

			const indexedDbItem = await getItem<string>(key)
			if (indexedDbItem) {
				return indexedDbItem
			}

			const localStorageItem = localStorage?.getItem(PERSIST_KEY)
			return localStorageItem || indexedDbItem
		},
		setItem,
		removeItem,
	}
}
