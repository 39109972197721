import { createId } from '@paralleldrive/cuid2'
import {
	createEntityAdapter,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit'
import type { PayloadAction, Update } from '@reduxjs/toolkit'
import {
	TCompletedOrder,
	TInProgressOrder,
	TMachineBooking,
	TOrder,
	TPendingOrder,
	TPeriodString,
	TPlannedOrder,
	TPlanningParameters,
	TProduct,
	TProductOperation,
} from '@repo/types'
import { compareAsc, formatDistance } from 'date-fns'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'
import { getTotalOperationDurationMinutes } from '@/features/planning/schedule-order'

export function getTotalOrderDurationMinutes(args: {
	order: TOrder<unknown>
	product?: TProduct<TProductOperation>
	bookings: TMachineBooking[]
}) {
	const { order, product, bookings } = args

	if (!product) {
		return 0
	}

	if (order.status === 'planned') {
		return bookings
			.filter(booking => order.bookingIds.includes(booking.id))
			.reduce((total, booking) => total + booking.effectiveTimeMinutes.total, 0)
	} else {
		return product.operations.reduce(
			(total, operation) =>
				total +
				getTotalOperationDurationMinutes({
					setupDuration: operation.phases.before,
					teardownDuration: operation.phases.after,
					productionDuration: operation.phases.during[0].duration,
					quantity: order.quantity,
				}),
			0,
		)
	}
}

export function isActiveOrder(order: TOrder) {
	return ['pending', 'planned', 'in-progress'].includes(order.status)
}

const ordersAdapter = createEntityAdapter<TOrder>({
	sortComparer: (a, b) => {
		const aHasPlanned = 'plannedPeriod' in a
		const bHasPlanned = 'plannedPeriod' in b

		if (!aHasPlanned && !bHasPlanned) {
			return compareAsc(a.dueDate, b.dueDate)
		}

		if (!aHasPlanned) return -1
		if (!bHasPlanned) return 1

		return compareAsc(a.plannedPeriod.startDate, b.plannedPeriod.startDate)
	},
})

export const ordersSlice = createSlice({
	name: 'orders',
	initialState: ordersAdapter.getInitialState(),
	reducers: {
		createOrder: {
			reducer: ordersAdapter.addOne,
			prepare: (orderData: Omit<TPendingOrder, 'id' | 'status'>) => {
				return {
					payload: {
						...orderData,
						id: createId(),
						status: 'pending' as const,
					},
				}
			},
		},
		editOrder: (
			state,
			action: PayloadAction<Update<Omit<TOrder, 'status'>, string>>,
		) => {
			ordersAdapter.updateOne(state, action.payload)
		},
		deleteOrder: ordersAdapter.removeOne,
		advanceOrderToPlanned: (
			state,
			action: PayloadAction<{
				id: string
				bookingIds: string[]
				planningParameters: TPlanningParameters
				plannedPeriod: TPeriodString
			}>,
		) => {
			const { id, bookingIds, planningParameters, plannedPeriod } =
				action.payload
			const existingOrder = state.entities[id] as TPendingOrder | undefined

			if (existingOrder?.status === 'pending') {
				ordersAdapter.updateOne(state, {
					id,
					changes: {
						status: 'planned',
						bookingIds,
						planningParameters,
						plannedPeriod,
					} as Partial<TPlannedOrder>,
				})
			}
		},
		advanceOrderToInactive: (state, action: PayloadAction<string>) => {
			const id = action.payload
			const existingOrder = state.entities[id]

			if (existingOrder?.status === 'pending') {
				ordersAdapter.updateOne(state, {
					id,
					changes: { status: 'inactive' },
				})
			}
		},
		advanceOrderToInProgress: (state, action: PayloadAction<string>) => {
			const id = action.payload
			const existingOrder = state.entities[id]

			if (existingOrder?.status === 'planned') {
				ordersAdapter.updateOne(state, {
					id,
					changes: { status: 'in-progress' },
				})
			}
		},
		advanceOrderToCompleted: (
			state,
			action: PayloadAction<{ id: string; completionDate: string }>,
		) => {
			const { id, completionDate } = action.payload
			const existingOrder = state.entities[id]

			if (
				existingOrder?.status === 'planned' ||
				existingOrder?.status === 'in-progress'
			) {
				ordersAdapter.updateOne(state, {
					id,
					changes: {
						status: 'completed',
						completionDate,
					} as Partial<TCompletedOrder>,
				})
			}
		},
		revertOrderToPlanned: (state, action: PayloadAction<string>) => {
			const existingOrder = state.entities[action.payload]

			if (
				existingOrder?.status === 'completed' ||
				existingOrder?.status === 'in-progress'
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { completionDate, ...data } = existingOrder as TCompletedOrder
				ordersAdapter.setOne(state, {
					...data,
					status: 'planned' as const,
				})
			}
		},
		revertOrderToPending: (state, action: PayloadAction<string>) => {
			const existingOrder = state.entities[action.payload]

			if (
				existingOrder?.status === 'inactive' ||
				existingOrder?.status === 'planned' ||
				existingOrder?.status === 'in-progress'
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { bookingIds, planningParameters, plannedPeriod, ...data } =
					existingOrder as TPlannedOrder
				ordersAdapter.setOne(state, {
					...data,
					status: 'pending' as const,
				})
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			ordersAdapter.removeAll(state)
		})
	},
})

export const {
	createOrder,
	editOrder,
	deleteOrder,
	advanceOrderToPlanned,
	advanceOrderToInactive,
	revertOrderToPending,
	advanceOrderToInProgress,
	advanceOrderToCompleted,
	revertOrderToPlanned,
} = ordersSlice.actions

export const {
	selectAll: selectAllOrders,
	selectById: selectOrderById,
	selectIds: selectOrderIds,
	selectEntities: selectOrderEntities,
} = ordersAdapter.getSelectors((state: RootState) => state.orders)

export const selectEnhancedOrders = createSelector(
	selectAllOrders,
	(state: RootState) => state.products.list,
	(state: RootState) => state.productOperations.list,
	(state: RootState) => state.planning.bookings,
	(state: RootState) => state.planning.alerts,
	(state: RootState) => state.planning.ignoredAlerts,
	(orders, products, productOperations, bookings, alerts, ignoredAlerts) =>
		orders.map(order => {
			const product =
				// TODO: Provide a better fallback or handle this case
				products.find(product => product.id === order.product.id) ?? products[0]
			const enhancedProduct = {
				...product,
				operations: product?.operations
					.map(operation => productOperations.find(o => o.id === operation.id))
					.filter((o): o is TProductOperation => Boolean(o)),
			}
			const productionDurationMinutes = Math.min(
				getTotalOrderDurationMinutes({
					order,
					product: enhancedProduct,
					bookings,
				}),
				5_260_000 /* 10 years in mins */,
			)
			return {
				...order,
				product,
				productionDurationMinutes,
				formattedProductionDuration: formatDistance(
					0,
					productionDurationMinutes * 60 * 1000,
					{
						includeSeconds: true,
					},
				),
				alerts: alerts.filter(
					alert =>
						!ignoredAlerts.entities[alert.hash] &&
						alert.orders.some(o => o.id === order.id),
				).length,
			}
		}),
)
export const selectCategorizedOrders = createSelector(
	selectAllOrders,
	orders => {
		const pendingOrders: TPendingOrder[] = []
		const plannedOrders: TPlannedOrder[] = []
		const inProgressOrders: TInProgressOrder[] = []
		const completedOrders: TCompletedOrder[] = []
		const activeOrders: (TPlannedOrder | TInProgressOrder)[] = []
		const processedOrders: (
			| TPlannedOrder
			| TInProgressOrder
			| TCompletedOrder
		)[] = []

		orders.forEach(order => {
			if (order.status === 'pending') {
				pendingOrders.push(order)
			} else if (order.status === 'planned') {
				plannedOrders.push(order)
				activeOrders.push(order)
				processedOrders.push(order)
			} else if (order.status === 'in-progress') {
				inProgressOrders.push(order)
				activeOrders.push(order)
				processedOrders.push(order)
			} else if (order.status === 'completed') {
				completedOrders.push(order)
				processedOrders.push(order)
			}
		})

		return {
			pendingOrders,
			plannedOrders,
			inProgressOrders,
			completedOrders,
			activeOrders,
			processedOrders,
		}
	},
)
export const selectProductionOrderNumbers = createSelector(
	selectAllOrders,
	orders => orders.map(order => order.productionOrderNumber),
)

export default ordersSlice.reducer
