import { createId } from '@paralleldrive/cuid2'
import {
	TMachineBooking,
	TPeriod,
	TPeriodString,
	TPhasedItem,
	TPhaseSchedule,
} from '@repo/types'
import { areIntervalsOverlapping, max, min } from 'date-fns'

import { TPlanValidator } from './types'

export function getToolPeriods<
	T extends TPeriod | TPeriodString = TPeriod,
>(args: {
	toolName: string
	tools: TPhasedItem[]
	phases: TPhaseSchedule<T>
}): TPeriod[] {
	const { toolName, tools, phases } = args
	const tool = tools.find(t => t.name === toolName)
	if (!tool) return []

	const periods: TPeriod[] = []
	let currentPeriod: TPeriod | null = null

	// Process phases in order
	const phaseSequence: Array<keyof typeof phases> = [
		'before',
		'during',
		'after',
	]

	for (const phaseName of phaseSequence) {
		const phase = phases[phaseName]
		if (!tool.phases[phaseName]) continue

		const phaseStartDate = new Date(phase.startDate)
		const phaseEndDate = new Date(phase.endDate)

		// Merge with previous period if adjacent
		if (
			currentPeriod &&
			currentPeriod.endDate.getTime() === phaseStartDate.getTime()
		) {
			currentPeriod.endDate = phaseEndDate
		} else {
			if (currentPeriod) periods.push(currentPeriod)
			currentPeriod = {
				startDate: phaseStartDate,
				endDate: phaseEndDate,
			}
		}
	}

	if (currentPeriod) periods.push(currentPeriod)
	return periods
}

function createToolOverlapAlert(args: {
	bookingA: TMachineBooking
	bookingB: TMachineBooking
	toolName: string
	periodA: TPeriod
	periodB: TPeriod
}) {
	const { bookingA, bookingB, toolName, periodA, periodB } = args
	return {
		id: createId(),
		category: 'tool-overlap' as const,
		orders: [{ id: bookingA.orderId }, { id: bookingB.orderId }],
		bookings: [{ id: bookingA.id }, { id: bookingB.id }],
		startDate: max([periodA.startDate, periodB.startDate]).toISOString(),
		endDate: min([periodA.endDate, periodB.endDate]).toISOString(),
		suggestion: `Tool "${toolName}" is required by multiple operations at the same time.`,
	}
}

const validateToolOverlaps: TPlanValidator = plan => {
	const alerts: ReturnType<TPlanValidator> = []

	// Create a map of tool name to array of bookings using that tool
	const bookingsByTool = plan.bookings.reduce((map, booking) => {
		if (booking.status === 'completed') return map

		booking.tools.forEach(tool => {
			const toolBookings = map.get(tool.name) || []
			toolBookings.push(booking)
			map.set(tool.name, toolBookings)
		})

		return map
	}, new Map<string, TMachineBooking[]>())

	// Check each tool's bookings for overlaps
	bookingsByTool.forEach((bookings, toolName) => {
		// Compare each booking with every other booking
		for (let i = 0; i < bookings.length; i++) {
			const bookingA = bookings[i]

			for (let j = i + 1; j < bookings.length; j++) {
				const bookingB = bookings[j]

				// Skip if bookings are on same machine
				if (bookingA.machineId === bookingB.machineId) continue

				// Get tool usage periods for both bookings
				const periodsA = getToolPeriods({
					toolName,
					tools: bookingA.tools,
					phases: bookingA.phases,
				})
				const periodsB = getToolPeriods({
					toolName,
					tools: bookingB.tools,
					phases: bookingB.phases,
				})

				// Check for overlaps between periods
				for (const periodA of periodsA) {
					for (const periodB of periodsB) {
						if (
							areIntervalsOverlapping(
								{ start: periodA.startDate, end: periodA.endDate },
								{ start: periodB.startDate, end: periodB.endDate },
							)
						) {
							alerts.push(
								createToolOverlapAlert({
									bookingA,
									bookingB,
									toolName,
									periodA,
									periodB,
								}),
							)
						}
					}
				}
			}
		}
	})

	return alerts
}

export { validateToolOverlaps }
