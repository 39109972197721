import { TPeriod } from '@repo/types'
import { compareAsc } from 'date-fns'

function invertPeriods(args: {
	startDate: Date
	endDate: Date
	periods: TPeriod[]
}): TPeriod[] {
	const { startDate, endDate, periods } = args
	const invertedPeriods: TPeriod[] = []
	let previousEndDate = startDate

	const sortedPeriods = [...periods].sort((a, b) =>
		compareAsc(a.startDate, b.startDate),
	)

	sortedPeriods.forEach(period => {
		if (period.startDate > previousEndDate) {
			invertedPeriods.push({
				startDate: previousEndDate,
				endDate: period.startDate,
			})
		}
		if (period.endDate > previousEndDate) {
			previousEndDate = period.endDate
		}
	})

	if (previousEndDate < endDate) {
		invertedPeriods.push({ startDate: previousEndDate, endDate })
	}

	return invertedPeriods
}

export { invertPeriods }
