import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAppDispatch } from '@/app/hooks'
import { store } from '@/app/store'
import { GenericAlertDialog } from '@/components/generic-alert-dialog'
import {
	deleteProduct,
	selectProduct,
} from '@/features/products/products-slice'

const Route = createFileRoute('/products/$productId/delete')({
	component: DeleteProductComponent,
	loader: async ({ params: { productId } }) => {
		const productSelector = selectProduct(productId)
		const product = productSelector(store.getState())
		if (!product) {
			throw notFound()
		}
		return { product }
	},
})

function DeleteProductComponent() {
	const { product } = Route.useLoaderData()
	const { productId } = Route.useParams()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()

	function closeDialog() {
		navigate({
			to: '/products',
			search: true,
		})
	}

	return (
		<GenericAlertDialog
			title="Delete product"
			description={`Are you sure you want to delete "${product.name}"? This action cannot be undone.`}
			confirmButtonText="Delete"
			onClose={closeDialog}
			onConfirm={() => {
				closeDialog()
				dispatch(deleteProduct(productId))
				toast.success(`Product "${product.name}" deleted`)
			}}
		/>
	)
}

export { Route }
