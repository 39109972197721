import { lazy, Suspense } from 'react'

import { createRootRoute, Outlet, useNavigate } from '@tanstack/react-router'

import { useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { Footer } from '@/components/footer'
import { Header } from '@/components/header'
import { TailwindIndicator } from '@/components/tailwind-indicator'
import { Toaster } from '@/components/ui/sonner'
import { TooltipProvider } from '@/components/ui/tooltip'
import { EditOrderDialog } from '@/features/orders/components/edit-order-dialog'
import { selectOrderById } from '@/features/orders/orders-slice'
import { FullScreenProvider } from '@/features/planning/context/full-screen-provider'
import { selectAllBookings } from '@/features/planning/planning-slice'

const TanStackRouterDevtools =
	process.env.NODE_ENV === 'production'
		? () => null // Render nothing in production
		: lazy(() =>
				// Lazy load in development
				import('@tanstack/router-devtools').then(res => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			)

type RootSearch = {
	editOrderId?: string
}

const Route = createRootRoute({
	component: RootComponent,
	validateSearch: (search: Record<string, unknown>): RootSearch => {
		return {
			editOrderId: search.editOrderId as RootSearch['editOrderId'],
		}
	},
	loaderDeps: ({ search: { editOrderId } }) => ({ editOrderId }),
	loader: async ({ deps: { editOrderId } }) => {
		let orderToEdit
		if (editOrderId) {
			orderToEdit = selectOrderById(store.getState(), editOrderId)
		}
		return { orderToEdit }
	},
})

function RootComponent() {
	const { orderToEdit } = Route.useLoaderData()
	const navigate = useNavigate()
	const bookings = useAppSelector(selectAllBookings)

	return (
		<>
			<Toaster closeButton />
			<TooltipProvider>
				<div className="flex min-h-screen w-dvw flex-col items-center">
					<Header bookings={bookings} />
					<main className="flex w-full flex-1 flex-col bg-muted/50">
						<FullScreenProvider>
							<Outlet />
						</FullScreenProvider>
					</main>
					<Footer />
				</div>
			</TooltipProvider>
			<Suspense fallback={null}>
				<TanStackRouterDevtools position="bottom-right" />
			</Suspense>
			<TailwindIndicator />
			{orderToEdit && (
				<EditOrderDialog
					order={orderToEdit}
					onClose={() => {
						navigate({
							to: '.',
							search: old => ({
								...old,
								editOrderId: undefined,
							}),
						})
					}}
				/>
			)}
		</>
	)
}

export { Route }
