import { cn } from '@/lib/utils'

export function ScrollableToolbar(props: React.HTMLAttributes<HTMLDivElement>) {
	const { className, ...rest } = props
	return (
		<div
			className={cn(
				'-mx-4 -my-1 flex items-center justify-between gap-4 overflow-x-auto px-4 py-1',
				className,
			)}
			{...rest}
		/>
	)
}

export function ScrollableToolbarContentLeft(
	props: React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return (
		<div
			className={cn('flex shrink-0 grow items-center gap-4', className)}
			{...rest}
		/>
	)
}

export function ScrollableToolbarContentRight(
	props: React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return (
		<div
			className={cn('flex shrink-0 items-center gap-4', className)}
			{...rest}
		/>
	)
}
