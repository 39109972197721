import { useMemo } from 'react'

import { getNextUniqueName } from '@/utils/get--next-unique-name'

function useUniqueName(
	names: string[] | Set<string>,
	includeSpaceBeforeNumber: boolean = true,
): {
	getUniqueName: (currentName: string) => string
} {
	const nameSet = useMemo(() => {
		return names instanceof Set ? names : new Set(names)
	}, [names])

	function getUniqueName(currentName: string): string {
		return getNextUniqueName(currentName, nameSet, includeSpaceBeforeNumber)
	}

	return {
		getUniqueName,
	}
}

export { useUniqueName }
