import {
	TMachine,
	TOrder,
	TPlanningParameters,
	TProductOperation,
} from '@repo/types'

// TODO: Get product and machine(s) directly in the args, not having to filter and find
export const getPlanningParameters = (args: {
	order: TOrder
	operations: TProductOperation<TMachine>[]
}): TPlanningParameters => {
	const { order, operations } = args
	const { quantity, earliestStartDate, dueDate, buffer } = order
	const productId = order.product.id
	return {
		orderId: order.id,
		productId,
		quantity,
		earliestStartDate,
		dueDate,
		buffer,
		operations: operations
			.filter(operation => operation.productId === productId)
			.map(operation => ({
				id: operation.id,
				phases: operation.phases,
				staffGroups: operation.staffGroups,
				tools: operation.tools,
				transition: operation.transition,
				machines: operation.phases.during.map(productionPhase => ({
					id: productionPhase.machine.id,
					availability: productionPhase.machine.availability,
				})),
			})),
	}
}
