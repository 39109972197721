import { TCalendarPeriodAdjustment, TPeriod } from '@repo/types'
import {
	areIntervalsOverlapping,
	compareAsc,
	isAfter,
	isBefore,
	max,
} from 'date-fns'

function adjustOpenPeriods(args: {
	startDate: Date
	endDate: Date
	openPeriods: TPeriod[]
	calendarAdjustments: TCalendarPeriodAdjustment[]
}): TPeriod[] {
	const { startDate, endDate, openPeriods, calendarAdjustments } = args
	const sortedCalendarAdjustments = [...calendarAdjustments].sort((a, b) =>
		compareAsc(a.startDate, b.startDate),
	)

	let adjustedPeriods: TPeriod[] = [...openPeriods]

	for (const calendarAdjustment of sortedCalendarAdjustments) {
		const newPeriods: TPeriod[] = []

		for (const period of adjustedPeriods) {
			if (
				!areIntervalsOverlapping(
					{ start: period.startDate, end: period.endDate },
					{
						start: calendarAdjustment.startDate,
						end: calendarAdjustment.endDate,
					},
				)
			) {
				// No overlap
				newPeriods.push(period)
			} else {
				// Adjust the current period based on the adjustment
				if (isAfter(calendarAdjustment.startDate, period.startDate)) {
					// Add the non-overlapping beginning part of the period
					newPeriods.push({
						startDate: period.startDate,
						endDate: new Date(calendarAdjustment.startDate),
					})
				}
				if (isBefore(calendarAdjustment.endDate, period.endDate)) {
					// Add the non-overlapping ending part of the period
					newPeriods.push({
						startDate: new Date(calendarAdjustment.endDate),
						endDate: period.endDate,
					})
				}
			}
		}

		// If the adjustment is 'open' and doesn't overlap, add it as a new period
		if (calendarAdjustment.status === 'open') {
			const overlapsExisting = newPeriods.some(p =>
				areIntervalsOverlapping(
					{
						start: calendarAdjustment.startDate,
						end: calendarAdjustment.endDate,
					},
					{
						start: p.startDate,
						end: p.endDate,
					},
				),
			)
			if (!overlapsExisting) {
				newPeriods.push({
					startDate: new Date(calendarAdjustment.startDate),
					endDate: new Date(calendarAdjustment.endDate),
				})
			}
		}

		// Use the newly calculated periods for the next iteration
		adjustedPeriods = newPeriods
	}

	// Limit periods to within the provided startDate and endDate
	adjustedPeriods = adjustedPeriods.filter(p =>
		areIntervalsOverlapping(
			{ start: p.startDate, end: p.endDate },
			{ start: startDate, end: endDate },
			{ inclusive: true },
		),
	)

	// Normalize periods by restricting them to the boundaries and removing duplicates
	adjustedPeriods = adjustedPeriods.map(p => ({
		startDate: p.startDate < startDate ? startDate : p.startDate,
		endDate: p.endDate > endDate ? endDate : p.endDate,
	}))

	// Sort periods to ensure they are in chronological order
	adjustedPeriods.sort((a, b) => compareAsc(a.startDate, b.startDate))

	// Merge overlapping periods that may have been introduced by open adjustments
	const mergedPeriods: TPeriod[] = []
	adjustedPeriods.forEach(p => {
		if (
			mergedPeriods.length === 0 ||
			mergedPeriods[mergedPeriods.length - 1].endDate < p.startDate
		) {
			mergedPeriods.push(p)
		} else {
			mergedPeriods[mergedPeriods.length - 1].endDate = max([
				mergedPeriods[mergedPeriods.length - 1].endDate,
				p.endDate,
			])
		}
	})

	return mergedPeriods
}

export { adjustOpenPeriods }
