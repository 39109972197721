import { useCallback, useState } from 'react'

function useFormDialog<T>(args: {
	onSubmit: (data: T) => void
	onClose: () => void
	onError?: (errors: unknown) => void
	open?: boolean
}) {
	const [formDialogOpen, setFormDialogOpen] = useState(args.open ?? true)
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

	const closeFormDialog = useCallback(() => {
		setFormDialogOpen(false)
		args.onClose()
	}, [args])

	const requestClose = useCallback(() => {
		if (hasUnsavedChanges) {
			setConfirmDialogOpen(true)
		} else {
			closeFormDialog()
		}
	}, [hasUnsavedChanges, closeFormDialog])

	const closeConfirmDialog = useCallback(() => {
		setConfirmDialogOpen(false)
	}, [])

	const handleUnsavedChanges = useCallback((hasChanges: boolean) => {
		setHasUnsavedChanges(hasChanges)
	}, [])

	const handleSubmit = useCallback(
		(onSubmit: (data: T) => void) => {
			return (data: T) => {
				onSubmit(data)
				closeFormDialog()
			}
		},
		[closeFormDialog],
	)

	return {
		confirmDialogProps: {
			open: confirmDialogOpen,
			onClose: closeConfirmDialog,
			onConfirm: closeFormDialog,
		},
		formDialogProps: {
			open: args.open ?? formDialogOpen,
			onClose: requestClose,
		},
		formProps: {
			onUnsavedChanges: handleUnsavedChanges,
			onSubmit: handleSubmit(args.onSubmit),
			onError: args.onError,
		},
	}
}

export { useFormDialog }
