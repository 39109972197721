import { TCalendarAdjustment } from '@repo/types'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'
import { z } from 'zod'

import { useAppSelector } from '@/app/hooks'
import { Button } from '@/components/ui/button'
import { CalendarAdjustmentForm } from '@/features/calendar-adjustments/forms/calendar-adjustment-form'
import { selectMachines } from '@/features/machines/machines-slice'
import { useFullScreen } from '@/features/planning/context/full-screen-provider'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'

const newCalendarAdjustmentSearchSchema = z.object({
	machines: z.array(z.string()).optional().catch([]),
})

const Route = createFileRoute('/planning/calendar-adjustments/new')({
	component: NewCalendarAdjustmentComponent,
	validateSearch: newCalendarAdjustmentSearchSchema,
})

function NewCalendarAdjustmentComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const machines = useAppSelector(selectMachines)
	const { addCalendarAdjustment } = useOrderPlanner()
	const { fullScreenContainer } = useFullScreen()

	const initialValues = {
		affectedMachines: search.machines
			? search.machines.map(id => ({ id }))
			: [],
	}

	function handleSubmit(data: Omit<TCalendarAdjustment, 'id'>) {
		addCalendarAdjustment(data)
		toast.success('Calendar adjustment added')
		navigate({
			to: '/planning',
		})
	}

	return (
		<CalendarAdjustmentForm
			machines={machines}
			onSubmit={handleSubmit}
			submitButtons={<Button type="submit">Add Adjustment</Button>}
			container={fullScreenContainer}
			initialValues={initialValues}
		/>
	)
}

export { Route }
