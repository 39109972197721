import { Table } from '@tanstack/react-table'
import {
	Calendar,
	CircleCheck,
	CircleHelp,
	CircleX,
	Timer,
	X,
} from 'lucide-react'

import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
} from '@/components/scrollable-toolbar'
import { DataTableFacetedFilter } from '@/components/table/data-table-faceted-filter'
import { DataTableViewOptions } from '@/components/table/data-table-view-options'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTableFilterValue } from '@/lib/hooks/use-table-filter-value'

interface DataTableToolbarProps<TData> {
	table: Table<TData>
}

const statuses = [
	{
		value: 'pending',
		label: 'Pending',
		icon: CircleHelp,
	},
	{
		value: 'planned',
		label: 'Planned',
		icon: Calendar,
	},
	{
		value: 'in-progress',
		label: 'In Progress',
		icon: Timer,
	},
	{
		value: 'completed',
		label: 'Completed',
		icon: CircleCheck,
	},
	{
		value: 'inactive',
		label: 'Inactive',
		icon: CircleX,
	},
]

export function DataTableToolbar<TData>({
	table,
}: DataTableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0

	const [productionOrderNumber, setProductionOrderNumber] = useTableFilterValue(
		'productionOrderNumber',
		table,
	)
	const [product, setProduct] = useTableFilterValue('product', table)
	const [customerName, setCustomerName] = useTableFilterValue(
		'customerName',
		table,
	)

	return (
		<ScrollableToolbar>
			<ScrollableToolbarContentLeft className="gap-2">
				<Input
					placeholder="Filter PO#..."
					value={productionOrderNumber}
					onChange={setProductionOrderNumber}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				<Input
					placeholder="Filter products..."
					value={product}
					onChange={setProduct}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				<Input
					placeholder="Filter customers..."
					value={customerName}
					onChange={setCustomerName}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				{table.getColumn('status') && (
					<DataTableFacetedFilter
						column={table.getColumn('status')}
						title="Status"
						options={statuses}
					/>
				)}
				{isFiltered && (
					<Button
						variant="ghost"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<X className="ml-2 h-4 w-4" />
					</Button>
				)}
			</ScrollableToolbarContentLeft>
			<DataTableViewOptions table={table} />
		</ScrollableToolbar>
	)
}
