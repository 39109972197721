import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TTool = {
	id: string
	name: string
}

type ToolsState = {
	list: TTool[]
}

const initialState: ToolsState = {
	list: [],
}

export const toolsSlice = createSlice({
	name: 'tools',
	initialState,
	reducers: {
		createTool: {
			reducer: (state, action: PayloadAction<TTool>) => {
				const toolExists = state.list.some(
					tool => tool.name === action.payload.name,
				)
				if (!toolExists && action.payload.name !== '') {
					state.list.push(action.payload)
				}
			},
			prepare: (toolData: Omit<TTool, 'id'>) => {
				const id = createId()
				return { payload: { ...toolData, id } }
			},
		},
		editTool: (state, action: PayloadAction<TTool>) => {
			const tool = state.list.find(tool => tool.id === action.payload.id)
			if (tool) {
				Object.assign(tool, action.payload)
			}
		},
		deleteTool: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(tool => tool.id !== action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const { createTool, editTool, deleteTool } = toolsSlice.actions

export const selectTool = (id: string) =>
	createSelector(
		(state: RootState) => state.tools.list,
		tools => tools.find(tool => tool.id === id),
	)

export const selectTools = (state: RootState) => state.tools.list

export const selectToolNames = createSelector(selectTools, tools =>
	tools.map(tool => tool.name),
)

export default toolsSlice.reducer
