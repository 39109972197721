import { useState } from 'react'

import { Check, ChevronsUpDown, Plus } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

type Option = {
	value: string
	label: string
}

type EnhancedOption = Option & { isUserCreated?: boolean }

function Combobox(
	props: {
		options: Option[]
		onSelect: (value: string) => void
		searchPlaceholder: string
		valuePlaceholder: string
		initialValue?: string
		canDeselect?: boolean
		disabled?: boolean
		error?: boolean
		allowCreation?: boolean
		classNameButton?: string
		classNameContent?: string
	} & {
		container?: HTMLElement | null
	},
) {
	const {
		options,
		onSelect,
		searchPlaceholder,
		valuePlaceholder,
		initialValue = '',
		canDeselect = false,
		disabled = false,
		error = false,
		allowCreation = false,
		classNameButton = '',
		classNameContent = '',
		container,
	} = props
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState(initialValue)
	const [search, setSearch] = useState('')
	const [customOptions, setCustomOptions] = useState<EnhancedOption[]>([])

	const existingOptions = [...customOptions, ...options]

	const newOptions =
		allowCreation &&
		search.length > 0 &&
		!existingOptions.some(option => option.value === search)
			? [{ value: search, label: search, isUserCreated: true }]
			: []

	const searchResults: EnhancedOption[] = [...newOptions, ...existingOptions]

	const handleSelect = (selectedOption: EnhancedOption) => {
		const isDeselecting = canDeselect && selectedOption.value === value
		const newValue = isDeselecting ? '' : selectedOption.value
		setSearch('')
		if (
			selectedOption.isUserCreated &&
			!customOptions.some(option => option.value === selectedOption.value)
		) {
			setCustomOptions(prev => [
				...prev,
				{ value: selectedOption.value, label: selectedOption.value },
			])
		}
		setValue(newValue)
		onSelect(newValue)
		setOpen(false)
	}

	const selectedLabel = existingOptions.find(
		option => option.value === value,
	)?.label

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button
					disabled={disabled}
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn(
						'w-[200px] justify-between',
						error && 'border-destructive',
						classNameButton,
					)}
				>
					<span className="truncate">{selectedLabel ?? valuePlaceholder}</span>
					{!disabled && (
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className={cn(
					'max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] p-0',
					classNameContent,
				)}
				align="start"
				container={container}
			>
				<Command>
					<CommandInput
						placeholder={searchPlaceholder}
						onValueChange={setSearch}
					/>
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						<CommandGroup>
							{searchResults.map(option => (
								<CommandItem
									key={option.value}
									value={
										option.isUserCreated && search.trim() === search
											? option.value
											: `${option.value} ${option.label}`
									}
									onSelect={() => handleSelect(option)}
								>
									{option.isUserCreated ? (
										<Plus className="mr-2 h-4 w-4" />
									) : (
										<Check
											className={cn(
												'mr-2 h-4 w-4',
												value === option.value ? 'opacity-100' : 'opacity-0',
											)}
										/>
									)}
									{option.isUserCreated
										? `Add "${option.label}"`
										: option.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

export { Combobox }
