import { useEffect, useRef, useState } from 'react'

function useDragToScroll<T extends HTMLElement>(args: {
	sensitivity?: number
	scrollRef: React.RefObject<T>
}) {
	const { sensitivity = 2, scrollRef } = args
	const [isDragging, setIsDragging] = useState(false)
	const startPos = useRef({ x: 0, scrollLeft: 0 })

	useEffect(() => {
		const element = scrollRef.current
		if (!element) return

		const onMouseDown = (e: MouseEvent) => {
			setIsDragging(true)
			startPos.current = {
				x: e.pageX,
				scrollLeft: element.scrollLeft,
			}
		}

		const onMouseMove = (e: MouseEvent) => {
			if (!isDragging) return
			e.preventDefault()
			const dx = e.pageX - startPos.current.x
			const walk = dx * sensitivity
			element.scrollLeft = startPos.current.scrollLeft - walk
		}

		const onMouseUp = () => setIsDragging(false)
		const onMouseLeave = () => setIsDragging(false)

		element.addEventListener('mousedown', onMouseDown)
		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)
		element.addEventListener('mouseleave', onMouseLeave)

		return () => {
			element.removeEventListener('mousedown', onMouseDown)
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('mouseup', onMouseUp)
			element.removeEventListener('mouseleave', onMouseLeave)
		}
	}, [sensitivity, isDragging, scrollRef])

	return { isDragging }
}

export { useDragToScroll }
