import { forwardRef, useMemo } from 'react'

import { TMachine, TTimeRange, weekdays } from '@repo/types'
import { Copy, MoreVertical, Pencil, Trash2 } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { stopPropagation } from '@/utils/stop-propagation'

function calculateDuration(shift: TTimeRange): number {
	const [startHour, startMinute] = shift.from.split(':').map(Number)
	const [endHour, endMinute] = shift.to.split(':').map(Number)

	let duration

	if (endHour === 23 && endMinute === 59) {
		// Special case where the end time is 23:59
		duration = 24 - (startHour + startMinute / 60)
	} else {
		// Nornal case
		duration = endHour - startHour + (endMinute - startMinute) / 60
	}

	return Math.round(duration * 100) / 100
}

function calculateWeeklyRunningHours(machine: TMachine): number {
	const total = weekdays.reduce((totalHours, day) => {
		const dailyHours = machine.availability[day].reduce(
			(dailyTotal, shift) => dailyTotal + calculateDuration(shift),
			0,
		)
		return totalHours + dailyHours
	}, 0)

	return Math.round(total)
}

function calculateDailyRunningHours(
	machine: TMachine,
): Record<(typeof weekdays)[number], number> {
	return weekdays.reduce(
		(acc, day) => {
			const dailyHours = machine.availability[day].reduce(
				(dailyTotal, shift) => dailyTotal + calculateDuration(shift),
				0,
			)
			acc[day] = dailyHours
			return acc
		},
		{} as Record<(typeof weekdays)[number], number>,
	)
}

const MachineCard = forwardRef(function MachineCard(
	props: {
		machine: TMachine
		onEdit: () => void
		onDuplicate: () => void
		onDelete: () => void
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const weeklyRunningHours = useMemo(
		() => calculateWeeklyRunningHours(props.machine),
		[props.machine],
	)
	const dailyRunningHours = useMemo(
		() => calculateDailyRunningHours(props.machine),
		[props.machine],
	)

	return (
		<Card
			ref={ref}
			className="relative min-h-40 p-4"
			role="button"
			onClick={props.onEdit}
		>
			<h2 className="truncate text-lg" title={props.machine.name}>
				{props.machine.name}
			</h2>
			<p className="my-1 text-sm text-muted-foreground">
				Available {weeklyRunningHours} h/week
			</p>
			<div className="mt-2 flex flex-wrap gap-1">
				{weekdays.map(day => (
					<Tooltip key={day}>
						<TooltipTrigger>
							<span className="flex overflow-hidden rounded-sm border text-xs">
								<span className="bg-muted p-1.5 capitalize">
									{day.slice(0, 3)}
								</span>
								<span className="p-1.5 text-muted-foreground">
									{dailyRunningHours[day]}h
								</span>
							</span>
						</TooltipTrigger>
						<TooltipContent>
							<div className="flex flex-col gap-1">
								{props.machine.availability[day].length === 0 ? (
									<span className="text-xs">Not available</span>
								) : (
									props.machine.availability[day].map((shift, index) => (
										<span
											key={`${day}-${index}-from:${shift.from}-to:${shift.to}`}
											className="font-mono text-xs"
										>
											{shift.from}
											<span className="text-muted-foreground/80">—</span>
											{shift.to}
										</span>
									))
								)}
							</div>
						</TooltipContent>
					</Tooltip>
				))}
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
					<Button variant="ghost" className="h-8 w-8 p-2">
						<MoreVertical className="h-4 w-4 shrink-0" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					<DropdownMenuItem onClick={stopPropagation(props.onEdit)}>
						<Pencil className="mr-2 h-4 w-4" />
						<span>Edit</span>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={stopPropagation(props.onDuplicate)}>
						<Copy className="mr-2 h-4 w-4" />
						<span>Duplicate</span>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={stopPropagation(props.onDelete)}>
						<Trash2 className="mr-2 h-4 w-4" />
						<span>Delete</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</Card>
	)
})

export { MachineCard }
