import { useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TCalendarAdjustment, TMachine } from '@repo/types'
import {
	endOfDay,
	format,
	isAfter,
	isBefore,
	isSameDay,
	parse,
	startOfDay,
} from 'date-fns'
import { CalendarIcon, CheckCheck, Plus, Trash2 } from 'lucide-react'
import {
	Controller,
	FieldErrors,
	SubmitHandler,
	useFieldArray,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Combobox } from '@/components/ui/combobox'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

const MIDNIGHT = '00:00'

const timeSchema = z
	.string()
	.trim()
	.regex(
		/^([01]\d|2[0-3]):([0-5]\d)$/,
		'Use 24-hour format (HH:mm), e.g. 13:45',
	)
	.or(z.literal(''))
	.or(z.literal('24:00'))
	.transform(time =>
		time === '' ? MIDNIGHT : time === '24:00' ? '23:59' : time,
	)

const calendarAdjustmentSchema = z
	.object({
		description: z
			.string({
				required_error: 'Please enter a reason',
				invalid_type_error: 'Reason must be a string',
			})
			.trim()
			.optional()
			.transform(description => (description === '' ? undefined : description)),
		startTime: timeSchema,
		startDate: z.string().datetime(),
		endTime: timeSchema,
		endDate: z.string().datetime(),
		status: z.union([z.literal('open'), z.literal('closed')]),
		affectedMachines: z
			.array(z.object({ id: z.string() }), {
				required_error: 'Machines must be defined',
				invalid_type_error: 'Machines must be a list',
			})
			.refine(machines => !machines.some(machine => machine.id === ''), {
				message:
					'If selecting specific machines, make sure none are left empty. Or leave the list empty to apply to all machines.',
			}),
	})
	.refine(
		({ startDate, endDate, startTime, endTime }) => {
			const startDateTime = parse(startTime, 'HH:mm', startDate)
			const endDateTime = parse(endTime, 'HH:mm', endDate)
			return !(startDateTime >= endDateTime && isSameDay(startDate, endDate))
		},
		{
			message: 'End time must be later than start time on the same day',
			path: ['endTime'],
		},
	)
	.refine(
		({ startDate, endDate, startTime, endTime }) => {
			const startDateTime = parse(startTime, 'HH:mm', startDate)
			const endDateTime = parse(endTime, 'HH:mm', endDate)
			return !(startDateTime >= endDateTime && !isSameDay(startDate, endDate))
		},
		{
			message: 'End date is earlier than start date',
			path: ['endDate'],
		},
	)

export type CalendarAdjustmentSchema = z.infer<typeof calendarAdjustmentSchema>

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<CalendarAdjustmentSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function CalendarAdjustmentForm(props: {
	machines: TMachine[]
	submitButtons: SubmitButtonsRenderer
	container?: HTMLDivElement | null
	onSubmit: SubmitHandler<Omit<TCalendarAdjustment, 'id'>>
	onError?: (errors: FieldErrors<CalendarAdjustmentSchema>) => void
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Partial<Omit<TCalendarAdjustment, 'id'>>
}) {
	const { machines, container, onUnsavedChanges } = props

	const {
		register,
		handleSubmit,
		control,
		watch,
		trigger,
		setValue,
		formState: { errors, isDirty },
	} = useForm<CalendarAdjustmentSchema>({
		resolver: zodResolver(calendarAdjustmentSchema),
		defaultValues: {
			description: props.initialValues?.description ?? '',
			startDate: props.initialValues?.startDate ?? new Date().toISOString(),
			startTime: format(
				props.initialValues?.startDate ?? startOfDay(new Date()),
				'HH:mm',
			),
			endDate: props.initialValues?.endDate ?? new Date().toISOString(),
			endTime: format(
				props.initialValues?.endDate ?? endOfDay(new Date()),
				'HH:mm',
			),
			status: props.initialValues?.status ?? 'open',
			affectedMachines: props.initialValues?.affectedMachines ?? [],
		},
	})

	const watchedMachines = watch('affectedMachines', [])
	const watchedMachineIds = new Set(watchedMachines.map(m => m.id))

	const { fields, append, update, insert, remove } = useFieldArray({
		name: 'affectedMachines',
		control,
		keyName: 'key',
	})

	const [startDateCalendarOpen, setStartDateCalendarOpen] = useState(false)
	const [endDateCalendarOpen, setEndDateCalendarOpen] = useState(false)

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	function handleTimeChange(name: 'startTime' | 'endTime') {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			// Transforms a numeric string into a time format.
			// For 1-2 digits: unchanged (e.g., '1' -> '1', '12' -> '12').
			// For 3-4 digits: inserts a colon (e.g., '123' -> '12:3', '1234' -> '12:34').
			const value = e.target.value
			const transformedValue = value.replace(
				/(\d{1,2})(\d{0,2})/,
				(_match, firstPart, secondPart) => {
					// Check if the first part is a single digit between 3 and 9 and prepend a 0 if true
					if (firstPart.length === 1 && parseInt(firstPart, 10) >= 3) {
						firstPart = `0${firstPart}`
					}

					return secondPart.length === 0
						? firstPart
						: `${firstPart}:${secondPart}`
				},
			)

			setValue(name, transformedValue)
		}
	}

	function handleTimeBlur(name: 'startTime' | 'endTime') {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value

			// Match only integers between 0 and 24, including 00, 01, ..., 24.
			const isValidHour = /^(0?[0-9]|1[0-9]|2[0-4])$/.test(value)

			let transformedValue = value

			if (isValidHour) {
				transformedValue = `${value.padStart(2, '0')}:00`
			}

			setValue(name, transformedValue)
			trigger(name)
		}
	}

	return (
		<form
			onSubmit={handleSubmit(data => {
				const { startTime, endTime, startDate, endDate, ...rest } = data
				props.onSubmit({
					startDate: parse(startTime, 'HH:mm', startDate).toISOString(),
					endDate: parse(endTime, 'HH:mm', endDate).toISOString(),
					...rest,
				})
			}, props.onError)}
			autoComplete="off"
		>
			<div className="grid gap-6 pb-6 pt-4">
				<div className="grid grid-cols-[64px_1fr] items-center gap-x-4 gap-y-2">
					<Label className="col-span-full">Period</Label>
					<Separator className="col-span-full" />
					<Label htmlFor="startTime" className="text-right text-xs">
						Start
					</Label>
					<div className="grid grid-cols-[64px_1fr] gap-x-2">
						<Input
							className="max-w-16 text-center"
							error={Boolean(errors.startTime)}
							placeholder={MIDNIGHT}
							{...register('startTime', { required: true })}
							onChange={handleTimeChange('startTime')}
							onBlur={handleTimeBlur('startTime')}
						/>
						<Controller
							name="startDate"
							control={control}
							render={({ field }) => (
								<Popover
									open={startDateCalendarOpen}
									onOpenChange={setStartDateCalendarOpen}
								>
									<PopoverTrigger asChild>
										<Button
											variant="outline"
											className={cn(
												'justify-start text-left font-normal',
												!field.value && 'text-muted-foreground',
												errors.startDate && 'border-destructive',
											)}
										>
											<CalendarIcon className="mr-2 h-4 w-4" />
											{field.value ? (
												format(field.value, 'PPP')
											) : (
												<span>Pick a date</span>
											)}
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" container={container}>
										<Calendar
											mode="single"
											selected={new Date(field.value)}
											defaultMonth={new Date(field.value)}
											onSelect={date => {
												if (date) {
													field.onChange(date.toISOString())
													const endDate = watch('endDate')
													if (endDate && isAfter(date, endDate)) {
														setValue('endDate', date.toISOString())
													}
												}
												setStartDateCalendarOpen(false)
											}}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
							)}
						/>
					</div>
					{(errors.startTime || errors.startDate) && (
						<div className="col-span-full grid grid-cols-subgrid">
							{errors.startTime && (
								<span className="col-start-2 -mt-1 text-xs text-destructive">
									{errors.startTime.message}
								</span>
							)}
							{errors.startDate && (
								<span className="col-start-2 -mt-1 text-xs text-destructive">
									{errors.startDate.message}
								</span>
							)}
						</div>
					)}
					<Label htmlFor="endTime" className="text-right text-xs">
						End
					</Label>
					<div className="grid grid-cols-[64px_1fr] gap-x-2">
						<Input
							className="text-center"
							error={Boolean(errors.endTime)}
							placeholder={MIDNIGHT}
							{...register('endTime', { required: true })}
							onChange={handleTimeChange('endTime')}
							onBlur={handleTimeBlur('endTime')}
						/>
						<Controller
							name="endDate"
							control={control}
							render={({ field }) => (
								<Popover
									open={endDateCalendarOpen}
									onOpenChange={setEndDateCalendarOpen}
								>
									<PopoverTrigger asChild>
										<Button
											variant="outline"
											className={cn(
												'justify-start text-left font-normal',
												!field.value && 'text-muted-foreground',
												errors.endDate && 'border-destructive',
											)}
										>
											<CalendarIcon className="mr-2 h-4 w-4" />
											{field.value ? (
												format(field.value, 'PPP')
											) : (
												<span>Pick a date</span>
											)}
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" container={container}>
										<Calendar
											mode="single"
											selected={new Date(field.value)}
											defaultMonth={new Date(field.value)}
											onSelect={date => {
												if (date) {
													field.onChange(date.toISOString())
													const startDate = watch('startDate')
													if (startDate && isBefore(date, startDate)) {
														setValue('startDate', date.toISOString())
													}
												}
												setEndDateCalendarOpen(false)
											}}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
							)}
						/>
					</div>
					{(errors.endTime || errors.endDate) && (
						<div className="col-span-full grid grid-cols-subgrid">
							{errors.endTime && (
								<span className="col-start-2 -mt-1 text-xs text-destructive">
									{errors.endTime.message}
								</span>
							)}
							{errors.endDate && (
								<span className="col-start-2 -mt-1 text-xs text-destructive">
									{errors.endDate.message}
								</span>
							)}
						</div>
					)}
				</div>
				<div className="grid items-center gap-2">
					<Label className="col-span-full">Availability</Label>
					<Separator />
					<Controller
						name="status"
						control={control}
						render={({ field }) => (
							<RadioGroup defaultValue={field.value} onChange={field.onChange}>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="open" id="field-open" />
									<Label htmlFor="field-open" className="text-xs">
										Open
									</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="closed" id="field-closed" />
									<Label htmlFor="field-closed" className="text-xs">
										Closed
									</Label>
								</div>
							</RadioGroup>
						)}
					/>
					{errors.status && (
						<span className="-mt-1 text-xs text-destructive">
							{errors.status.message}
						</span>
					)}
				</div>
				<div className="grid grid-cols-8 items-center gap-2">
					<Label className="col-span-full">
						Machines{' '}
						<span className="text-xs font-normal text-muted-foreground">
							(leave empty to affect all machines)
						</span>
					</Label>
					<div className="col-span-full grid grid-cols-subgrid gap-2">
						{fields.length === 0 ? (
							<>
								<Button variant="outline" disabled className="col-span-4 p-2">
									<CheckCheck className="mr-2 h-4 w-4" /> Affects All Machines
								</Button>
								<Button
									variant="outline"
									type="button"
									className="col-span-4 p-2"
									onClick={() => append({ id: '' })}
								>
									<Plus className="mr-2 h-4 w-4" /> Choose Machine(s)
								</Button>
							</>
						) : (
							fields.map((machine, index) => (
								<>
									<Combobox
										key={machine.key}
										initialValue={machine.id}
										options={machines
											.filter(
												m =>
													!watchedMachineIds.has(m.id) || m.id === machine.id,
											)
											.map(m => ({
												value: m.id,
												label: m.name,
											}))}
										searchPlaceholder="Search machine..."
										valuePlaceholder="Select a machine..."
										classNameButton="col-span-6 w-auto"
										onSelect={value => {
											update(index, { id: value })
										}}
										error={Boolean(errors.affectedMachines)}
										container={container}
									/>
									<Button
										variant="outline"
										type="button"
										className="p-2"
										onClick={() => insert(index + 1, { id: '' })}
									>
										<Plus className="h-4 w-4" />
									</Button>
									<Button
										variant="outline"
										type="button"
										className="p-2"
										onClick={() => remove(index)}
									>
										<Trash2 className="h-4 w-4" />
									</Button>
								</>
							))
						)}
						{errors.affectedMachines && (
							<span className="col-span-full -mt-1 text-xs text-destructive">
								{errors.affectedMachines.message}
							</span>
						)}
					</div>
				</div>
				<div className="grid items-center gap-x-4 gap-y-2">
					<Label className="col-span-full">
						Reason{' '}
						<span className="text-xs font-normal text-muted-foreground">
							(optional)
						</span>
					</Label>
					<Input
						className="col-span-full"
						error={Boolean(errors.description)}
						{...register('description', { required: true })}
					/>
					{errors.description && (
						<span className="-mt-1 text-xs text-destructive">
							{errors.description.message}
						</span>
					)}
				</div>
			</div>
			<DialogFooter>
				{typeof props.submitButtons === 'function'
					? props.submitButtons({ handleSubmit })
					: props.submitButtons}
			</DialogFooter>
		</form>
	)
}

export { CalendarAdjustmentForm }
