import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TCustomer = {
	id: string
	name: string
}

type CustomersState = {
	list: TCustomer[]
}

const initialState: CustomersState = {
	list: [],
}

export const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		createCustomer: {
			reducer: (state, action: PayloadAction<TCustomer>) => {
				const customerExists = state.list.some(
					customer => customer.name === action.payload.name,
				)
				if (!customerExists && action.payload.name !== '') {
					state.list.push(action.payload)
				}
			},
			prepare: (customerData: Omit<TCustomer, 'id'>) => {
				const id = createId()
				return { payload: { ...customerData, id } }
			},
		},
		editCustomer: (state, action: PayloadAction<TCustomer>) => {
			const customer = state.list.find(
				customer => customer.id === action.payload.id,
			)
			if (customer) {
				Object.assign(customer, action.payload)
			}
		},
		deleteCustomer: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(customer => customer.id !== action.payload)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const { createCustomer, editCustomer, deleteCustomer } =
	customersSlice.actions

export const selectCustomer = (id: string) =>
	createSelector(
		(state: RootState) => state.customers.list,
		customers => customers.find(customer => customer.id === id),
	)

export const selectCustomers = (state: RootState) => state.customers.list

export const selectCustomerNames = createSelector(selectCustomers, customers =>
	customers.map(customer => customer.name),
)

export default customersSlice.reducer
