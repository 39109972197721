import { parseFlexibleNumber } from './parse-flexible-number'

const numberFormat = new Intl.NumberFormat('da-DK')

function getDecimalSeparator(
	format: Intl.NumberFormat,
	defaultSeparator: string = '.',
) {
	const numberWithDecimalSeparator = 1.1
	const parts = format.formatToParts(numberWithDecimalSeparator)
	const decimal = parts.find(part => part.type === 'decimal')
	return decimal ? decimal.value : defaultSeparator
}

const decimalSeparator = getDecimalSeparator(numberFormat)

function getThousandSeparator(
	format: Intl.NumberFormat,
	defaultSeparator: string = '.',
) {
	const numberWithThousandSeparator = 1000
	const parts = format.formatToParts(numberWithThousandSeparator)
	const thousand = parts.find(part => part.type === 'group')
	return thousand ? thousand.value : defaultSeparator
}

const thousandSeparator = getThousandSeparator(numberFormat)

function formatNumericString(
	numStr: string | number = '',
	emptyValue: string | number = '',
) {
	if (numStr === '') return String(emptyValue)
	try {
		return numberFormat.format(parseFlexibleNumber(String(numStr)))
	} catch {
		return numberFormat.format(Number(numStr))
	}
}

export {
	formatNumericString,
	numberFormat,
	decimalSeparator,
	thousandSeparator,
}
