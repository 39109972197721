import { createId } from '@paralleldrive/cuid2'
import { areIntervalsOverlapping, max, min } from 'date-fns'

import { TPlanValidator } from './types'

const validateOverlaps: TPlanValidator = plan => {
	const alerts: ReturnType<TPlanValidator> = []

	for (let i = 0; i < plan.bookings.length; i++) {
		for (let j = i + 1; j < plan.bookings.length; j++) {
			const bookingA = plan.bookings[i]
			const bookingB = plan.bookings[j]

			if (bookingA.status === 'completed' || bookingB.status === 'completed')
				continue // TODO: Add test case for this

			if (bookingA.machineId === bookingB.machineId) {
				if (
					areIntervalsOverlapping(
						{ start: bookingA.startDate, end: bookingA.endDate },
						{ start: bookingB.startDate, end: bookingB.endDate },
					)
				) {
					alerts.push({
						id: createId(),
						category: 'overlap',
						orders: [{ id: bookingA.orderId }, { id: bookingB.orderId }],
						bookings: [{ id: bookingA.id }, { id: bookingB.id }],
						startDate: max([
							bookingA.startDate,
							bookingB.startDate,
						]).toISOString(),
						endDate: min([bookingA.endDate, bookingB.endDate]).toISOString(),
						suggestion: `Should not be scheduled on the same machine at the same time.`,
					})
				}
			}
		}
	}

	return alerts
}

export { validateOverlaps }
