import {
	CalendarClock,
	CalendarX2,
	Hammer,
	LucideProps,
	TriangleAlert,
	Unlink,
} from 'lucide-react'

import { TPlanningAlert } from '@/features/validation/types'

export function AlertIcon(props: { category: TPlanningAlert['category'] }) {
	const { category } = props

	const iconMap: Record<
		TPlanningAlert['category'],
		React.ComponentType<LucideProps>
	> = {
		overlap: TriangleAlert,
		early: CalendarX2,
		late: CalendarClock,
		'transition-soft-linked': Unlink,
		'transition-hard-linked': Unlink,
		'tool-overlap': Hammer,
	}
	const Icon = iconMap[category]

	return <Icon className="h-4 w-4 shrink-0" />
}
