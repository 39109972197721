/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ProgressImport } from './routes/progress'
import { Route as ProductsImport } from './routes/products'
import { Route as PlanningImport } from './routes/planning'
import { Route as OrdersImport } from './routes/orders'
import { Route as MachinesImport } from './routes/machines'
import { Route as AlertsImport } from './routes/alerts'
import { Route as IndexImport } from './routes/index'
import { Route as ProductsCreateImport } from './routes/products.create'
import { Route as PlanningCalendarAdjustmentsImport } from './routes/planning.calendar-adjustments'
import { Route as OrdersCreateImport } from './routes/orders.create'
import { Route as MachinesCreateImport } from './routes/machines.create'
import { Route as ProductsProductIdEditImport } from './routes/products.$productId.edit'
import { Route as ProductsProductIdDeleteImport } from './routes/products.$productId.delete'
import { Route as PlanningCalendarAdjustmentsNewImport } from './routes/planning.calendar-adjustments.new'
import { Route as OrdersOrderIdEditImport } from './routes/orders.$orderId.edit'
import { Route as OrdersOrderIdDeleteImport } from './routes/orders.$orderId.delete'
import { Route as MachinesMachineIdEditImport } from './routes/machines.$machineId.edit'
import { Route as MachinesMachineIdDuplicateImport } from './routes/machines.$machineId.duplicate'
import { Route as MachinesMachineIdDeleteImport } from './routes/machines.$machineId.delete'

// Create/Update Routes

const ProgressRoute = ProgressImport.update({
  id: '/progress',
  path: '/progress',
  getParentRoute: () => rootRoute,
} as any)

const ProductsRoute = ProductsImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => rootRoute,
} as any)

const PlanningRoute = PlanningImport.update({
  id: '/planning',
  path: '/planning',
  getParentRoute: () => rootRoute,
} as any)

const OrdersRoute = OrdersImport.update({
  id: '/orders',
  path: '/orders',
  getParentRoute: () => rootRoute,
} as any)

const MachinesRoute = MachinesImport.update({
  id: '/machines',
  path: '/machines',
  getParentRoute: () => rootRoute,
} as any)

const AlertsRoute = AlertsImport.update({
  id: '/alerts',
  path: '/alerts',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ProductsCreateRoute = ProductsCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => ProductsRoute,
} as any)

const PlanningCalendarAdjustmentsRoute =
  PlanningCalendarAdjustmentsImport.update({
    id: '/calendar-adjustments',
    path: '/calendar-adjustments',
    getParentRoute: () => PlanningRoute,
  } as any)

const OrdersCreateRoute = OrdersCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => OrdersRoute,
} as any)

const MachinesCreateRoute = MachinesCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => MachinesRoute,
} as any)

const ProductsProductIdEditRoute = ProductsProductIdEditImport.update({
  id: '/$productId/edit',
  path: '/$productId/edit',
  getParentRoute: () => ProductsRoute,
} as any)

const ProductsProductIdDeleteRoute = ProductsProductIdDeleteImport.update({
  id: '/$productId/delete',
  path: '/$productId/delete',
  getParentRoute: () => ProductsRoute,
} as any)

const PlanningCalendarAdjustmentsNewRoute =
  PlanningCalendarAdjustmentsNewImport.update({
    id: '/new',
    path: '/new',
    getParentRoute: () => PlanningCalendarAdjustmentsRoute,
  } as any)

const OrdersOrderIdEditRoute = OrdersOrderIdEditImport.update({
  id: '/$orderId/edit',
  path: '/$orderId/edit',
  getParentRoute: () => OrdersRoute,
} as any)

const OrdersOrderIdDeleteRoute = OrdersOrderIdDeleteImport.update({
  id: '/$orderId/delete',
  path: '/$orderId/delete',
  getParentRoute: () => OrdersRoute,
} as any)

const MachinesMachineIdEditRoute = MachinesMachineIdEditImport.update({
  id: '/$machineId/edit',
  path: '/$machineId/edit',
  getParentRoute: () => MachinesRoute,
} as any)

const MachinesMachineIdDuplicateRoute = MachinesMachineIdDuplicateImport.update(
  {
    id: '/$machineId/duplicate',
    path: '/$machineId/duplicate',
    getParentRoute: () => MachinesRoute,
  } as any,
)

const MachinesMachineIdDeleteRoute = MachinesMachineIdDeleteImport.update({
  id: '/$machineId/delete',
  path: '/$machineId/delete',
  getParentRoute: () => MachinesRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/alerts': {
      id: '/alerts'
      path: '/alerts'
      fullPath: '/alerts'
      preLoaderRoute: typeof AlertsImport
      parentRoute: typeof rootRoute
    }
    '/machines': {
      id: '/machines'
      path: '/machines'
      fullPath: '/machines'
      preLoaderRoute: typeof MachinesImport
      parentRoute: typeof rootRoute
    }
    '/orders': {
      id: '/orders'
      path: '/orders'
      fullPath: '/orders'
      preLoaderRoute: typeof OrdersImport
      parentRoute: typeof rootRoute
    }
    '/planning': {
      id: '/planning'
      path: '/planning'
      fullPath: '/planning'
      preLoaderRoute: typeof PlanningImport
      parentRoute: typeof rootRoute
    }
    '/products': {
      id: '/products'
      path: '/products'
      fullPath: '/products'
      preLoaderRoute: typeof ProductsImport
      parentRoute: typeof rootRoute
    }
    '/progress': {
      id: '/progress'
      path: '/progress'
      fullPath: '/progress'
      preLoaderRoute: typeof ProgressImport
      parentRoute: typeof rootRoute
    }
    '/machines/create': {
      id: '/machines/create'
      path: '/create'
      fullPath: '/machines/create'
      preLoaderRoute: typeof MachinesCreateImport
      parentRoute: typeof MachinesImport
    }
    '/orders/create': {
      id: '/orders/create'
      path: '/create'
      fullPath: '/orders/create'
      preLoaderRoute: typeof OrdersCreateImport
      parentRoute: typeof OrdersImport
    }
    '/planning/calendar-adjustments': {
      id: '/planning/calendar-adjustments'
      path: '/calendar-adjustments'
      fullPath: '/planning/calendar-adjustments'
      preLoaderRoute: typeof PlanningCalendarAdjustmentsImport
      parentRoute: typeof PlanningImport
    }
    '/products/create': {
      id: '/products/create'
      path: '/create'
      fullPath: '/products/create'
      preLoaderRoute: typeof ProductsCreateImport
      parentRoute: typeof ProductsImport
    }
    '/machines/$machineId/delete': {
      id: '/machines/$machineId/delete'
      path: '/$machineId/delete'
      fullPath: '/machines/$machineId/delete'
      preLoaderRoute: typeof MachinesMachineIdDeleteImport
      parentRoute: typeof MachinesImport
    }
    '/machines/$machineId/duplicate': {
      id: '/machines/$machineId/duplicate'
      path: '/$machineId/duplicate'
      fullPath: '/machines/$machineId/duplicate'
      preLoaderRoute: typeof MachinesMachineIdDuplicateImport
      parentRoute: typeof MachinesImport
    }
    '/machines/$machineId/edit': {
      id: '/machines/$machineId/edit'
      path: '/$machineId/edit'
      fullPath: '/machines/$machineId/edit'
      preLoaderRoute: typeof MachinesMachineIdEditImport
      parentRoute: typeof MachinesImport
    }
    '/orders/$orderId/delete': {
      id: '/orders/$orderId/delete'
      path: '/$orderId/delete'
      fullPath: '/orders/$orderId/delete'
      preLoaderRoute: typeof OrdersOrderIdDeleteImport
      parentRoute: typeof OrdersImport
    }
    '/orders/$orderId/edit': {
      id: '/orders/$orderId/edit'
      path: '/$orderId/edit'
      fullPath: '/orders/$orderId/edit'
      preLoaderRoute: typeof OrdersOrderIdEditImport
      parentRoute: typeof OrdersImport
    }
    '/planning/calendar-adjustments/new': {
      id: '/planning/calendar-adjustments/new'
      path: '/new'
      fullPath: '/planning/calendar-adjustments/new'
      preLoaderRoute: typeof PlanningCalendarAdjustmentsNewImport
      parentRoute: typeof PlanningCalendarAdjustmentsImport
    }
    '/products/$productId/delete': {
      id: '/products/$productId/delete'
      path: '/$productId/delete'
      fullPath: '/products/$productId/delete'
      preLoaderRoute: typeof ProductsProductIdDeleteImport
      parentRoute: typeof ProductsImport
    }
    '/products/$productId/edit': {
      id: '/products/$productId/edit'
      path: '/$productId/edit'
      fullPath: '/products/$productId/edit'
      preLoaderRoute: typeof ProductsProductIdEditImport
      parentRoute: typeof ProductsImport
    }
  }
}

// Create and export the route tree

interface MachinesRouteChildren {
  MachinesCreateRoute: typeof MachinesCreateRoute
  MachinesMachineIdDeleteRoute: typeof MachinesMachineIdDeleteRoute
  MachinesMachineIdDuplicateRoute: typeof MachinesMachineIdDuplicateRoute
  MachinesMachineIdEditRoute: typeof MachinesMachineIdEditRoute
}

const MachinesRouteChildren: MachinesRouteChildren = {
  MachinesCreateRoute: MachinesCreateRoute,
  MachinesMachineIdDeleteRoute: MachinesMachineIdDeleteRoute,
  MachinesMachineIdDuplicateRoute: MachinesMachineIdDuplicateRoute,
  MachinesMachineIdEditRoute: MachinesMachineIdEditRoute,
}

const MachinesRouteWithChildren = MachinesRoute._addFileChildren(
  MachinesRouteChildren,
)

interface OrdersRouteChildren {
  OrdersCreateRoute: typeof OrdersCreateRoute
  OrdersOrderIdDeleteRoute: typeof OrdersOrderIdDeleteRoute
  OrdersOrderIdEditRoute: typeof OrdersOrderIdEditRoute
}

const OrdersRouteChildren: OrdersRouteChildren = {
  OrdersCreateRoute: OrdersCreateRoute,
  OrdersOrderIdDeleteRoute: OrdersOrderIdDeleteRoute,
  OrdersOrderIdEditRoute: OrdersOrderIdEditRoute,
}

const OrdersRouteWithChildren =
  OrdersRoute._addFileChildren(OrdersRouteChildren)

interface PlanningCalendarAdjustmentsRouteChildren {
  PlanningCalendarAdjustmentsNewRoute: typeof PlanningCalendarAdjustmentsNewRoute
}

const PlanningCalendarAdjustmentsRouteChildren: PlanningCalendarAdjustmentsRouteChildren =
  {
    PlanningCalendarAdjustmentsNewRoute: PlanningCalendarAdjustmentsNewRoute,
  }

const PlanningCalendarAdjustmentsRouteWithChildren =
  PlanningCalendarAdjustmentsRoute._addFileChildren(
    PlanningCalendarAdjustmentsRouteChildren,
  )

interface PlanningRouteChildren {
  PlanningCalendarAdjustmentsRoute: typeof PlanningCalendarAdjustmentsRouteWithChildren
}

const PlanningRouteChildren: PlanningRouteChildren = {
  PlanningCalendarAdjustmentsRoute:
    PlanningCalendarAdjustmentsRouteWithChildren,
}

const PlanningRouteWithChildren = PlanningRoute._addFileChildren(
  PlanningRouteChildren,
)

interface ProductsRouteChildren {
  ProductsCreateRoute: typeof ProductsCreateRoute
  ProductsProductIdDeleteRoute: typeof ProductsProductIdDeleteRoute
  ProductsProductIdEditRoute: typeof ProductsProductIdEditRoute
}

const ProductsRouteChildren: ProductsRouteChildren = {
  ProductsCreateRoute: ProductsCreateRoute,
  ProductsProductIdDeleteRoute: ProductsProductIdDeleteRoute,
  ProductsProductIdEditRoute: ProductsProductIdEditRoute,
}

const ProductsRouteWithChildren = ProductsRoute._addFileChildren(
  ProductsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/alerts': typeof AlertsRoute
  '/machines': typeof MachinesRouteWithChildren
  '/orders': typeof OrdersRouteWithChildren
  '/planning': typeof PlanningRouteWithChildren
  '/products': typeof ProductsRouteWithChildren
  '/progress': typeof ProgressRoute
  '/machines/create': typeof MachinesCreateRoute
  '/orders/create': typeof OrdersCreateRoute
  '/planning/calendar-adjustments': typeof PlanningCalendarAdjustmentsRouteWithChildren
  '/products/create': typeof ProductsCreateRoute
  '/machines/$machineId/delete': typeof MachinesMachineIdDeleteRoute
  '/machines/$machineId/duplicate': typeof MachinesMachineIdDuplicateRoute
  '/machines/$machineId/edit': typeof MachinesMachineIdEditRoute
  '/orders/$orderId/delete': typeof OrdersOrderIdDeleteRoute
  '/orders/$orderId/edit': typeof OrdersOrderIdEditRoute
  '/planning/calendar-adjustments/new': typeof PlanningCalendarAdjustmentsNewRoute
  '/products/$productId/delete': typeof ProductsProductIdDeleteRoute
  '/products/$productId/edit': typeof ProductsProductIdEditRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/alerts': typeof AlertsRoute
  '/machines': typeof MachinesRouteWithChildren
  '/orders': typeof OrdersRouteWithChildren
  '/planning': typeof PlanningRouteWithChildren
  '/products': typeof ProductsRouteWithChildren
  '/progress': typeof ProgressRoute
  '/machines/create': typeof MachinesCreateRoute
  '/orders/create': typeof OrdersCreateRoute
  '/planning/calendar-adjustments': typeof PlanningCalendarAdjustmentsRouteWithChildren
  '/products/create': typeof ProductsCreateRoute
  '/machines/$machineId/delete': typeof MachinesMachineIdDeleteRoute
  '/machines/$machineId/duplicate': typeof MachinesMachineIdDuplicateRoute
  '/machines/$machineId/edit': typeof MachinesMachineIdEditRoute
  '/orders/$orderId/delete': typeof OrdersOrderIdDeleteRoute
  '/orders/$orderId/edit': typeof OrdersOrderIdEditRoute
  '/planning/calendar-adjustments/new': typeof PlanningCalendarAdjustmentsNewRoute
  '/products/$productId/delete': typeof ProductsProductIdDeleteRoute
  '/products/$productId/edit': typeof ProductsProductIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/alerts': typeof AlertsRoute
  '/machines': typeof MachinesRouteWithChildren
  '/orders': typeof OrdersRouteWithChildren
  '/planning': typeof PlanningRouteWithChildren
  '/products': typeof ProductsRouteWithChildren
  '/progress': typeof ProgressRoute
  '/machines/create': typeof MachinesCreateRoute
  '/orders/create': typeof OrdersCreateRoute
  '/planning/calendar-adjustments': typeof PlanningCalendarAdjustmentsRouteWithChildren
  '/products/create': typeof ProductsCreateRoute
  '/machines/$machineId/delete': typeof MachinesMachineIdDeleteRoute
  '/machines/$machineId/duplicate': typeof MachinesMachineIdDuplicateRoute
  '/machines/$machineId/edit': typeof MachinesMachineIdEditRoute
  '/orders/$orderId/delete': typeof OrdersOrderIdDeleteRoute
  '/orders/$orderId/edit': typeof OrdersOrderIdEditRoute
  '/planning/calendar-adjustments/new': typeof PlanningCalendarAdjustmentsNewRoute
  '/products/$productId/delete': typeof ProductsProductIdDeleteRoute
  '/products/$productId/edit': typeof ProductsProductIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/alerts'
    | '/machines'
    | '/orders'
    | '/planning'
    | '/products'
    | '/progress'
    | '/machines/create'
    | '/orders/create'
    | '/planning/calendar-adjustments'
    | '/products/create'
    | '/machines/$machineId/delete'
    | '/machines/$machineId/duplicate'
    | '/machines/$machineId/edit'
    | '/orders/$orderId/delete'
    | '/orders/$orderId/edit'
    | '/planning/calendar-adjustments/new'
    | '/products/$productId/delete'
    | '/products/$productId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/alerts'
    | '/machines'
    | '/orders'
    | '/planning'
    | '/products'
    | '/progress'
    | '/machines/create'
    | '/orders/create'
    | '/planning/calendar-adjustments'
    | '/products/create'
    | '/machines/$machineId/delete'
    | '/machines/$machineId/duplicate'
    | '/machines/$machineId/edit'
    | '/orders/$orderId/delete'
    | '/orders/$orderId/edit'
    | '/planning/calendar-adjustments/new'
    | '/products/$productId/delete'
    | '/products/$productId/edit'
  id:
    | '__root__'
    | '/'
    | '/alerts'
    | '/machines'
    | '/orders'
    | '/planning'
    | '/products'
    | '/progress'
    | '/machines/create'
    | '/orders/create'
    | '/planning/calendar-adjustments'
    | '/products/create'
    | '/machines/$machineId/delete'
    | '/machines/$machineId/duplicate'
    | '/machines/$machineId/edit'
    | '/orders/$orderId/delete'
    | '/orders/$orderId/edit'
    | '/planning/calendar-adjustments/new'
    | '/products/$productId/delete'
    | '/products/$productId/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AlertsRoute: typeof AlertsRoute
  MachinesRoute: typeof MachinesRouteWithChildren
  OrdersRoute: typeof OrdersRouteWithChildren
  PlanningRoute: typeof PlanningRouteWithChildren
  ProductsRoute: typeof ProductsRouteWithChildren
  ProgressRoute: typeof ProgressRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AlertsRoute: AlertsRoute,
  MachinesRoute: MachinesRouteWithChildren,
  OrdersRoute: OrdersRouteWithChildren,
  PlanningRoute: PlanningRouteWithChildren,
  ProductsRoute: ProductsRouteWithChildren,
  ProgressRoute: ProgressRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/alerts",
        "/machines",
        "/orders",
        "/planning",
        "/products",
        "/progress"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/alerts": {
      "filePath": "alerts.tsx"
    },
    "/machines": {
      "filePath": "machines.tsx",
      "children": [
        "/machines/create",
        "/machines/$machineId/delete",
        "/machines/$machineId/duplicate",
        "/machines/$machineId/edit"
      ]
    },
    "/orders": {
      "filePath": "orders.tsx",
      "children": [
        "/orders/create",
        "/orders/$orderId/delete",
        "/orders/$orderId/edit"
      ]
    },
    "/planning": {
      "filePath": "planning.tsx",
      "children": [
        "/planning/calendar-adjustments"
      ]
    },
    "/products": {
      "filePath": "products.tsx",
      "children": [
        "/products/create",
        "/products/$productId/delete",
        "/products/$productId/edit"
      ]
    },
    "/progress": {
      "filePath": "progress.tsx"
    },
    "/machines/create": {
      "filePath": "machines.create.tsx",
      "parent": "/machines"
    },
    "/orders/create": {
      "filePath": "orders.create.tsx",
      "parent": "/orders"
    },
    "/planning/calendar-adjustments": {
      "filePath": "planning.calendar-adjustments.tsx",
      "parent": "/planning",
      "children": [
        "/planning/calendar-adjustments/new"
      ]
    },
    "/products/create": {
      "filePath": "products.create.tsx",
      "parent": "/products"
    },
    "/machines/$machineId/delete": {
      "filePath": "machines.$machineId.delete.tsx",
      "parent": "/machines"
    },
    "/machines/$machineId/duplicate": {
      "filePath": "machines.$machineId.duplicate.tsx",
      "parent": "/machines"
    },
    "/machines/$machineId/edit": {
      "filePath": "machines.$machineId.edit.tsx",
      "parent": "/machines"
    },
    "/orders/$orderId/delete": {
      "filePath": "orders.$orderId.delete.tsx",
      "parent": "/orders"
    },
    "/orders/$orderId/edit": {
      "filePath": "orders.$orderId.edit.tsx",
      "parent": "/orders"
    },
    "/planning/calendar-adjustments/new": {
      "filePath": "planning.calendar-adjustments.new.tsx",
      "parent": "/planning/calendar-adjustments"
    },
    "/products/$productId/delete": {
      "filePath": "products.$productId.delete.tsx",
      "parent": "/products"
    },
    "/products/$productId/edit": {
      "filePath": "products.$productId.edit.tsx",
      "parent": "/products"
    }
  }
}
ROUTE_MANIFEST_END */
