import { Calendar, CircleCheck, CircleHelp, CircleX, Timer } from 'lucide-react'

import { Badge } from './ui/badge'

export function StatusBadge(props: {
	status: 'pending' | 'inactive' | 'planned' | 'in-progress' | 'completed'
	children?: React.ReactNode
}) {
	const { status, children } = props
	switch (status) {
		case 'pending':
			return (
				<Badge className="whitespace-nowrap bg-yellow-100 text-yellow-800 hover:bg-yellow-100 dark:bg-yellow-800 dark:text-yellow-100 dark:hover:bg-yellow-800">
					<CircleHelp className="mr-2 h-4 w-4 shrink-0" />
					Pending
					{children !== undefined && <>&nbsp;&bull;&nbsp;</>}
					{children}
				</Badge>
			)
		case 'inactive':
			return (
				<Badge className="whitespace-nowrap bg-slate-100 text-slate-800 hover:bg-slate-100 dark:bg-slate-800 dark:text-slate-100 dark:hover:bg-slate-800">
					<CircleX className="mr-2 h-4 w-4 shrink-0" />
					Inactive
					{children !== undefined && <>&nbsp;&bull;&nbsp;</>}
					{children}
				</Badge>
			)
		case 'planned':
			return (
				<Badge className="whitespace-nowrap bg-blue-100 text-blue-800 hover:bg-blue-100 dark:bg-blue-800 dark:text-blue-100 dark:hover:bg-blue-800">
					<Calendar className="mr-2 h-4 w-4 shrink-0" />
					Planned
					{children !== undefined && <>&nbsp;&bull;&nbsp;</>}
					{children}
				</Badge>
			)
		case 'in-progress':
			return (
				<Badge className="whitespace-nowrap bg-emerald-100 text-emerald-800 hover:bg-emerald-100 dark:bg-emerald-800 dark:text-emerald-100 dark:hover:bg-emerald-800">
					<Timer className="mr-2 h-4 w-4 shrink-0" />
					In Progress
					{children !== undefined && <>&nbsp;&bull;&nbsp;</>}
					{children}
				</Badge>
			)
		case 'completed':
			return (
				<Badge className="whitespace-nowrap bg-purple-100 text-purple-800 hover:bg-purple-100 dark:bg-purple-800 dark:text-purple-100 dark:hover:bg-purple-800">
					<CircleCheck className="mr-2 h-4 w-4 shrink-0" />
					Completed
					{children !== undefined && <>&nbsp;&bull;&nbsp;</>}
					{children}
				</Badge>
			)
	}
}
