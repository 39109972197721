import { TOrder, TOrderStatus, TProduct } from '@repo/types'
import { Link } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import {
	ArrowLeftFromLine,
	ArrowRightFromLine,
	BellRing,
	Calendar,
	Copy,
	Eraser,
	GanttChart,
	MoreHorizontal,
	Pencil,
	Power,
	PowerOff,
	Timer,
	Trash2,
} from 'lucide-react'

import { ClickToCopyBadge } from '@/components/click-to-copy-badge'
import { StatusBadge } from '@/components/status-badge'
import { DataTableColumnHeader } from '@/components/table/data-table-column-header'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { formatNumericString } from '@/utils/format-numeric-string'

declare module '@tanstack/react-table' {
	interface TableMeta<TData> {
		actions: (order: TData) => {
			onEdit: () => void
			onDuplicate: () => void
			onDelete: () => void
			onShowInPlan?: () => void
			onPlanForward?: () => void
			onPlanBackward?: () => void
			onUnschedule?: () => void
			onDeactivate?: () => void
			onReactivate?: () => void
		}
	}
}

type TOrderTableColumn = TOrder<TProduct> & {
	productionDurationMinutes: number
	formattedProductionDuration: string
	alerts: number
}

export const columns: ColumnDef<TOrderTableColumn>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<div className="flex items-center">
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			</div>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={value => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'productionOrderNumber',
		meta: { label: 'Production Order Number' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="PO#"
				tooltip="Production Order Number"
			/>
		),
		cell: ({ row }) => {
			const productionOrderNumber = row.getValue<string>(
				'productionOrderNumber',
			)
			return (
				<ClickToCopyBadge
					value={productionOrderNumber}
					displayValue={`#${productionOrderNumber}`}
					variant="outline"
				/>
			)
		},
	},
	{
		accessorKey: 'status',
		meta: { label: 'Status' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => {
			const status = row.getValue<TOrderStatus>('status')
			return <StatusBadge status={status} />
		},
		filterFn: (row, id, value) => {
			return value.includes(row.getValue(id))
		},
	},
	{
		accessorKey: 'product',
		meta: { label: 'Product' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Product" />
		),
		cell: ({ row }) => {
			const product = row.getValue<TProduct>('product')

			return (
				<div className="grid justify-items-start gap-1">
					<span>{product.name}</span>
					<ClickToCopyBadge
						value={product.productNumber}
						displayValue={`#${product.productNumber}`}
						variant="outline"
					/>
				</div>
			)
		},
		filterFn: (row, id, value) => {
			const product = row.getValue<TProduct>(id)
			return (
				product.name.toLowerCase().includes(value.toLowerCase()) ||
				product.productNumber.toLowerCase().includes(value.toLowerCase())
			)
		},
	},
	{
		accessorKey: 'quantity',
		meta: { label: 'Quantity' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Quantity" />
		),
		cell: ({ row }) => {
			const quantity = row.getValue<number>('quantity')
			return formatNumericString(quantity)
		},
	},
	{
		accessorKey: 'customerName',
		meta: { label: 'Customer' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Customer" />
		),
	},
	{
		accessorKey: 'salesOrderNumber',
		meta: { label: 'Sales Order Number' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="SO#"
				tooltip="Sales Order Number"
			/>
		),
		cell: ({ row }) => {
			const salesOrderNumber = row.getValue<string>('salesOrderNumber')
			return (
				<ClickToCopyBadge
					value={salesOrderNumber}
					displayValue={`#${salesOrderNumber}`}
					variant="outline"
				/>
			)
		},
	},
	{
		accessorKey: 'earliestStartDate',
		meta: { label: 'Earliest Start Date' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Earliest Start"
				tooltip="Earliest Start Date"
			/>
		),
		cell: ({ row }) => {
			const earliestStartDate = row.getValue<Date>('earliestStartDate')

			return (
				<Badge variant="outline" className="whitespace-nowrap">
					<Calendar className="mr-2 h-4 w-4 shrink-0" />
					{format(earliestStartDate, 'MMM d, yyyy')}
				</Badge>
			)
		},
	},
	{
		accessorKey: 'dueDate',
		meta: { label: 'Due Date' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Due" tooltip="Due Date" />
		),
		cell: ({ row }) => {
			const dueDate = row.getValue<Date>('dueDate')

			return (
				<Badge variant="outline" className="whitespace-nowrap">
					<Calendar className="mr-2 h-4 w-4 shrink-0" />
					{format(dueDate, 'MMM d, yyyy')}
				</Badge>
			)
		},
	},
	{
		accessorKey: 'productionDurationMinutes',
		meta: { label: 'Eff. Duration' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Eff. Duration"
				tooltip="Effective Production Duration"
			/>
		),
		cell: ({ row }) => {
			const productionDuration = row.original.formattedProductionDuration

			return (
				<Badge variant="outline" className="whitespace-nowrap">
					<Timer className="mr-2 h-4 w-4 shrink-0" /> {productionDuration}
				</Badge>
			)
		},
	},
	{
		accessorKey: 'alerts',
		meta: { label: 'Alerts' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Alerts" />
		),
		cell: ({ row }) => {
			const alerts = row.getValue<number>('alerts')
			const orderId = row.original.id

			if (alerts > 0) {
				return (
					<Link
						from="/orders"
						to="/alerts"
						search={{
							orderId,
						}}
					>
						<Badge variant="outline" className="whitespace-nowrap">
							<BellRing className="mr-2 h-4 w-4 shrink-0 text-destructive" />{' '}
							{alerts}
						</Badge>
					</Link>
				)
			}

			return null
		},
	},
	{
		id: 'actions',
		cell: ({ row, table }) => {
			const actions = table.options.meta?.actions(row.original)

			if (!actions) {
				return null
			}

			return (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" className="h-8 w-8 p-0">
							<span className="sr-only">Open menu</span>
							<MoreHorizontal className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuLabel>Actions</DropdownMenuLabel>
						{actions.onReactivate && (
							<DropdownMenuItem onClick={actions.onReactivate}>
								<Power className="mr-2 h-4 w-4" />
								<span>Reactivate</span>
							</DropdownMenuItem>
						)}
						{actions.onShowInPlan && (
							<DropdownMenuItem onClick={actions.onShowInPlan}>
								<GanttChart className="mr-2 h-4 w-4" />
								<span>Show In Plan</span>
							</DropdownMenuItem>
						)}
						{actions.onUnschedule && (
							<DropdownMenuItem onClick={actions.onUnschedule}>
								<Eraser className="mr-2 h-4 w-4" />
								<span>Unschedule</span>
							</DropdownMenuItem>
						)}
						{actions.onPlanForward && (
							<DropdownMenuItem onClick={actions.onPlanForward}>
								<ArrowRightFromLine className="mr-2 h-4 w-4" />
								<span>Plan forward</span>
							</DropdownMenuItem>
						)}
						{actions.onPlanBackward && (
							<DropdownMenuItem onClick={actions.onPlanBackward}>
								<ArrowLeftFromLine className="mr-2 h-4 w-4" />
								<span>Plan backward</span>
							</DropdownMenuItem>
						)}
						<DropdownMenuSeparator />
						<DropdownMenuItem onClick={actions.onEdit}>
							<Pencil className="mr-2 h-4 w-4" />
							<span>Edit</span>
						</DropdownMenuItem>
						<DropdownMenuItem onClick={actions.onDuplicate}>
							<Copy className="mr-2 h-4 w-4" />
							<span>Duplicate</span>
						</DropdownMenuItem>
						{actions.onDeactivate && (
							<DropdownMenuItem onClick={actions.onDeactivate}>
								<PowerOff className="mr-2 h-4 w-4" />
								<span>Deactivate</span>
							</DropdownMenuItem>
						)}
						<DropdownMenuItem onClick={actions.onDelete}>
							<Trash2 className="mr-2 h-4 w-4" />
							<span>Delete</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			)
		},
		enableSorting: false,
		enableHiding: false,
	},
]
