import { createContext, useContext, useEffect, useState } from 'react'

type Theme = 'dark' | 'light' | 'system'

type ThemeProviderProps = {
	children: React.ReactNode
	lightColor?: string
	darkColor?: string
	defaultTheme?: Theme
	storageKey?: string
}

type ThemeProviderState = {
	theme: Theme
	darkMode: boolean
	setTheme: (theme: Theme) => void
}

const initialState: ThemeProviderState = {
	theme: 'system',
	darkMode: false,
	setTheme: () => null,
}

const ThemeProviderContext = createContext<ThemeProviderState>(initialState)

const setMetaThemeColor = (args: {
	theme: Theme
	lightColor: string
	darkColor: string
}) => {
	const metaLight = document.querySelector(
		'meta[name="theme-color"][media="(prefers-color-scheme: light)"]',
	)
	const metaDark = document.querySelector(
		'meta[name="theme-color"][media="(prefers-color-scheme: dark)"]',
	)

	switch (args.theme) {
		case 'light':
			metaLight?.setAttribute('content', args.lightColor)
			metaDark?.setAttribute('content', args.lightColor)
			break
		case 'dark':
			metaLight?.setAttribute('content', args.darkColor)
			metaDark?.setAttribute('content', args.darkColor)
			break
		case 'system':
		default:
			metaLight?.setAttribute('content', args.lightColor)
			metaDark?.setAttribute('content', args.darkColor)
	}
}

export function ThemeProvider({
	children,
	lightColor = '#fff',
	darkColor = '#000',
	defaultTheme = 'system',
	storageKey = 'theme',
	...props
}: ThemeProviderProps) {
	const [theme, setTheme] = useState<Theme>(
		() => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
	)
	const [darkMode, setDarkMode] = useState<boolean>(defaultTheme === 'dark')

	useEffect(() => {
		setMetaThemeColor({ theme, lightColor, darkColor })
		const root = window.document.documentElement

		root.classList.remove('light', 'dark')

		if (theme === 'system') {
			const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
				.matches
				? 'dark'
				: 'light'

			root.classList.add(systemTheme)
			setDarkMode(systemTheme === 'dark')
			return
		} else {
			setDarkMode(theme === 'dark')
		}

		root.classList.add(theme)
	}, [theme, darkColor, lightColor])

	const value = {
		theme,
		darkMode,
		setTheme: (theme: Theme) => {
			localStorage.setItem(storageKey, theme)
			setTheme(theme)
		},
	}

	return (
		<ThemeProviderContext.Provider {...props} value={value}>
			{children}
		</ThemeProviderContext.Provider>
	)
}

export const useTheme = () => {
	const context = useContext(ThemeProviderContext)

	if (context === undefined)
		throw new Error('useTheme must be used within a ThemeProvider')

	return context
}
