import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { cn } from '@/lib/utils'

function Draggable(
	props: {
		id: string
		children: React.ReactElement
		element?: React.ElementType
	} & React.HTMLAttributes<HTMLElement>,
) {
	const { children, id, element, className, ...rest } = props
	const Element = element ?? 'div'
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		userSelect: 'none',
		...(isDragging && {
			zIndex: 10,
			position: 'relative',
		}),
	}

	return (
		<Element
			ref={setNodeRef}
			style={style}
			className={cn(
				'select-none transition-shadow',
				isDragging && 'shadow-lg shadow-slate-400/40',
				className,
			)}
			{...attributes}
			{...listeners}
			{...rest}
		>
			{children}
		</Element>
	)
}

export { Draggable }
