import { isAfter } from 'date-fns'
import { z } from 'zod'

import { parseFlexibleNumber } from '@/utils/parse-flexible-number'

const timeUnitSchema = z.union([z.literal('hours'), z.literal('days')])

const timeDuationSchema = z.object({
	quantity: z
		.union([
			z.number(),
			z
				.string()
				.trim()
				.transform(value => parseFlexibleNumber(value)),
		])
		.refine(value => value >= 0, {
			message: 'Duration must be ≥ 0',
		})
		.refine(value => value <= 1000, {
			message: 'Duration must be ≤ 1000',
		}),
	unit: timeUnitSchema,
})

export const orderSchmea = z
	.object({
		productionOrderNumber: z.string().trim(),
		salesOrderNumber: z.string().trim(),
		customerName: z
			.string({
				required_error: 'Please add a customer name',
			})
			.trim()
			.min(1, 'Please add a customer name'),
		product: z.object(
			{
				id: z
					.string({
						required_error: 'Please select a product',
						invalid_type_error: 'Please select a product',
					})
					.trim()
					.min(1, 'Please select a product'),
			},
			{
				required_error: 'Please select a product',
				invalid_type_error: 'Please select a product',
			},
		),
		quantity: z
			.union([
				z.number(),
				z
					.string()
					.trim()
					.transform(value => parseFlexibleNumber(value)),
			])
			.refine(value => value >= 0, {
				message: 'Quantity must be ≥ 0',
			}),
		earliestStartDate: z.string().datetime(),
		dueDate: z.string().datetime(),
		buffer: timeDuationSchema,
	})
	.refine(data => !isAfter(data.earliestStartDate, data.dueDate), {
		message: 'Earliest start date must be before or equal to the due date',
		path: ['earliestStartDate'],
	})

export type OrderSchema = z.infer<typeof orderSchmea>
