import { useCallback } from 'react'

import { Table } from '@tanstack/react-table'

export function useTableFilterValue<TData>(
	columnId: string,
	table: Table<TData>,
) {
	const value = (table.getColumn(columnId)?.getFilterValue() as string) ?? ''

	const setValue = useCallback(
		(valueOrEvent: string | React.ChangeEvent<HTMLInputElement>) => {
			const newValue =
				typeof valueOrEvent === 'string'
					? valueOrEvent
					: valueOrEvent.target.value
			table.getColumn(columnId)?.setFilterValue(newValue)
		},
		[columnId, table],
	)

	return [value, setValue] as const
}
