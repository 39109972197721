import { Table } from '@tanstack/react-table'
import { X } from 'lucide-react'

import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
} from '@/components/scrollable-toolbar'
import { DataTableViewOptions } from '@/components/table/data-table-view-options'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTableFilterValue } from '@/lib/hooks/use-table-filter-value'

interface DataTableToolbarProps<TData> {
	table: Table<TData>
}

export function DataTableToolbar<TData>({
	table,
}: DataTableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0

	const [productNumber, setProductNumber] = useTableFilterValue(
		'productNumber',
		table,
	)
	const [productName, setProductName] = useTableFilterValue('name', table)

	return (
		<ScrollableToolbar>
			<ScrollableToolbarContentLeft className="gap-2">
				<Input
					placeholder="Filter product numbers..."
					value={productNumber}
					onChange={setProductNumber}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				<Input
					placeholder="Filter names..."
					value={productName}
					onChange={setProductName}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				{isFiltered && (
					<Button
						variant="ghost"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<X className="ml-2 h-4 w-4" />
					</Button>
				)}
			</ScrollableToolbarContentLeft>
			<DataTableViewOptions table={table} />
		</ScrollableToolbar>
	)
}
