import {
	createFileRoute,
	Outlet,
	useChildMatches,
	useNavigate,
} from '@tanstack/react-router'
import { AlignJustify, Plus } from 'lucide-react'
import { toast } from 'sonner'

import { useAppSelector } from '@/app/hooks'
import { GenericDialog } from '@/components/generic-dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { CalendarAdjustmentList } from '@/features/calendar-adjustments/components/calendar-adjustment-list'
import { selectMachines } from '@/features/machines/machines-slice'
import { useFullScreen } from '@/features/planning/context/full-screen-provider'
import { useOrderPlanner } from '@/features/planning/hooks/use-order-planner'
import { selectCalendarAdjustments } from '@/features/planning/planning-slice'

const Route = createFileRoute('/planning/calendar-adjustments')({
	component: CalendarAdjustmentsComponent,
})

function CalendarAdjustmentsComponent() {
	const navigate = useNavigate({ from: Route.fullPath })
	const childMatches = useChildMatches()
	const isAddNew = childMatches.some(
		m => m.pathname === '/planning/calendar-adjustments/new',
	)
	const calendarAdjustments = useAppSelector(selectCalendarAdjustments)
	const machines = useAppSelector(selectMachines)
	const { removeCalendarAdjustment } = useOrderPlanner()
	const { fullScreenContainer } = useFullScreen()

	function handleClose() {
		navigate({
			to: '/planning',
		})
	}

	return (
		<GenericDialog
			title="Calendar adjustments"
			onClose={handleClose}
			container={fullScreenContainer}
		>
			<Tabs
				className="w-full"
				defaultValue="overview"
				value={isAddNew ? 'add-new' : 'overview'}
				onValueChange={value => {
					if (value === 'add-new') {
						navigate({
							to: '/planning/calendar-adjustments/new',
						})
					}
					if (value === 'overview') {
						navigate({
							to: '/planning/calendar-adjustments',
						})
					}
				}}
			>
				<TabsList className="w-full">
					<TabsTrigger className="w-1/2 text-xs sm:text-sm" value="overview">
						<AlignJustify className="mr-2 h-4 min-h-4 w-4 min-w-4" />
						Overview
					</TabsTrigger>
					<TabsTrigger className="w-1/2 text-xs sm:text-sm" value="add-new">
						<Plus className="mr-2 h-4 min-h-4 w-4 min-w-4" /> Add Adjustment
					</TabsTrigger>
				</TabsList>
				<TabsContent value="overview">
					<CalendarAdjustmentList
						calendarAdjustments={calendarAdjustments}
						machines={machines}
						onDelete={id => {
							removeCalendarAdjustment(id)
							toast.success('Calendar adjustment deleted')
						}}
						container={fullScreenContainer}
					/>
				</TabsContent>
				<TabsContent value="add-new">
					<Outlet />
				</TabsContent>
			</Tabs>
		</GenericDialog>
	)
}

export { Route }
