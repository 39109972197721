import { useEffect } from 'react'

function usePreventOverscrollX() {
	useEffect(() => {
		const originalStyle = document.body.style.overscrollBehaviorX

		document.body.style.overscrollBehaviorX = 'contain'

		return () => {
			document.body.style.overscrollBehaviorX = originalStyle
		}
	}, [])
}

export { usePreventOverscrollX }
