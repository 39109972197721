import { FooterLayout } from './layout'
import logoIcon from '/logo-icon.svg'

export function Footer() {
	return (
		<footer className="w-full border-t py-4">
			<FooterLayout>
				<div className="flex items-center justify-center gap-2">
					<img
						src={logoIcon}
						width={16}
						height={16}
						className="logo"
						alt="Polly logo"
					/>
					<span className="text-sm">
						Polly &copy; {new Date().getFullYear()}
					</span>
				</div>
			</FooterLayout>
		</footer>
	)
}
