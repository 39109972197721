import { useLayoutEffect, useState } from 'react'

function detectHotkeySupport() {
	const isMobileOrTablet = /Mobi|Android|iPad|iPhone/i.test(navigator.userAgent)
	const isSmallScreen = window.innerWidth <= 1024
	const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

	const supportsHotkeys = !isMobileOrTablet && !isSmallScreen && !isTouchDevice
	return supportsHotkeys
}

function detectCmdKeyModifier(): boolean {
	// @ts-expect-error navigator has no type definition for userAgentData
	const platform = navigator.userAgentData?.platform ?? navigator.platform

	return platform.toLowerCase().startsWith('mac')
}

function useHotkeyLabel(keys: string): string | null {
	const [label, setLabel] = useState<string | null>(null)

	useLayoutEffect(() => {
		const supportsHotkeys = detectHotkeySupport()
		const cmdIsKeyModifier = detectCmdKeyModifier()
		if (!supportsHotkeys) {
			setLabel(null)
		} else {
			const modifier = cmdIsKeyModifier ? '⌘' : 'Ctrl'
			const formattedKeys = keys
				.toLowerCase()
				.replace('mod', modifier)
				.split('+')
				.map(key => {
					if (cmdIsKeyModifier) {
						key = key
							.replace('shift', '⇧')
							.replace('ctrl', '⌃')
							.replace('alt', '⌥')
					}
					return key.charAt(0).toUpperCase() + key.slice(1)
				})
				.join('+')

			setLabel(formattedKeys)
		}
	}, [keys])

	return label
}

export { useHotkeyLabel }
