import { TProductOperation } from '@repo/types'
import { Link } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import {
	Copy,
	MoreHorizontal,
	Pencil,
	ReceiptText,
	Settings,
	Trash2,
} from 'lucide-react'

import { ClickToCopyBadge } from '@/components/click-to-copy-badge'
import { DataTableColumnHeader } from '@/components/table/data-table-column-header'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { TEnhancedProduct } from '@/features/products/products-slice'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'
import { pluralize } from '@/utils/pluralize'

declare module '@tanstack/react-table' {
	interface TableMeta<TData> {
		actions: (order: TData) => {
			onEdit: () => void
			onDuplicate: () => void
			onDelete: () => void
			onShowInPlan?: () => void
			onPlanForward?: () => void
			onPlanBackward?: () => void
			onUnschedule?: () => void
			onDeactivate?: () => void
			onReactivate?: () => void
		}
	}
}

type TProductTableColumn = TEnhancedProduct

export const columns: ColumnDef<TProductTableColumn>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<div className="flex items-center">
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			</div>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={value => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'productNumber',
		meta: { label: 'Product Number' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Product Number" />
		),
		cell: ({ row }) => {
			const productNumber = row.getValue<string>('productNumber')
			return (
				<ClickToCopyBadge
					value={productNumber}
					displayValue={`#${productNumber}`}
					variant="outline"
				/>
			)
		},
	},
	{
		accessorKey: 'name',
		meta: { label: 'Name' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
	},
	{
		accessorKey: 'operations',
		meta: { label: 'Operations' },
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Operations" />
		),
		cell: ({ row }) => {
			const operations = row.getValue<TProductOperation[]>('operations')
			return (
				<Tooltip>
					<TooltipTrigger className="w-fit">
						<Badge
							variant="outline"
							className={cn(
								'whitespace-nowrap',
								operations.length === 0 && 'text-muted-foreground',
							)}
						>
							<Settings className="mr-2 h-4 w-4 shrink-0" />
							{formatNumericString(operations.length)}{' '}
							{pluralize(operations.length, 'Operation')}
						</Badge>
					</TooltipTrigger>
					<TooltipContent className="grid justify-items-center">
						<span>
							<strong className="font-medium">Product Operations</strong>
							{operations.length > 0 && (
								<ul className="list-inside list-disc">
									{operations.map(operation => (
										<li key={operation.id}>{operation.name}</li>
									))}
								</ul>
							)}
						</span>
					</TooltipContent>
				</Tooltip>
			)
		},
		sortingFn: (a, b) =>
			a.getValue<TProductOperation[]>('operations').length -
			b.getValue<TProductOperation[]>('operations').length,
	},
	{
		accessorKey: 'orderCounts.active',
		id: 'activeOrderCount',
		meta: { label: 'Active Orders' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Active Orders"
				tooltip="Active Order Count"
			/>
		),
		cell: ({ row }) => {
			const activeOrderCount = row.getValue<number>('activeOrderCount')
			return (
				<Link
					to="/orders"
					search={{
						columnFilters: [
							{ id: 'product', value: row.original.productNumber },
							{
								id: 'status',
								value: ['planned', 'in-progress', 'pending'],
							},
						],
					}}
				>
					<Badge
						variant="outline"
						className={cn(
							'whitespace-nowrap',
							activeOrderCount === 0 && 'text-muted-foreground',
						)}
					>
						<ReceiptText className="mr-2 h-4 w-4 shrink-0" />
						{formatNumericString(activeOrderCount)} Active
					</Badge>
				</Link>
			)
		},
	},
	{
		accessorKey: 'orderCounts.total',
		id: 'totalOrderCount',
		meta: { label: 'Total Orders' },
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Total Orders"
				tooltip="Total Order Count"
			/>
		),
		cell: ({ row }) => {
			const activeOrderCount = row.getValue<number>('totalOrderCount')
			return (
				<Link
					to="/orders"
					search={{
						columnFilters: [
							{ id: 'product', value: row.original.productNumber },
						],
					}}
				>
					<Badge
						variant="outline"
						className={cn(
							'whitespace-nowrap',
							activeOrderCount === 0 && 'text-muted-foreground',
						)}
					>
						<ReceiptText className="mr-2 h-4 w-4 shrink-0" />
						{formatNumericString(activeOrderCount)} Total
					</Badge>
				</Link>
			)
		},
	},
	{
		id: 'actions',
		cell: ({ row, table }) => {
			const actions = table.options.meta?.actions(row.original)

			if (!actions) {
				return null
			}

			return (
				<div className="flex justify-end">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" className="h-8 w-8 p-0">
								<span className="sr-only">Open menu</span>
								<MoreHorizontal className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuLabel>Actions</DropdownMenuLabel>
							<DropdownMenuItem onClick={actions.onEdit}>
								<Pencil className="mr-2 h-4 w-4" />
								<span>Edit</span>
							</DropdownMenuItem>
							<DropdownMenuItem onClick={actions.onDuplicate}>
								<Copy className="mr-2 h-4 w-4" />
								<span>Duplicate</span>
							</DropdownMenuItem>
							<DropdownMenuItem onClick={actions.onDelete}>
								<Trash2 className="mr-2 h-4 w-4" />
								<span>Delete</span>
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			)
		},
		enableSorting: false,
		enableHiding: false,
	},
]
