import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TProduct, TProductOperation } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'
import { isActiveOrder, selectAllOrders } from '@/features/orders/orders-slice'

import { enhanceProductOperation } from './utils/enhance-product-operation'

export type TEnhancedProduct = TProduct<TProductOperation> & {
	orderCounts: { total: number; active: number }
}

type ProductsState = {
	list: TProduct[]
}

const initialState: ProductsState = {
	list: [],
}

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		createProduct: {
			reducer: (state, action: PayloadAction<TProduct>) => {
				state.list.push(action.payload)
			},
			prepare: (productData: Omit<TProduct, 'id'>, id = createId()) => {
				return { payload: { ...productData, id } }
			},
		},
		editProduct: (state, action: PayloadAction<TProduct>) => {
			const product = state.list.find(
				product => product.id === action.payload.id,
			)
			if (product) {
				Object.assign(product, action.payload)
			}
		},
		deleteProduct: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(product => product.id !== action.payload)
		},
		reorderProduct: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const [removed] = state.list.splice(oldIndex, 1)
			state.list.splice(newIndex, 0, removed)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const { createProduct, editProduct, deleteProduct, reorderProduct } =
	productsSlice.actions

export const selectProduct = (id: string) =>
	createSelector(
		(state: RootState) => state.products.list,
		(state: RootState) => state.productOperations.list,
		(state: RootState) => state.machines.list,
		(products, operations, machines) => {
			const product = products.find(product => product.id === id)
			if (!product) return undefined
			return {
				...product,
				operations: product.operations
					.map(operation => operations.find(o => o.id === operation.id))
					.filter((o): o is TProductOperation => Boolean(o))
					.map(operation => enhanceProductOperation(operation, machines)),
			}
		},
	)

export const selectProducts = (state: RootState) => state.products.list

export const selectEnhancedProducts = createSelector(
	selectProducts,
	(state: RootState) => state.productOperations.list,
	(state: RootState) => state.machines.list,
	selectAllOrders,
	(products, operations, machines, orders): TEnhancedProduct[] => {
		const orderCountsByProductId = orders.reduce(
			(acc, order) => {
				if (!acc[order.product.id]) {
					acc[order.product.id] = { total: 0, active: 0 }
				}
				acc[order.product.id].total++
				if (isActiveOrder(order)) {
					acc[order.product.id].active++
				}
				return acc
			},
			{} as Record<string, { total: number; active: number }>,
		)

		return products.map(product => ({
			...product,
			operations: product.operations
				.map(operation => operations.find(o => o.id === operation.id))
				.filter((o): o is TProductOperation => Boolean(o))
				.map(operation => enhanceProductOperation(operation, machines)),
			orderCounts: orderCountsByProductId[product.id] ?? {
				total: 0,
				active: 0,
			},
		}))
	},
)

export const selectProductNames = createSelector(
	(state: RootState) => state.products.list,
	products => products.map(product => product.name),
)

export const selectProductNumbers = createSelector(
	(state: RootState) => state.products.list,
	products => products.map(product => product.productNumber),
)

export default productsSlice.reducer
