import { TCalendarAdjustment, TCalendarPeriodAdjustment } from '@repo/types'

function isMachineAffectedByCalendarAdjustment(args: {
	calendarAdjustment: Pick<TCalendarAdjustment, 'affectedMachines'>
	machineId: string
}): boolean {
	const { calendarAdjustment, machineId } = args
	const { affectedMachines } = calendarAdjustment
	return (
		affectedMachines.length === 0 ||
		affectedMachines.some(({ id }) => id === machineId)
	)
}

function getCalendarAdjustmentsForMachine(args: {
	calendarAdjustments: Pick<
		TCalendarAdjustment,
		'startDate' | 'endDate' | 'status' | 'affectedMachines'
	>[]
	machineId: string
}): TCalendarPeriodAdjustment[] {
	const { calendarAdjustments, machineId } = args
	return calendarAdjustments
		.filter(calendarAdjustment =>
			isMachineAffectedByCalendarAdjustment({ calendarAdjustment, machineId }),
		)
		.map(({ startDate, endDate, status }) => ({ startDate, endDate, status }))
}

export {
	isMachineAffectedByCalendarAdjustment,
	getCalendarAdjustmentsForMachine,
}
