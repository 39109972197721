import { useState } from 'react'

import { FileDown, Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { GenericDialog } from '@/components/generic-dialog'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { DialogFooter } from '@/components/ui/dialog'

export function ExportDataDialog(props: {
	onClose: () => void
	onExport: () => void
	fileSizeBytes?: number
}) {
	const { onClose, onExport, fileSizeBytes = 0 } = props
	const [isExporting, setIsExporting] = useState(false)

	const formatFileSize = (bytes: number) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const sizes = ['Bytes', 'KB', 'MB', 'GB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
	}

	const handleExport = async () => {
		setIsExporting(true)
		try {
			await new Promise(resolve => setTimeout(resolve, 900))
			onExport()
		} catch (error) {
			console.error('Failed to export data:', error)
			toast.error('Failed to export data')
		} finally {
			setIsExporting(false)
		}
	}

	return (
		<GenericDialog
			title="Export Data"
			description="Download your data as a JSON file to create a backup or share it with others. While this file contains application data and cannot be viewed as a spreadsheet, it can be imported back into the application by anyone you share it with."
			onClose={onClose}
		>
			<div className="grid gap-4">
				<Card className="p-4">
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-2 text-sm text-muted-foreground">
							<span>File format:</span>
							<Badge variant="secondary" className="font-mono">
								JSON
							</Badge>
						</div>
						<div className="text-sm text-muted-foreground">
							<span className="font-medium">
								{formatFileSize(fileSizeBytes)}
							</span>
						</div>
					</div>
				</Card>
				<div>
					{isExporting ? (
						<Button disabled className="w-full">
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
							Preparing file
						</Button>
					) : (
						<Button className="w-full" onClick={handleExport}>
							<FileDown className="mr-2 h-4 w-4" />
							<span>Export Application Data</span>
						</Button>
					)}
				</div>
			</div>
			<DialogFooter>
				<Button type="button" variant="outline" onClick={onClose}>
					Cancel
				</Button>
			</DialogFooter>
		</GenericDialog>
	)
}
