import { useCallback, useEffect } from 'react'

export function useDeselect(args: {
	deselectRef?: React.RefObject<HTMLElement>
	onDeselect?: () => void
}) {
	const { deselectRef, onDeselect } = args

	const resetActiveId = useCallback(() => {
		onDeselect?.()
	}, [onDeselect])

	const deselect = useCallback(
		(event: Event) => {
			if (
				!(event.target as Element).closest('[data-interactive-zone="true"]')
			) {
				resetActiveId()
			}
		},
		[resetActiveId],
	)

	useEffect(() => {
		const deselectElement = deselectRef?.current ?? document
		deselectElement?.addEventListener('click', deselect)

		return () => {
			deselectElement?.removeEventListener('click', deselect)
		}
	}, [deselectRef, deselect])

	return {
		resetActiveId,
	}
}
