import React from 'react'

import { toast } from 'sonner'

import { GenericAlertDialog } from '@/components/generic-alert-dialog'

import { useFormDialog } from './use-form-dialog'

function defaultOnError() {
	toast.error(
		'Oops! Data appears to be missing or incorrect. Please check the fields and try again.',
	)
}

function useConfirmExitFormDialog<T>(args: {
	onSubmit: (data: T) => void
	onClose: () => void
	onError?: (errors: unknown) => void
	open?: boolean
}) {
	const { confirmDialogProps, ...rest } = useFormDialog<T>({
		...args,
		onError: args.onError ?? defaultOnError,
	})

	const ConfirmExitAlertDialog = React.memo(() => (
		<GenericAlertDialog
			title="Unsaved changes"
			description="You have unsaved changes that will be lost if you leave now. Do you want to exit and discard these changes?"
			confirmButtonText="Exit Without Saving"
			{...confirmDialogProps}
		/>
	))
	ConfirmExitAlertDialog.displayName = 'ConfirmExitAlertDialog'

	return { ConfirmExitAlertDialog, ...rest }
}

export { useConfirmExitFormDialog }
