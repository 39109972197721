import { useEffect, useRef } from 'react'

import useLocalStorageState from 'use-local-storage-state'

export function useSyncedPersistedState<T>(
	key: string,
	options: { value?: T; defaultValue: T },
) {
	const [persistedValue, setPersistedValue] = useLocalStorageState(key, {
		defaultValue: options.defaultValue,
	})

	const previousValueRef = useRef(options.value)

	useEffect(() => {
		if (
			options.value !== undefined &&
			options.value !== previousValueRef.current
		) {
			setPersistedValue(options.value)
			previousValueRef.current = options.value
		}
	}, [setPersistedValue, options.value])

	return persistedValue
}
