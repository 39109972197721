import { useLayoutEffect, useState } from 'react'

import {
	TCompletedOrder,
	TInProgressOrder,
	TMachineBooking,
	TPlannedOrder,
} from '@repo/types'

const useBookingActionBar = (args: {
	booking: TMachineBooking
	bookingActive: boolean
	order?: TPlannedOrder | TInProgressOrder | TCompletedOrder
	hourGap: number
	dragState: {
		initialScrollLeft: number
		initialPointX: number
		offset: number
		desiredStartDate: Date
	} | null
	bookingRef: React.RefObject<HTMLElement>
	viewRef: React.RefObject<HTMLElement>
	actionBarWidth: number
}) => {
	const {
		booking,
		bookingActive,
		order,
		hourGap,
		dragState,
		bookingRef,
		viewRef,
		actionBarWidth,
	} = args
	const [actionBar, setActionBar] = useState({
		top: -30,
		left: 0,
		active: false,
	})

	const hasNext =
		order &&
		order.bookingIds.length > 1 &&
		order.bookingIds.indexOf(booking.id) < order.bookingIds.length - 1
	const hasPrev =
		order &&
		order.bookingIds.length > 1 &&
		order.bookingIds.indexOf(booking.id) > 0

	useLayoutEffect(() => {
		if (bookingActive && bookingRef.current && viewRef.current) {
			const bookingLeft = parseFloat(bookingRef.current.style.left)
			const bookingWidth = parseFloat(bookingRef.current.style.width)
			const viewLeft = viewRef.current.scrollLeft + 250 + 8
			const viewWidth = window.innerWidth - 250 - 8
			const topOffset =
				bookingRef.current.getBoundingClientRect().top -
					(viewRef.current.getBoundingClientRect().top ??
						bookingRef.current.getBoundingClientRect().top) <
				30
					? 30
					: -30

			let leftOffset = 0

			if (
				bookingLeft > viewLeft &&
				bookingLeft + bookingWidth < viewLeft + viewWidth
			) {
				leftOffset = (bookingWidth - actionBarWidth) / 2
			} else if (
				bookingLeft <= viewLeft &&
				bookingLeft + bookingWidth >= viewLeft + viewWidth
			) {
				leftOffset = viewLeft - bookingLeft + (viewWidth - actionBarWidth) / 2
			} else if (bookingLeft <= viewLeft) {
				const center = (viewLeft - bookingLeft + bookingWidth) / 2
				leftOffset =
					center + actionBarWidth / 2 > bookingWidth
						? bookingWidth - actionBarWidth / 2
						: center - actionBarWidth / 2
			} else if (bookingLeft <= viewLeft + viewWidth) {
				const center = (viewLeft + viewWidth - bookingLeft) / 2
				leftOffset =
					center < actionBarWidth / 2
						? -actionBarWidth / 2
						: center - actionBarWidth / 2
			}
			setActionBar({ top: topOffset, left: leftOffset, active: true })
		} else {
			setActionBar({ top: -30, left: 0, active: false })
		}
	}, [
		bookingRef,
		viewRef,
		bookingActive,
		dragState,
		hourGap,
		booking.startDate,
		actionBarWidth,
		booking.id,
	])

	return { actionBar, actionBarWidth, hasNext, hasPrev }
}

export { useBookingActionBar }
