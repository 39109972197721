import {
	Column,
	ColumnDef,
	flexRender,
	Table as ReactTable,
} from '@tanstack/react-table'

import { cn } from '@/lib/utils'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table'
import { DataTablePagination } from './data-table-pagination'

const getPinningStyle = <TData, TValue>(
	column: Column<TData, TValue>,
): { style: React.CSSProperties; className: string } => {
	const isPinned = column.getIsPinned()
	if (!isPinned)
		return {
			style: {},
			className: '',
		}
	const isLastLeftPinnedColumn =
		isPinned === 'left' && column.getIsLastColumn('left')
	const isFirstRightPinnedColumn =
		isPinned === 'right' && column.getIsFirstColumn('right')

	return {
		style: {
			left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
			right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
		},
		className: cn(
			'z-10 sticky bg-background/90',
			isLastLeftPinnedColumn &&
				'shadow-[inset_-4px_0_4px_-4px_rgba(0,0,0,0.05)] dark:shadow-[inset_-4px_0_4px_-4px_rgba(255,255,255,0.1)]',
			isFirstRightPinnedColumn &&
				'shadow-[inset_4px_0_4px_-4px_rgba(0,0,0,0.05)] dark:shadow-[inset_4px_0_4px_-4px_rgba(255,255,255,0.1)]',
			column.id === 'select' && '!px-4',
			column.id === 'actions' && '!px-2',
		),
	}
}

interface DataTableProps<TData, TValue> {
	table: ReactTable<TData>
	columns: ColumnDef<TData, TValue>[]
	renderToolbar?: () => React.ReactNode
	renderSelectedRowActions?: () => React.ReactNode
}

export function DataTable<TData, TValue>({
	table,
	columns,
	renderToolbar,
	renderSelectedRowActions,
}: DataTableProps<TData, TValue>) {
	return (
		<div className="space-y-4">
			{renderToolbar?.()}
			<div className="flex h-8 flex-1 items-center gap-x-2 px-2">
				<div className="text-sm text-muted-foreground">
					{table.getFilteredSelectedRowModel().rows.length} of{' '}
					{table.getFilteredRowModel().rows.length} row(s) selected.
				</div>
				{renderSelectedRowActions?.()}
			</div>
			<div className="overflow-hidden rounded-md border bg-background">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => {
									const { style, className } = getPinningStyle(header.column)
									return (
										<TableHead
											key={header.id}
											className={className}
											style={style}
										>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									)
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(row => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
								>
									{row.getVisibleCells().map(cell => {
										const { style, className } = getPinningStyle(cell.column)
										return (
											<TableCell
												key={cell.id}
												className={className}
												style={style}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										)
									})}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<DataTablePagination table={table} />
		</div>
	)
}
