import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TProductOperation } from '@repo/types'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

import { enhanceProductOperation } from './utils/enhance-product-operation'

type ProductOperationsState = {
	list: TProductOperation[]
}

const initialState: ProductOperationsState = {
	list: [],
}

export const productOperationsSlice = createSlice({
	name: 'productOperations',
	initialState,
	reducers: {
		createProductOperation: {
			reducer: (state, action: PayloadAction<TProductOperation>) => {
				state.list.push(action.payload)
			},
			prepare: (
				productOperationData: Omit<TProductOperation, 'id'>,
				id: string = createId(),
			) => {
				return { payload: { ...productOperationData, id } }
			},
		},
		editProductOperation: (state, action: PayloadAction<TProductOperation>) => {
			const productOperation = state.list.find(
				productOperation => productOperation.id === action.payload.id,
			)
			if (productOperation) {
				Object.assign(productOperation, action.payload)
			}
		},
		deleteProductOperation: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(
				productOperation => productOperation.id !== action.payload,
			)
		},
		updateProductOperations: (
			state,
			action: PayloadAction<
				{ operations: TProductOperation[] } & { productId: string }
			>,
		) => {
			const { operations, productId } = action.payload
			state.list = state.list
				.filter(productOperation => productOperation.productId !== productId)
				.concat(operations)
		},
		reorderProductOperation: (
			state,
			action: PayloadAction<{ oldIndex: number; newIndex: number }>,
		) => {
			const { oldIndex, newIndex } = action.payload
			const [removed] = state.list.splice(oldIndex, 1)
			state.list.splice(newIndex, 0, removed)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const {
	createProductOperation,
	editProductOperation,
	deleteProductOperation,
	updateProductOperations,
	reorderProductOperation,
} = productOperationsSlice.actions

export const selectProductOperation = (id: string) =>
	createSelector(
		(state: RootState) => state.productOperations.list,
		(state: RootState) => state.machines.list,
		(productOperations, machines) => {
			const productOperation = productOperations.find(
				productOperation => productOperation.id === id,
			)
			if (!productOperation) return undefined
			return enhanceProductOperation(productOperation, machines)
		},
	)

export const selectProductOperations = createSelector(
	(state: RootState) => state.productOperations.list,
	(state: RootState) => state.machines.list,
	(productOperations, machines) =>
		productOperations.map(productOperation =>
			enhanceProductOperation(productOperation, machines),
		),
)

export default productOperationsSlice.reducer
