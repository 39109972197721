import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TProductOperation } from '@repo/types'
import { Blend, Link, Timer } from 'lucide-react'
import {
	Controller,
	FieldErrors,
	SubmitHandler,
	useForm,
	UseFormHandleSubmit,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import {
	decimalSeparator,
	thousandSeparator,
} from '@/utils/format-numeric-string'

import {
	ProductOperationOutputSchema,
	productOperationSchema,
} from './product-schema'

type SubmitButtonsRenderer =
	| ((props: {
			handleSubmit: UseFormHandleSubmit<ProductOperationOutputSchema>
	  }) => React.ReactNode)
	| React.ReactNode

function ProductOperationTransitionForm(props: {
	submitButtons: SubmitButtonsRenderer
	onSubmit: SubmitHandler<ProductOperationOutputSchema>
	onError?: (errors: FieldErrors<ProductOperationOutputSchema>) => void
	onUnsavedChanges?: (hasUnsavedChanges: boolean) => void
	initialValues?: Partial<Omit<TProductOperation, 'id' | 'productId'>>
}) {
	const { submitButtons, onSubmit, onError, onUnsavedChanges, initialValues } =
		props

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty },
	} = useForm<ProductOperationOutputSchema>({
		resolver: zodResolver(productOperationSchema),
		defaultValues: initialValues,
	})

	useEffect(() => {
		onUnsavedChanges?.(isDirty)
	}, [isDirty, onUnsavedChanges])

	return (
		<form
			onSubmit={e => {
				e.stopPropagation()
				handleSubmit(onSubmit, onError)(e)
			}}
			autoComplete="off"
		>
			<div className="grid gap-6 pb-6 pt-4">
				<div className="flex flex-col space-y-1.5">
					<Label className="flex items-center" htmlFor="transition.kind">
						<Link className="mr-1 h-4 w-4" /> Hard-Linked
					</Label>
					<div className="flex items-center gap-2">
						<Controller
							name="transition.kind"
							control={control}
							render={({ field }) => (
								<Switch
									id="transition.kind"
									defaultChecked={field.value === 'hard-linked'}
									onCheckedChange={checked =>
										field.onChange(checked ? 'hard-linked' : 'soft-linked')
									}
								/>
							)}
						/>
						<p className="text-xs text-muted-foreground">
							Ensures sequential operations without gaps.
						</p>
					</div>
					{errors.transition?.kind && (
						<span className="col-start-2 -mt-3 text-xs text-destructive">
							{errors.transition?.kind?.message}
						</span>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label
						className="flex items-center"
						htmlFor="transition.overlap.quantity"
					>
						<Blend className="mr-1 h-4 w-4" /> Overlap
					</Label>
					<p className="text-xs text-muted-foreground">
						Allows the next operation to start before the previous one ends.
						&quot;0&quot; means no overlap.
					</p>
					<div className="grid grid-cols-2 gap-4">
						<Controller
							name="transition.overlap.quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="transition.overlap.quantity"
									className="text-right"
									error={Boolean(errors.transition?.overlap?.quantity)}
									defaultValue={field.value}
									onValueChange={values => field.onChange(values.value)}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
								/>
							)}
						/>
						<Controller
							name="transition.overlap.unit"
							control={control}
							render={({ field }) => (
								<Select
									defaultValue={field.value ?? 'percent'}
									onValueChange={field.onChange}
								>
									<SelectTrigger
										onBlur={field.onBlur}
										error={Boolean(errors.transition?.overlap?.unit)}
									>
										<SelectValue placeholder="Select time unit" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="minutes">minutes</SelectItem>
										<SelectItem value="percent">percent</SelectItem>
										<SelectItem value="pieces">pieces</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>
						{errors.transition?.overlap?.message && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.transition?.overlap.message}
							</span>
						)}
						{errors.transition?.overlap?.quantity && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.transition?.overlap?.quantity.message}
							</span>
						)}
						{errors.transition?.overlap?.unit && (
							<span className="col-start-2 -mt-3 text-xs text-destructive">
								{errors.transition?.overlap?.unit.message}
							</span>
						)}
					</div>
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label
						className="flex items-center"
						htmlFor="transition.waitingTime.quantity"
					>
						<Timer className="mr-1 h-4 w-4" />
						Waiting Time
					</Label>
					<p className="text-xs text-muted-foreground">
						Adds a minimum delay before the next operation can start.
					</p>
					<div className="grid grid-cols-2 gap-4">
						<Controller
							name="transition.waitingTime.quantity"
							control={control}
							render={({ field }) => (
								<NumericFormat
									customInput={Input}
									id="transition.waitingTime.quantity"
									className="text-right"
									error={Boolean(errors.transition?.waitingTime?.quantity)}
									defaultValue={field.value}
									onValueChange={values => field.onChange(values.value)}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
								/>
							)}
						/>
						<Controller
							name="transition.waitingTime.unit"
							control={control}
							render={({ field }) => (
								<Select
									defaultValue={field.value ?? 'minutes'}
									onValueChange={field.onChange}
								>
									<SelectTrigger
										onBlur={field.onBlur}
										error={Boolean(errors.transition?.waitingTime?.unit)}
									>
										<SelectValue placeholder="Select time unit" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="seconds">seconds</SelectItem>
										<SelectItem value="minutes">minutes</SelectItem>
										<SelectItem value="hours">hours</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>
						{errors.transition?.waitingTime?.message && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.transition?.waitingTime.message}
							</span>
						)}
						{errors.transition?.waitingTime?.quantity && (
							<span className="-mt-3 text-xs text-destructive">
								{errors.transition?.waitingTime?.quantity.message}
							</span>
						)}
						{errors.transition?.waitingTime?.unit && (
							<span className="col-start-2 -mt-3 text-xs text-destructive">
								{errors.transition?.waitingTime?.unit.message}
							</span>
						)}
					</div>
				</div>
			</div>
			<DialogFooter>
				{typeof submitButtons === 'function'
					? submitButtons({ handleSubmit })
					: submitButtons}
			</DialogFooter>
		</form>
	)
}

export { ProductOperationTransitionForm }
