import { useCallback, useState } from 'react'

type CopyToClipboardOptions = {
	onSuccess?: () => void
	onError?: (error: Error) => void
}

export function copyToClipboard(
	value: string,
	options?: CopyToClipboardOptions,
) {
	if (typeof window === 'undefined' || !navigator.clipboard?.writeText) {
		options?.onError?.(new Error('Clipboard API not supported'))
		return
	}

	if (!value) {
		options?.onError?.(new Error('No value provided to copy'))
		return
	}

	navigator.clipboard
		.writeText(value)
		.then(() => {
			options?.onSuccess?.()
		})
		.catch(error => {
			options?.onError?.(error)
		})
}

export function useCopyToClipboard(args: { timeout?: number }) {
	const { timeout = 2000 } = args
	const [isCopied, setIsCopied] = useState<boolean>(false)

	const copyToClipboard = useCallback(
		(value: string, options?: CopyToClipboardOptions) => {
			if (typeof window === 'undefined' || !navigator.clipboard?.writeText) {
				options?.onError?.(new Error('Clipboard API not supported'))
				return
			}

			if (!value) {
				options?.onError?.(new Error('No value provided to copy'))
				return
			}

			navigator.clipboard
				.writeText(value)
				.then(() => {
					setIsCopied(true)
					options?.onSuccess?.()

					setTimeout(() => {
						setIsCopied(false)
					}, timeout)
				})
				.catch(error => {
					options?.onError?.(error)
				})
		},
		[timeout],
	)

	return { isCopied, copyToClipboard }
}
