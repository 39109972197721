import { forwardRef } from 'react'

import {
	Copy,
	MoreVertical,
	Pencil,
	ReceiptText,
	Settings,
	Trash2,
} from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { TEnhancedProduct } from '@/features/products/products-slice'
import { cn } from '@/lib/utils'
import { formatNumericString } from '@/utils/format-numeric-string'
import { pluralize } from '@/utils/pluralize'
import { stopPropagation } from '@/utils/stop-propagation'

const ProductCard = forwardRef(function ProductCard(
	props: {
		product: TEnhancedProduct
		onEdit: () => void
		onDuplicate: () => void
		onDelete: () => void
	},
	ref: React.Ref<HTMLDivElement>,
) {
	const { product, onEdit, onDuplicate, onDelete } = props

	return (
		<Card
			ref={ref}
			className="relative min-h-40 p-4"
			role="button"
			onClick={onEdit}
		>
			<h2 className="truncate text-lg" title={product.name}>
				{product.name}
			</h2>
			<p className="my-1 text-sm text-muted-foreground">
				{product.productNumber}
			</p>
			<div className="mt-2 flex flex-wrap gap-2">
				<Badge
					variant="outline"
					className={cn(
						'whitespace-nowrap',
						product.orderCounts.active === 0 && 'text-muted-foreground',
					)}
				>
					<ReceiptText className="mr-2 h-4 w-4 shrink-0" />
					{formatNumericString(product.orderCounts.active)} Active{' '}
					{pluralize(product.orderCounts.active, 'Order')}
				</Badge>
				<Badge
					variant="outline"
					className={cn(
						'whitespace-nowrap',
						product.orderCounts.total === 0 && 'text-muted-foreground',
					)}
				>
					<ReceiptText className="mr-2 h-4 w-4 shrink-0" />
					{formatNumericString(product.orderCounts.total)} Total{' '}
					{pluralize(product.orderCounts.total, 'Order')}
				</Badge>
				{product.operations.length > 0 && (
					<Badge
						variant="outline"
						className={cn(
							'whitespace-nowrap',
							product.operations.length === 0 && 'text-muted-foreground',
						)}
					>
						<Settings className="mr-2 h-4 w-4 shrink-0" />
						<span className="truncate">
							{product.operations.length}{' '}
							{pluralize(product.operations.length, 'Operation')}
						</span>
					</Badge>
				)}
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute right-2 top-2" asChild>
					<Button variant="ghost" className="h-8 w-8 p-2">
						<MoreVertical className="h-4 w-4 shrink-0" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					<DropdownMenuItem onClick={stopPropagation(onEdit)}>
						<Pencil className="mr-2 h-4 w-4" />
						<span>Edit</span>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={stopPropagation(onDuplicate)}>
						<Copy className="mr-2 h-4 w-4" />
						<span>Duplicate</span>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={stopPropagation(onDelete)}>
						<Trash2 className="mr-2 h-4 w-4" />
						<span>Delete</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</Card>
	)
})

export { ProductCard }
