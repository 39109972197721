import { useState } from 'react'

import { Loader2 } from 'lucide-react'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'

export function AddDataDialog(props: {
	onClose: () => void
	onAddDemoDataset: () => Promise<void>
	onFileUpload: (file: File) => void
}) {
	const { onClose, onAddDemoDataset, onFileUpload } = props
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [demoDatasetLoading, setDemoDatasetLoading] = useState(false)

	const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] ?? null
		setSelectedFile(file)
	}

	const addDemoDataset = async () => {
		setDemoDatasetLoading(true)
		try {
			await onAddDemoDataset()
		} finally {
			setDemoDatasetLoading(false)
		}
	}

	const handleFileUpload = () => {
		if (selectedFile) {
			onFileUpload(selectedFile)
		}
	}

	return (
		<GenericDialog
			title="Add data"
			description="Select a predefined dataset or upload your JSON file. Either option will overwrite existing data. This action is irreversible but your data stays private and local."
			onClose={onClose}
		>
			<div className="grid gap-8">
				<div className="space-y-2">
					<Label>Option 1: Select Dataset</Label>
					<Separator />
					{demoDatasetLoading ? (
						<Button disabled variant="outline" className="w-full">
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
							Please wait
						</Button>
					) : (
						<Button
							variant="outline"
							className="w-full"
							onClick={addDemoDataset}
						>
							Use Demo Dataset
						</Button>
					)}
				</div>
				<div className="space-y-2">
					<Label>Option 2: Upload Dataset</Label>
					<Separator />
					<Input
						type="file"
						accept=".json"
						onChange={handleFileSelection}
						className="w-full"
					/>
				</div>
			</div>
			<DialogFooter className="mt-6">
				<Button type="button" variant="outline" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={handleFileUpload} disabled={!selectedFile}>
					Upload Dataset
				</Button>
			</DialogFooter>
		</GenericDialog>
	)
}
