import { formatNumericString } from '@/utils/format-numeric-string'
import { formatTimeUnit } from '@/utils/format-time-unit'

function getFormattedPhaseDuration(
	phaseDuration?: {
		quantity: string | number
		unit: keyof typeof formatTimeUnit
	},
	defaultQuantity: number | string = 0,
) {
	if (!phaseDuration) {
		return { quantity: defaultQuantity, unit: formatTimeUnit['minutes'](0) }
	}

	const { quantity, unit } = phaseDuration
	return {
		quantity: formatNumericString(quantity),
		unit: formatTimeUnit[unit](quantity),
	}
}

export { getFormattedPhaseDuration }
