import { createId } from '@paralleldrive/cuid2'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import type { RootState } from '@/app/store'

export type TStaffGroup = {
	id: string
	name: string
}

type StaffGroupsState = {
	list: TStaffGroup[]
}

const initialState: StaffGroupsState = {
	list: [],
}

export const staffGroupsSlice = createSlice({
	name: 'staffGroups',
	initialState,
	reducers: {
		createStaffGroup: {
			reducer: (state, action: PayloadAction<TStaffGroup>) => {
				const staffGroupExists = state.list.some(
					staffGroup => staffGroup.name === action.payload.name,
				)
				if (!staffGroupExists && action.payload.name !== '') {
					state.list.push(action.payload)
				}
			},
			prepare: (staffGroupData: Omit<TStaffGroup, 'id'>) => {
				const id = createId()
				return { payload: { ...staffGroupData, id } }
			},
		},
		editStaffGroup: (state, action: PayloadAction<TStaffGroup>) => {
			const staffGroup = state.list.find(
				staffGroup => staffGroup.id === action.payload.id,
			)
			if (staffGroup) {
				Object.assign(staffGroup, action.payload)
			}
		},
		deleteStaffGroup: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(
				staffGroup => staffGroup.id !== action.payload,
			)
		},
	},
	extraReducers: builder => {
		builder.addCase(PURGE, state => {
			state.list = []
		})
	},
})

export const { createStaffGroup, editStaffGroup, deleteStaffGroup } =
	staffGroupsSlice.actions

export const selectStaffGroup = (id: string) =>
	createSelector(
		(state: RootState) => state.staffGroups.list,
		staffGroups => staffGroups.find(staffGroup => staffGroup.id === id),
	)

export const selectStaffGroups = (state: RootState) => state.staffGroups.list

export const selectStaffGroupNames = createSelector(
	selectStaffGroups,
	staffGroups => staffGroups.map(staffGroup => staffGroup.name),
)

export default staffGroupsSlice.reducer
