import { cn } from '@/lib/utils'

export function HeaderLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return <div className={cn('container relative', className)} {...rest} />
}

export function PageLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return <div className={cn('container relative py-4', className)} {...rest} />
}

export function FooterLayout(
	props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
	const { className, ...rest } = props
	return <div className={cn('container relative', className)} {...rest} />
}
