import { TMachineBooking } from '@repo/types'

import { GenericDialog } from '@/components/generic-dialog'
import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'

import { useProgressActions } from '../hooks/use-progress-actions'
import { ProgressCard } from './progress-card'

export function UpdateProgressDialog(props: {
	booking: TMachineBooking
	container?: HTMLDivElement | null
	onClose: () => void
}) {
	const { booking, container, onClose } = props
	const {
		resetBooking,
		startBooking,
		completeBookingSetup,
		startBookingProduction,
		completeBookingProduction,
		completeBooking,
		adjustProgressByPercent,
		adjustProgressByAmount,
		setProgressAmount,
	} = useProgressActions()

	return (
		<GenericDialog
			title="Update progress"
			description="Adjust progress, mark steps complete, or reset progress if needed. Changes are saved automatically."
			container={container}
			onClose={onClose}
		>
			<ProgressCard
				key={booking.id}
				booking={booking}
				container={container}
				{...(booking.status === 'planned' && {
					onStartBooking: () => startBooking(booking),
				})}
				{...(booking.status === 'in-progress' && {
					onResetBooking: () => resetBooking(booking),
					onCompleteBookingSetup: () => completeBookingSetup(booking),
					onStartBookingProduction: () => startBookingProduction(booking),
					onAdjustProgressByPercent: percent =>
						adjustProgressByPercent({ booking, percent }),
					onAdjustProgressByAmount: amount =>
						adjustProgressByAmount({ booking, amount }),
					onSetProgressAmount: amount => setProgressAmount({ booking, amount }),
					onCompleteBookingProduction: () => completeBookingProduction(booking),
					onCompleteBooking: () => completeBooking(booking),
				})}
				{...(booking.status === 'completed' && {
					onResetBooking: () => resetBooking(booking),
				})}
			/>
			<DialogFooter>
				<Button type="button" variant="outline" onClick={onClose}>
					Close
				</Button>
			</DialogFooter>
		</GenericDialog>
	)
}
