import { useState } from 'react'

import {
	createFileRoute,
	Link,
	Outlet,
	useNavigate,
} from '@tanstack/react-router'
import { Bell, BellOff, LayoutGrid, MoreVertical } from 'lucide-react'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { store } from '@/app/store'
import { PageLayout } from '@/components/layout'
import {
	ScrollableToolbar,
	ScrollableToolbarContentLeft,
	ScrollableToolbarContentRight,
} from '@/components/scrollable-toolbar'
import { AdvancedCombobox } from '@/components/ui/advanced-combobox'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/ui/tooltip'
import { AlertCard } from '@/features/alerts/components/alert-card'
import { AlertIcon } from '@/features/alerts/components/alert-icon'
import { selectMachines } from '@/features/machines/machines-slice'
import {
	activateAlert,
	ignoreAlert,
	selectFilteredAlerts,
	selectOrdersWithAlerts,
	validatePlan,
} from '@/features/planning/planning-slice'
import { TPlanningAlert } from '@/features/validation/types'

type ViewOptions = 'list' | 'grid'

type AlertSearch = {
	view?: ViewOptions
	orderId?: string
}

const Route = createFileRoute('/alerts')({
	validateSearch: (search: Record<string, unknown>): AlertSearch => {
		return {
			view: search.view as AlertSearch['view'],
			orderId: search.orderId as AlertSearch['orderId'],
		}
	},
	component: AlertsComponent,
	onEnter: () => {
		store.dispatch(validatePlan())
	},
})

function AlertsComponent() {
	const search = Route.useSearch()
	const navigate = useNavigate({ from: Route.fullPath })
	const dispatch = useAppDispatch()
	const [status, setStatus] = useState<'all' | 'active' | 'ignored'>('all')
	const [categories, setCategories] = useState<string[]>(['all'])
	const [machineId, setMachineId] = useState<string | undefined>(undefined)
	const machines = useAppSelector(selectMachines)
	const ordersWithAlerts = useAppSelector(selectOrdersWithAlerts)
	const allCategoriesAlerts = useAppSelector(
		selectFilteredAlerts({
			status,
			categories: [],
			machineId,
			orderId: search.orderId,
		}),
	)
	const alerts = useAppSelector(
		selectFilteredAlerts({
			status,
			categories: categories.includes('all')
				? []
				: categories.includes('transition')
					? [
							'transition-hard-linked',
							'transition-soft-linked',
							...categories.filter(c => c !== 'transition'),
						]
					: categories,
			machineId,
			orderId: search.orderId,
		}),
	)

	const hasIgnoredAlerts = alerts.some(alert => alert.ignored)
	const hasActiveAlerts = alerts.some(alert => !alert.ignored)

	function onIgnoreAlert(alert: TPlanningAlert) {
		dispatch(
			ignoreAlert({
				date: new Date().toISOString(),
				hash: alert.hash,
			}),
		)
	}

	function onActivateAlert(alert: TPlanningAlert) {
		dispatch(activateAlert(alert.hash))
	}

	return (
		<PageLayout>
			<ScrollableToolbar>
				<ScrollableToolbarContentLeft>
					<div className="flex items-center gap-2">
						<h1 className="text-xl sm:text-2xl">Alert Center</h1>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" className="h-8 w-8 p-2">
									<MoreVertical className="h-4 w-4 shrink-0" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="start">
								<DropdownMenuLabel>
									Filtered Alerts
									<Badge className="ml-2" variant="outline">
										{alerts.length}
									</Badge>
								</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<DropdownMenuGroup>
									<DropdownMenuItem
										data-disabled={!hasIgnoredAlerts}
										onClick={() => {
											alerts.forEach(alert =>
												dispatch(activateAlert(alert.hash)),
											)
										}}
									>
										<Bell className="mr-2 h-4 w-4 shrink-0" />
										<span>Reactivate All</span>
									</DropdownMenuItem>
									<DropdownMenuItem
										data-disabled={!hasActiveAlerts}
										onClick={() => {
											alerts.forEach(alert =>
												dispatch(
													ignoreAlert({
														date: new Date().toISOString(),
														hash: alert.hash,
													}),
												),
											)
										}}
									>
										<BellOff className="mr-2 h-4 w-4 shrink-0" />
										<span>Ignore All</span>
									</DropdownMenuItem>
								</DropdownMenuGroup>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				</ScrollableToolbarContentLeft>
				<ScrollableToolbarContentRight>
					<Tabs value="grid">
						<TabsList aria-label="View options">
							<Tooltip>
								<TooltipTrigger>
									<TabsTrigger
										value="grid"
										aria-label="Switch to grid view"
										asChild
									>
										<Link to="." search={old => ({ ...old, view: 'grid' })}>
											<LayoutGrid className="h-4 w-4" />
										</Link>
									</TabsTrigger>
								</TooltipTrigger>
								<TooltipContent>
									<p>Grid View</p>
								</TooltipContent>
							</Tooltip>
						</TabsList>
					</Tabs>
				</ScrollableToolbarContentRight>
			</ScrollableToolbar>
			<div className="py-8">
				<div className="space-y-2">
					<ScrollableToolbar>
						<ScrollableToolbarContentLeft>
							<ToggleGroup
								className="gap-2"
								variant="outline"
								type="multiple"
								value={categories}
								onValueChange={value => {
									if (value) {
										setCategories(['all'])
									}
								}}
							>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="all"
									aria-label="Toggle overlap"
								>
									<span className="mr-2">All</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{allCategoriesAlerts.length}
									</Badge>
								</ToggleGroupItem>
							</ToggleGroup>
							<Separator className="h-10" orientation="vertical" />
							<ToggleGroup
								className="gap-2"
								variant="outline"
								type="multiple"
								value={categories}
								onValueChange={value => {
									if (value) {
										setCategories(
											value.length === 0
												? ['all']
												: value.filter(v => v !== 'all'),
										)
									}
								}}
							>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="early"
									aria-label="Toggle Early"
								>
									<AlertIcon category="early" />
									<span className="mx-2">Early</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{
											allCategoriesAlerts.filter(a => a.category === 'early')
												.length
										}
									</Badge>
								</ToggleGroupItem>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="late"
									aria-label="Toggle Late"
								>
									<AlertIcon category="late" />
									<span className="mx-2">Late</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{
											allCategoriesAlerts.filter(a => a.category === 'late')
												.length
										}
									</Badge>
								</ToggleGroupItem>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="overlap"
									aria-label="Toggle Overlap"
								>
									<AlertIcon category="overlap" />
									<span className="mx-2">Overlap</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{
											allCategoriesAlerts.filter(a => a.category === 'overlap')
												.length
										}
									</Badge>
								</ToggleGroupItem>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="transition"
									aria-label="Toggle Transition"
								>
									<AlertIcon category="transition-soft-linked" />
									<span className="mx-2">Transition</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{
											allCategoriesAlerts.filter(
												a =>
													a.category === 'transition-soft-linked' ||
													a.category === 'transition-hard-linked',
											).length
										}
									</Badge>
								</ToggleGroupItem>
								<ToggleGroupItem
									className="transition-colors data-[state=on]:bg-background data-[state=off]:text-muted-foreground data-[state=off]:opacity-80"
									value="tool-overlap"
									aria-label="Toggle Tool Overlap"
								>
									<AlertIcon category="tool-overlap" />
									<span className="mx-2">Tool Overlap</span>
									<Badge style={{ color: 'inherit' }} variant="outline">
										{
											allCategoriesAlerts.filter(
												a => a.category === 'tool-overlap',
											).length
										}
									</Badge>
								</ToggleGroupItem>
							</ToggleGroup>
						</ScrollableToolbarContentLeft>
					</ScrollableToolbar>
					<div className="grid grid-cols-1 gap-x-4 gap-y-2 md:max-w-3xl md:grid-cols-[1fr_1fr_180px]">
						<AdvancedCombobox
							label="Machine"
							emptyLabel="All Machines"
							initialValue={machineId}
							options={machines.map(m => ({
								value: m.id,
								label: m.name,
							}))}
							searchPlaceholder="Search machine..."
							valuePlaceholder="Select a machine..."
							classNameButton="w-full"
							onSelect={value => {
								setMachineId(value === '' ? undefined : value)
							}}
						/>
						<AdvancedCombobox
							key={search.orderId}
							label="Order"
							emptyLabel="All Orders"
							initialValue={search.orderId}
							options={ordersWithAlerts.map(order => ({
								value: order.id,
								label: order.productionOrderNumber,
							}))}
							searchPlaceholder="Search order..."
							valuePlaceholder="Select an order..."
							classNameButton="w-full"
							onSelect={value => {
								navigate({
									search: old => ({
										...old,
										orderId: value ? value : undefined,
									}),
								})
							}}
						/>
						<Select
							value={status}
							onValueChange={(value: 'all' | 'active' | 'ignored') =>
								setStatus(value)
							}
						>
							<SelectTrigger>
								<div>
									<span className="mr-2 text-muted-foreground">Status: </span>
									<SelectValue placeholder="Choose Status" />
								</div>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="all">All</SelectItem>
								<Separator />
								<SelectItem value="active">Active</SelectItem>
								<SelectItem value="ignored">Ignored</SelectItem>
							</SelectContent>
						</Select>
					</div>
				</div>
				<Separator className="my-4" />
				<div className="grid w-full gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{alerts.length === 0 && (
						<div className="col-span-full text-center text-muted-foreground">
							No alerts found.
						</div>
					)}
					{alerts.map(alert => (
						<AlertCard
							key={alert.id}
							alert={alert}
							onShowInPlan={() => {
								navigate({
									to: '/planning',
									search: {
										alertId: alert.id,
									},
								})
							}}
							onIgnore={alert.ignored ? undefined : () => onIgnoreAlert(alert)}
							onActivate={
								alert.ignored ? () => onActivateAlert(alert) : undefined
							}
						/>
					))}
				</div>
			</div>
			<Outlet />
		</PageLayout>
	)
}

export { Route }
